import PropTypes from 'prop-types'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import { getFont, getColor, getSpace } from '../../theme/themeHelpers.js'
import Icon from '../Icon/Icon'
import InputContainer from '../Input/Container'
import { Flex } from '../Layout/Base'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  position: relative;
`

const IconWrapper = styled.div`
  position: absolute;
  height: 100%;
  right: ${getSpace(3)};
  display: flex;
  align-items: center;
`

const Select = styled.select`
  width: 100%;
  border: none;
  height: 40px;
  line-height: 24px;
  font-size: 16px !important;
  background: transparent !important;
  -webkit-border-radius: 4px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 4px 8px;
  font-weight: 100;
  font-family: ${getFont('body')};
  z-index: 2;
  &:focus {
    outline: none !important;
  }
  color: ${getColor('copyOne')};
  &:invalid {
    color: ${getColor('gsRat')};
  }
  &:disabled {
    color: ${getColor('gsRat')};
    background-color: ${getColor('gsCloud')} !important;
    transition-duration: 200ms;
  }
`

export const Option = styled.option`
  font-family: ${getFont('body')};
  display: ${(props) => props['data-default'] && 'none'};
`

// This is used to refer back to the placeholder to set it as the defaultValue.
// This is required because the latest version of react does not support setting
// the 'selected' prop on an option to select it.
const PLACEHOLDER_CONST = '__PLACEHOLDER_CONST'

const renderDefaultOptions = (item) => (
  <Option key={item} value={item}>
    {item}
  </Option>
)

const Placeholder = ({ placeholder }) => {
  if (!placeholder) {
    return null
  }
  return (
    <Option value={PLACEHOLDER_CONST} hidden>
      {placeholder}
    </Option>
  )
}

class Input extends React.PureComponent {
  render() {
    const {
      id,
      name,
      items,
      autoComplete,
      label,
      type,
      customOption,
      prefix,
      placeholder,
      required,
      className,
      value,
      initialValue,
      disabled,
      error,
      onFocus,
      onChange,
      ...rest
    } = this.props

    return (
      <Flex flexDirection="column" {...rest}>
        <InputContainer label={label} id={id} prefix={prefix} error={error}>
          <Container>
            {/* The 'required' prop allows us to use the invalid css selector option to
            set a different color for the placeholder. It has nothing to do with
            whether the dropdown requires a value */}
            <Select
              id={id}
              name={name}
              items={items}
              className={className}
              onFocus={onFocus}
              onChange={onChange}
              autoComplete={autoComplete}
              value={placeholder ? undefined : value}
              placeholder={placeholder}
              defaultValue={placeholder ? PLACEHOLDER_CONST : initialValue}
              type={type}
              disabled={disabled}
              required
            >
              <Placeholder placeholder={placeholder} />
              {required === false && <Option value="" />}
              {customOption ? map(customOption, items) : map(renderDefaultOptions, items)}
            </Select>
            <IconWrapper>
              <Icon icon="icon-dropdown" color="gsRat" height={10} width={11} />
            </IconWrapper>
          </Container>
        </InputContainer>
      </Flex>
    )
  }
}

Input.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  customOption: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  items: PropTypes.array.isRequired,
  /** Need object for react-intl */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['number', 'text']),
  /* value if using as a controlled component */
  value: PropTypes.string,
}

export default Input
