import { Box } from '@fatlama/llama-library'

import ReactSVG from './ReactSVG'

// When we have generic there will be another layer ontop to route partner/generic icons. Eg search
// some icons are colored with the path.stroke, and some path.fill...
// this knowledge should stay confined to this file. However any color overrides
// will need to refer to this file

type IconShape = {
  url: string
  isStrokeColored: boolean
  isGColored?: boolean
}

const iconUrlMap: Record<string, IconShape> = {
  close: {
    url: 'close.svg',
    isStrokeColored: true,
  },
  chevron: {
    url: 'chevron.svg',
    isStrokeColored: false,
  },
  chevron2: {
    url: 'chevron-2.svg',
    isStrokeColored: false,
  },
  padlock: {
    url: 'padlock.svg',
    isStrokeColored: true,
    isGColored: false,
  },
  ellipsis: {
    url: 'ellipsis.svg',
    isStrokeColored: true,
    isGColored: false,
  },
  rentals: {
    url: 'rentals.svg',
    isStrokeColored: true,
    isGColored: false,
  },
  overview: {
    url: 'overview.svg',
    isStrokeColored: true,
    isGColored: false,
  },
  settings: {
    url: 'settings.svg',
    isStrokeColored: false,
    isGColored: false,
  },
  profile: {
    url: 'profile.svg',
    isStrokeColored: false,
    isGColored: false,
  },
  search: {
    url: 'search.svg',
    isStrokeColored: true,
  },
  'check-bold': {
    url: 'check-bold.svg',
    isStrokeColored: false,
    // isGColored: true
  },
  'doc-verify': {
    url: 'doc-verify.svg',
    isStrokeColored: true,
    isGColored: true,
  },
  'self-verify': {
    url: 'self-verify.svg',
    isStrokeColored: true,
    // isGColored: true
  },
  'larry-logo': {
    url: 'larry-logo.svg',
    isStrokeColored: true,
    // isGColored: true
  },
  'fullscreen-1': {
    url: 'fullscreen-1.svg',
    isStrokeColored: false,
    // isGColored: true
  },
  'fullscreen-2': {
    url: 'fullscreen-2.svg',
    isStrokeColored: false,
    // isGColored: true
  },
  checkGrey: {
    url: 'checkGrey.svg',
    isStrokeColored: true,
    // isGColored: true
  },
  checkGreen: {
    url: 'checkGreen.svg',
    isStrokeColored: true,
    // isGColored: true
  },
  trash: {
    url: 'trash.svg',
    isStrokeColored: true,
    isGColored: true,
  },
  link: {
    url: 'link.svg',
    isStrokeColored: true,
    isGColored: true,
  },
} as any

const Icon = ({ icon, mr, mb, ...rest }: any) => {
  return (
    <Box width="auto" mr={mr} mb={mb}>
      <ReactSVG
        src={`/icons/${iconUrlMap[icon].url}`}
        isStrokeColored={iconUrlMap[icon].isStrokeColored}
        {...rest}
      />
    </Box>
  )
}

export default Icon
