import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { space, width, maxWidth } from 'styled-system'

import { getFont } from '../../theme/themeHelpers.js'
import getInputVariant from '../Internal/getInputVariant'
import InputCharacterCount from '../Internal/InputCharacterCount'
import { Label, Error } from '../Typography/Index'

const Container = styled.div`
  ${space};
  ${width};
  ${maxWidth};
  display: flex;
  flex-direction: column;
  font-family: ${getFont('body')};
`

const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

/**
 * Specific styling applied to textarea
 */
const TextAreaEl = styled(getInputVariant('textarea'))`
  rows: ${(props) => props.rows || 1};
  height: auto;
  padding: 6px 8px;
`

/**
 * Generic CoreInput component
 */
class TextArea extends React.PureComponent {
  render() {
    const {
      id,
      value,
      autocomplete,
      autoFocus,
      type,
      name,
      label,
      placeholder,
      width = '100%',
      maxLength,
      disabled,
      className,
      containerClassName,
      onBlur,
      onChange,
      error,
      rows,
      ...rest
    } = this.props
    const wordsLeft = maxLength - (value ? value.length : 0)
    return (
      <Container width={width} className={containerClassName} {...rest}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <InputDiv>
          {maxLength && <InputCharacterCount>{wordsLeft}</InputCharacterCount>}
          <TextAreaEl
            variant="textarea"
            id={id}
            name={name}
            value={value}
            onBlur={onBlur}
            placeholder={placeholder}
            type={type || 'text'}
            onChange={onChange}
            autoComplete={autocomplete}
            autoFocus={autoFocus}
            className={className}
            maxLength={maxLength}
            disabled={disabled}
            rows={rows}
          />
          {error && <Error>{error}</Error>}
        </InputDiv>
      </Container>
    )
  }
}

TextArea.propTypes = {
  /** id for the input element. */
  id: PropTypes.string,
  /** current value of input */
  value: PropTypes.string,
  /** e.g. 'd-o-b-day' or 'full-name'. */
  autocomplete: PropTypes.string,
  /** type of input e.g. password, text etc */
  type: PropTypes.string,
  /** Label for the input */
  /** Need object for react-intl */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Limit on number of characters */
  maxLength: PropTypes.number,
  /** Should input be disabled */
  disabled: PropTypes.bool,
  /** classname to apply to input element */
  className: PropTypes.string,
  /** classname to apply to container element */
  containerClassName: PropTypes.string,
  /** function to run on blur */
  onBlur: PropTypes.func,
  /** extra props that come from redux form */
  meta: PropTypes.object,
  /** rows which define text area height */
  rows: PropTypes.number,
  /* Styled System propTypes */
  ...width.propTypes,
  ...space.propTypes,
  ...maxWidth.propTypes,
}

export default TextArea
