import { Flex, Modal, Input, Button, H3 } from '@fatlama/llama-library'
import { Field, Formik } from 'formik'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { RequirementType } from '../../interfaces'
import GetApi from '../../utils/api'
import Dropdown from '../Common/Dropdown'
import formikFieldWrapper from '../Common/formikFieldWrapper'

const linkTypes = ['facebook', 'instagram', 'linkedin', 'personal', 'other']

const FormInput = formikFieldWrapper(Input)

const validate = (values: any) => {
  const errors = {} as any
  if (!values.linkValue) {
    errors.linkValue = 'Required'
  } else {
    try {
      new URL(values.linkValue)
    } catch {
      errors.linkValue = 'Invalid URL, make sure it starts with "https://" or "http://"'
    }
  }

  if (!values.linkType) {
    errors.linkType = 'Required'
  }

  return errors
}

const AddSocialMediaLink = ({
  userId,
  orderId,
  alreadyHasRequirement,
}: {
  userId: number
  orderId: number
  alreadyHasRequirement: boolean
}): any => {
  const [modalOpen, setModalOpen] = useState(false)
  const api = GetApi()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onSubmit = async (values: any) => {
    if (!alreadyHasRequirement) {
      const payload = {
        requirementTypes: [RequirementType.HAS_ONLINE_PRESENCE_LINK],
        orderId,
      } as any

      await api.addExtraRequirements(payload)
    }
    const res = await api.addOnlinePresenceLinks(userId, values.linkValue)
    if (res.success) {
      navigate(`${pathname}?refresh=true`)
      setModalOpen(false)
    }
  }

  return (
    <>
      <Button
        fullWidth
        label="Add social link"
        variant="secondary"
        onClick={() => setModalOpen(true)}
      />

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            linkValue: '',
            linkType: '',
          }}
          validate={validate}
          render={(formikBag) => {
            const { setFieldTouched, isSubmitting, handleSubmit, setFieldValue, isValid, values } =
              formikBag
            return (
              <Flex px={3} py={2} flexDirection="column">
                <H3 mb={2}>Add social media link</H3>

                <Dropdown
                  handleChange={(event: any) => {
                    setFieldValue('linkType', event.target.value)
                    setFieldTouched('linkType', true, false)
                  }}
                  value={values.linkType}
                  label="Link type"
                  options={linkTypes}
                />

                {/* <Label>Add note to borrower</Label> */}
                <Field
                  mb={3}
                  name="linkValue"
                  type="text"
                  required={true}
                  label="Url"
                  component={FormInput}
                />
                <Button
                  // variant='secondary'
                  onClick={handleSubmit}
                  large
                  fullWidth
                  label="Add link"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                />
              </Flex>
            )
          }}
        />
      </Modal>
    </>
  )
}

export default AddSocialMediaLink
