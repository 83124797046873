import { Text, getColor, Flex, getSpace } from '@fatlama/llama-library'
import styled from 'styled-components'

import type { OrderDataType, PaymentStatusType, SecurityStatusType } from '../../interfaces/index'

const Pill = styled(Flex)`
  border: 1px solid ${(props) => getColor(props.borderColor)};
  box-sizing: border-box;
  align-items: center;
  border-radius: 16px;
  padding-left: ${getSpace(2)};
  padding-right: ${getSpace(2)};
  height: 30px;
  width: auto;
  justify-content: center;
  ${({ small }: { small?: boolean }) => {
    return small ? 'max-width: 170px;' : ''
  }}
`

const TruncateText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// TODO - work out mappings
const getBaseColor = (
  securityStatus?: SecurityStatusType,
  orderStatus?: OrderDataType | string,
  paymentStatus?: PaymentStatusType
) => {
  if (securityStatus === 'IN_MANUAL_REVIEW' || securityStatus === 'UNFULFILLED_REQS') {
    return 'pomNeon'
  }

  if (
    orderStatus === 'CANCELED' ||
    orderStatus === 'DENIED' ||
    securityStatus === 'FAILED_VERIFICATION'
  ) {
    return 'gsCloud'
  }

  if (paymentStatus === 'FULLY_REFUNDED' || paymentStatus === 'UNPAID') {
    return 'gsCloud'
  }
  return 'marineMute'

  // switch (securityStatus) {
  //   case "REQUIREMENTS_UNFULLFILLED":
  //   case "MANUAL_REVIEW_REQUIRED":
  //     return "pomNeon";
  //   case TransactionStatus.ESCROW:
  //   case TransactionStatus.PAID:
  //     return "gsCloud";
  //   case TransactionStatus.CANCELLED:
  //   case TransactionStatus.PRE_AUTHORIZED_CANCELLED:
  //     return "orange";
  //   case TransactionStatus.FL_APPROVED:
  //     return "marineMute";
  //   default:
  //     return null;
  // }
}

const StatusPill = ({
  orderStatus,
  securityStatus,
  small,
  paymentStatus,
  mb,
}: {
  orderStatus?: OrderDataType | string
  securityStatus?: SecurityStatusType
  paymentStatus?: PaymentStatusType
  small?: boolean
  mb?: number
}) => {
  const statusBaseColor = getBaseColor(securityStatus, orderStatus, paymentStatus)
  const text = [securityStatus, orderStatus, paymentStatus].filter(Boolean).join(',')

  if (statusBaseColor === 'pomNeon') {
    return (
      <Pill mb={mb || 0} small={small} bg="pomLight" borderColor={'pomNeon'}>
        <TruncateText small fontSize="10px" color="pomNeon" mb={0}>
          {text}
        </TruncateText>
      </Pill>
    )
  }
  if (statusBaseColor === 'marineMute') {
    return (
      <Pill mb={mb || 0} small={small} bg="marineMuteLight" borderColor={'marineNeon'}>
        <TruncateText small fontSize="10px" color="marineMute" mb={0}>
          {text}
        </TruncateText>
      </Pill>
    )
  }
  if (statusBaseColor === 'gsCloud') {
    return (
      <Pill mb={mb || 0} small={small} bg="gsMist" borderColor={'gsRat'}>
        <TruncateText small color="gsGraphite" mb={0}>
          {text}
        </TruncateText>
      </Pill>
    )
  }

  if (statusBaseColor === 'orange') {
    return (
      <Pill mb={mb || 0} small={small} bg="orangeLight" borderColor={'orange'}>
        <TruncateText small color="orange" mb={0}>
          {text}
        </TruncateText>
      </Pill>
    )
  }
  return null
}

export default StatusPill
