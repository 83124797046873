import { Text, Flex, getColor } from '@fatlama/llama-library'
import styled from 'styled-components'

import { Tab, VerificationStatusFilter } from './types'

const FilterContainer = styled(Flex)`
  overflow: hidden;
`

const FilterButton = styled(Flex)`
  border: 1px solid ${(props) => getColor(props.borderColor)};
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  border-radius: 16px;
  padding-left: 16px;
  padding-right: 16px;
  height: 30px;
  width: auto;
  justify-content: center;
  margin-right: 16px;
`

const FilterTab = ({
  title,
  isActive,
  onClick,
}: {
  title: string
  isActive: boolean
  onClick: any
}) => {
  return (
    <FilterButton
      bg={isActive ? 'marineLight' : 'transparent'}
      borderColor={isActive ? 'marineMute' : 'copyTwo'}
      onClick={onClick}
      isActive={isActive}
    >
      <Text color={isActive ? 'marineMute' : 'copyTwo'} mb={0}>
        {title}
      </Text>
    </FilterButton>
  )
}

interface Props {
  activeTab: Tab
  activeFilter?: VerificationStatusFilter
  setActiveFilter(f?: VerificationStatusFilter): void
}

const ResultFilters = ({ activeTab, activeFilter, setActiveFilter }: Props) => {
  switch (activeTab) {
    case Tab.ACTIVE:
      return (
        <FilterContainer>
          <Flex justifyContent="flex-start">
            <FilterTab
              isActive={activeFilter === VerificationStatusFilter.ALL}
              title="All"
              onClick={() => setActiveFilter(VerificationStatusFilter.ALL)}
            />
            <FilterTab
              isActive={activeFilter === VerificationStatusFilter.SECURITY_REVIEW}
              title="Security review"
              onClick={() => {
                setActiveFilter(VerificationStatusFilter.SECURITY_REVIEW)
              }}
            />

            <FilterTab
              isActive={activeFilter === VerificationStatusFilter.SECURITY_CALL}
              title="Security call"
              onClick={() => setActiveFilter(VerificationStatusFilter.SECURITY_CALL)}
            />

            <FilterTab
              isActive={activeFilter === VerificationStatusFilter.CONFIRMATION_DOCUMENT_REVIEW}
              title={`Conf doc review`}
              onClick={() => setActiveFilter(VerificationStatusFilter.CONFIRMATION_DOCUMENT_REVIEW)}
            />
            <FilterTab
              isActive={activeFilter === VerificationStatusFilter.DOC_REVIEW}
              title="Doc review"
              onClick={() => setActiveFilter(VerificationStatusFilter.DOC_REVIEW)}
            />
          </Flex>

          <Flex width="auto" justifyContent="flex-end" minWidth="200px">
            <FilterTab
              isActive={activeFilter === VerificationStatusFilter.PENDING_ANY_DOCS}
              title={`🥱`}
              onClick={() => setActiveFilter(VerificationStatusFilter.PENDING_ANY_DOCS)}
            />
          </Flex>
        </FilterContainer>
      )
    case Tab.PAST:
      return null
    case Tab.ALL:
      return null
    default:
      return null
  }
}

export default ResultFilters
