import { AUTH, LOGOUT } from '../utils/apiTypes'

import type { TAction } from './actions'
import type * as types from './types'

const reducer = (state: types.State, action: TAction): types.State => {
  const { type } = action
  switch (type) {
    case LOGOUT.REQUEST:
      return {
        ...state,
        user: undefined,
      }
    case AUTH.SUCCESS:
      return {
        ...state,
        user: action.payload,
      }
    default:
      return state
  }
}
export default reducer
