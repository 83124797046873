export enum CoreBase {
  PRODUCTION = 'https://api.hygglo.se/',
  STAGING = 'https://core-staging.hygglo.se/',
  LOCAL = 'http://local.core.hygglo.se:5000/',
}

export enum WebBaseFatLLama {
  PRODUCTION = 'https://fatllama.com/',
  STAGING = 'https://next.fatllama.com/',
  LOCAL = 'http://local.fatllama.com:3000/',
}

export enum Brand {
  FATLLAMA = 'fatllama',
  HYGGLO = 'hygglo',
}

export enum Appearance {
  LIGHT = 'light',
  DARK = 'dark',
}

export const BRAND_LABELS = {
  [Brand.FATLLAMA]: 'Fat Llama',
  [Brand.HYGGLO]: 'Hygglo',
} as const satisfies Record<Brand, string>

export const BRAND_USER_CLIENTS = {
  [Brand.FATLLAMA]: 'FatLlama',
  [Brand.HYGGLO]: 'Hygglo',
} as const satisfies Record<Brand, string>

export enum Country {
  CANADA = 'CA',
  DENMARK = 'DK',
  FINLAND = 'FI',
  NORWAY = 'NO',
  SWEDEN = 'SE',
  UNITED_KINGDOM = 'GB',
  UNITED_STATES = 'US',
}

const FAT_LLAMA_COUNTRIES = [Country.CANADA, Country.UNITED_KINGDOM, Country.UNITED_STATES] as const

export type FatLlamaCountry = (typeof FAT_LLAMA_COUNTRIES)[number]

export const isFatLlamaCountry = (country: Country): country is FatLlamaCountry => {
  return (FAT_LLAMA_COUNTRIES as readonly Country[]).includes(country)
}

const HYGGLO_COUNTRIES = [Country.DENMARK, Country.FINLAND, Country.NORWAY, Country.SWEDEN] as const

export type HyggloCountry = (typeof HYGGLO_COUNTRIES)[number]

export const isHyggloCountry = (country: Country): country is FatLlamaCountry => {
  return (HYGGLO_COUNTRIES as readonly Country[]).includes(country)
}

export const getCountriesFromBrand = (brand: Brand): Readonly<Country[]> => {
  if (brand === Brand.FATLLAMA) {
    return FAT_LLAMA_COUNTRIES
  }
  return HYGGLO_COUNTRIES
}

const COUNTRY_TO_BASE_URL_MAP = {
  [Country.CANADA]: 'https://fatllama.com/ca',
  [Country.UNITED_KINGDOM]: 'https://fatllama.com/uk',
  [Country.UNITED_STATES]: 'https://fatllama.com/us',
  [Country.DENMARK]: 'https://www.hygglo.dk',
  [Country.FINLAND]: 'https://www.hygglo.fi',
  [Country.NORWAY]: 'https://www.hygglo.no',
  [Country.SWEDEN]: 'https://www.hygglo.se',
} as const satisfies Record<Country, string>

export const getBaseUrlByCountry = (country: Country) => {
  return COUNTRY_TO_BASE_URL_MAP[country]
}

export const transformUrlToCountry = (url: string, targetCountry: Country): string => {
  const currentBaseUrl = Object.values(COUNTRY_TO_BASE_URL_MAP).find((baseUrl) =>
    url.startsWith(baseUrl)
  )

  const targetBaseUrl = COUNTRY_TO_BASE_URL_MAP[targetCountry]

  if (!currentBaseUrl) {
    // Try to replace the domain portion of the URL
    try {
      const urlObj = new URL(url)
      return `${targetBaseUrl}${urlObj.pathname}${urlObj.search}${urlObj.hash}`
    } catch {
      // If URL parsing fails, return original URL
      return url
    }
  }

  return url.replace(currentBaseUrl, targetBaseUrl)
}

export const getUrlByBrandAndCountry = (
  brand: Brand,
  country: Country | undefined
): { url: string; label: string } => {
  if (brand === Brand.FATLLAMA) {
    switch (country) {
      case Country.CANADA:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.CANADA], label: 'Canada' }
      case Country.UNITED_KINGDOM:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.UNITED_KINGDOM], label: 'United Kingdom' }
      case Country.UNITED_STATES:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.UNITED_STATES], label: 'United States' }
    }
  }
  if (brand === Brand.HYGGLO) {
    switch (country) {
      case Country.DENMARK:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.DENMARK], label: 'Danmark' }
      case Country.FINLAND:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.FINLAND], label: 'Finland' }
      case Country.NORWAY:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.NORWAY], label: 'Norge' }
      case Country.SWEDEN:
        return { url: COUNTRY_TO_BASE_URL_MAP[Country.SWEDEN], label: 'Sverige' }
    }
  }
  throw new Error(`Unknown brand: ${brand}`)
}

export enum CountryUrlPrefix {
  CANADA = 'ca',
  UNITED_KINGDOM = 'uk',
  UNITED_STATES = 'us',
}

export const COUNTRY_URL_PREFIX_TO_COUNTRY: Record<CountryUrlPrefix, Country> = {
  [CountryUrlPrefix.CANADA]: Country.CANADA,
  [CountryUrlPrefix.UNITED_KINGDOM]: Country.UNITED_KINGDOM,
  [CountryUrlPrefix.UNITED_STATES]: Country.UNITED_STATES,
}

export const COUNTRY_TO_COUNTRY_URL_PREFIX: Record<FatLlamaCountry, CountryUrlPrefix> = {
  [Country.CANADA]: CountryUrlPrefix.CANADA,
  [Country.UNITED_KINGDOM]: CountryUrlPrefix.UNITED_KINGDOM,
  [Country.UNITED_STATES]: CountryUrlPrefix.UNITED_STATES,
}

export enum Language {
  DANISH = 'da',
  ENGLISH = 'en',
  FINNISH = 'fi',
  NORWEGIAN = 'nb',
  SWEDISH = 'sv',
}

export enum Currency {
  SEK = 'SEK',
  NOK = 'NOK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  CAD = 'CAD',
}

export enum BankIdStatus {
  COMPLETE_LOGIN = 'COMPLETE_LOGIN',
  COMPLETE_REGISTER = 'COMPLETE_REGISTER',
  OPEN_BANK_ID = 'OPEN_BANK_ID',
  OPEN_BANK_ID_WINDOW = 'OPEN_BANK_ID_WINDOW',
  USER_SIGN = 'USER_SIGN',
  USER_CANCEL = 'USER_CANCEL',
}

export enum Role {
  Owner = 'owner',
  Customer = 'customer',
}

export enum Orientation {
  Horizontal,
  Vertical,
}

export enum RequirementType {
  HAS_IDENTITY_DOC = 'HAS_IDENTITY_DOC',
  HAS_VIDEO_SELFIE = 'HAS_VIDEO_SELFIE',
  HAS_PROOF_OF_ADDRESS = 'HAS_PROOF_OF_ADDRESS',
  HAS_PROOF_OF_EMPLOYMENT = 'HAS_PROOF_OF_EMPLOYMENT',
  HAS_ONLINE_PRESENCE_LINK = 'HAS_ONLINE_PRESENCE_LINK',
  CONFIRMATION_DOCUMENT = 'CONFIRMATION_DOCUMENT',
  SECURITY_CALL = 'SECURITY_CALL',
}

export enum StaticPageSlug {
  ABOUT_US = 'about-us',
  CAREER = 'job',
  CONTACT = 'contact',
  MISSION = 'mission',
  PARTNERSHIP = 'partnerships',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_AND_CONDITIONS = 'tos',
}

export enum LocationAreaSubPage {
  OVERVIEW = 'overview',
  LEADERBOARDS = 'leaderboards',
  REVIEWS = 'reviews',
}

export enum FeatureFlag {
  TEST_ENV_GOOGLE_PAY = 'TEST_ENV_GOOGLE_PAY',
}

export enum HasAccessType {
  PRODUCT_FORM,
  REQUEST,
  GENERIC,
  SOCIAL,
}

export enum FlowIntent {
  ORDER = 'order',
}
