import { useEffect, useState } from 'react'

import GetApi from '../../../utils/api'

import type { Duplicates } from '../../../interfaces/index'

const useDuplicates = (userId?: number) => {
  const api = GetApi()
  const [duplicates, setDuplicates] = useState<Duplicates>({
    accounts: {},
    selfReferrer: undefined,
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const getDuplicates = async () => {
    if (!userId) {
      return
    }
    try {
      setError(undefined)
      setIsLoading(true)

      const res = await api.getUserDuplicates(userId)

      if (!res.success) {
        setError('Cannot get duplicates')
        return
      }
      const data = res.payload

      console.log(data, '-data')
      setDuplicates(data)
    } catch {
      setError('Cannot get duplicates')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userId) {
      getDuplicates()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  return {
    ...duplicates,
    isLoading,
    error,
  }
}

export default useDuplicates
