import { Flex, Box } from '@fatlama/llama-library'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import styled from 'styled-components'

import AuthSwitch from './components/Authentication/AuthSwitch'
import ErrorPage from './components/ErrorPage'
import NavSideBar from './components/NavSideBar/Index'
import Rentals from './components/Rentals/Index'
import CustomerRentals from './components/SelectedCustomer/CustomerRentals'
import SelectedRental from './components/SelectedRental/Index'
import { Page } from './interfaces'

const ContentContainer = styled(Box)`
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 100vh;
`

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path={`${Page.error}/:errorCode`} element={<ErrorPage />} />
          <Route
            path="*"
            element={
              <AuthSwitch>
                <Flex minHeight="100vh" maxHeight="100vh">
                  <NavSideBar />
                  <ContentContainer id="pageContainer" maxHeight="100vh">
                    <Routes>
                      <Route path="/" element={<Navigate to={Page.rentals} replace />} />

                      <Route path={`${Page.rentals}/:idOrOfferUuid`} element={<SelectedRental />} />

                      <Route path={Page.rentals} element={<Rentals />} />

                      <Route path={`${Page.customerRentals}/:id`} element={<CustomerRentals />} />

                      <Route
                        path="*"
                        element={
                          <Flex bg="#030D54" height="100vh">
                            <div
                              style={{
                                position: 'relative',
                                width: '100%',
                                paddingBottom: '56.25%',
                                backgroundColor: '#030D54',
                              }}
                            >
                              <iframe
                                src="https://www.youtube.com/embed/LWgGnMKoXHw?autoplay=1&mute=1"
                                title="YouTube Video"
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  border: 'none',
                                }}
                                allow="autoplay; encrypted-media; fullscreen"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </Flex>
                        }
                      />

                      <Route path={`${Page.error}/:errorCode`} element={<ErrorPage />} />
                    </Routes>
                  </ContentContainer>
                </Flex>
              </AuthSwitch>
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
