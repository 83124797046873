import React from 'react'
import styled, { keyframes } from 'styled-components'
import { space } from 'styled-system'

import Icon from '../Icon/Icon'

const spinFull = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`

const LoadingIconWrapper = styled.div`
  animation: ${spinFull} 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  ${space};
`

export default (props) => (
  <LoadingIconWrapper {...props}>
    <Icon
      icon="icon-multi-loader"
      width={props.width || 16}
      height={props.height || 16}
      color={props.color}
    />
  </LoadingIconWrapper>
)
