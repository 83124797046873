import styled, { css } from 'styled-components'
import * as styledSystem from 'styled-system'

import { getColor, getSpace, getFont } from '../../theme/themeHelpers'
const { space, width, fontSize, lineHeight, maxWidth, textAlign, letterSpacing, display } =
  styledSystem

const capitalizeFirstLetter = css`
  ::first-letter {
    text-transform: capitalize;
  }
`

export const baseTextStyle = css`
  margin-bottom: ${getSpace(2)};
  margin-top: 0px;
  ${space};
  ${display};
  ${width};
  ${maxWidth};
  ${textAlign};
  color: ${(props) => (props.color ? getColor(props.color) : getColor('copyOne'))};
  ${(props) => props.capitalize && capitalizeFirstLetter};
  ${fontSize};
  ${lineHeight};
  ${letterSpacing};
  font-family: ${getFont('body')};
`

export const H1 = styled.h1`
  ${baseTextStyle};
  color: ${(props) => (props.color ? getColor(props.color) : getColor('titleColor'))};
  font-family: ${getFont('header')};
  font-size: 30px;
  font-weight: 500;

  line-height: 40px;
`

export const H2 = styled.h2`
  ${baseTextStyle};
  color: ${(props) => (props.color ? getColor(props.color) : getColor('titleColor'))};
  font-family: ${getFont('header')};
  font-weight: 500;

  font-size: 24px;
  line-height: 30px;
`

export const H3 = styled.h3`
  ${baseTextStyle};
  color: ${(props) => (props.color ? getColor(props.color) : getColor('titleColor'))};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`

const smallTextStyles = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
`

const boldTextStyles = css`
  font-weight: 500;
`

const linkTextStyles = css`
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: 500;
  color: ${getColor('copyTwo')};
`

export const Text = styled.p`
  ${baseTextStyle};
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  ${(props) => props.small && smallTextStyles};
  ${(props) => props.link && linkTextStyles};
  ${(props) => props.bold && boldTextStyles};
`

export const Label = styled.p`
  ${baseTextStyle};
  margin-bottom: ${getSpace(1)};
  color: ${getColor('copyTwo')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  ${(props) => props.small && smallTextStyles}
`

export const Error = styled.p`
  ${baseTextStyle};
  margin-bottom: ${getSpace(1)};
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  ${(props) => props.small && smallTextStyles}
  color: ${getColor('error')};
`

export const Span = styled.span`
  ${baseTextStyle};
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  ${(props) => props.small && smallTextStyles}
  ${(props) => props.bold && boldTextStyles}
  ${(props) => props.link && linkTextStyles}
`

export const Li = styled.li`
  &::before {
    content: '•';
    font-size: 18px;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
    display: inline-block;
    color: ${getColor('marineMute')};
  }
  p {
    display: inline-block;
  }
`
