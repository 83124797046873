import { getTimeDifference } from '../../utils/dateUtils'

import { Tab, RentalDateFilter } from './types'

import type { QueryFilters, VerificationStatusFilter } from './types'

export const getTimeSince = (movedToSecurityReviewAt?: Date | null) => {
  if (!movedToSecurityReviewAt) {
    return null
  }
  const { minutes, hours, days } = getTimeDifference(movedToSecurityReviewAt)
  const shouldShowDays = hours >= 48
  const shouldShowHours = minutes >= 60 && days < 2
  const shouldShowMins = minutes < 60

  if (shouldShowMins) {
    return minutes + ' mins'
  }
  if (shouldShowHours) {
    return hours + ' hours'
  }
  if (shouldShowDays) {
    return days + ' days'
  }

  return null
}

export const getFiltersFromTabsAndFilter = ({
  activeTab,
  activeFilter,
}: {
  activeTab: Tab
  activeFilter?: VerificationStatusFilter
}): QueryFilters => {
  if (activeTab === Tab.ALL) {
    // no filters
    return {}
  }

  if (activeTab === Tab.PAST) {
    return {
      dateFilter: RentalDateFilter.PAST,
    }
  }

  return {
    dateFilter: RentalDateFilter.ACTIVE,
    verificationStatus: activeFilter,
  }
}
