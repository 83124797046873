import theme from './styledTheme.js'

// As we should no longer be writing CSS, we can slowly convert the helpers to read from styledTheme only
// And depricate the converted variables.scss JS theme
// Any additions to our LamaUi theme should be in styledTheme and NOT variables.
// All legacy scss will still function with the variables

export const getColor = (color) => () => theme.colors[color]
export const getFont = (font) => () => theme.fonts[font]
export const getFontSize = (fontSize) => () => theme.fontSizes[fontSize]
export const getLineHeight = (lineHeight) => () => theme.lineHeights[lineHeight]
export const getSpace = (index) => () => {
  return theme.space[index]
}
export const getBreakpoint = (index) => () => theme.breakpoints[index]
export const getBorder = (border) => () => theme.borders[border]
export const getShadow = (shadow) => () => theme.shadows[shadow]

const pxToNumber = (widthInPx) => Number(widthInPx.slice(0, -2))

/**
 * Applies style property based on the screen size
 *
 * @param {string|number} beforeBreak style property to apply to screens smaller than breakpoints[breakIndex]
 * @param {string|number} afterBreak style property to apply to screens bigger than breakpoints[breakIndex]
 * @param {number} breakIndex screen size index in breakpoints array
 */
export const resSettings = (beforeBreak, afterBreak, breakIndex) =>
  theme.breakpoints.map((breakpoint, i) => (i <= breakIndex ? beforeBreak : afterBreak))
