import styled from 'styled-components'

import { getBreakpoint } from '../theme/themeHelpers'

import { Flex } from './Layout/Base'

export const DesktopContainer = styled(Flex)`
  display: none;
  @media (min-width: ${getBreakpoint(3)}) {
    display: flex;
  }
`

export const MobileContainer = styled(Flex)`
  display: none;
  @media (max-width: ${getBreakpoint(3)}) {
    display: flex;
  }
`
