import currencyMap from '../constants/currencyMap'

const formatWithCurrency = (valueInPence: number, currencyCode: string) => {
  if (currencyCode === 'SEK' || currencyCode === 'NOK' || currencyCode === 'DKK') {
    return `${valueInPence.toFixed(2)}${currencyMap[currencyCode]}`
  }
  return `${currencyMap[currencyCode] || '£'}${valueInPence.toFixed(2)}`
}

export default formatWithCurrency
