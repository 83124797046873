import { Box, Flex, Text } from '@fatlama/llama-library'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import formatWithCurrency from '../../helpers/formatWithCurrency'
import { formatDateUTC, isAfter } from '../../utils/dateUtils'

import type { OrderDataType } from '../../interfaces/index'

const CursorRow = styled.tr`
  cursor: ${(props: { cursor: string }) => props.cursor || 'pointer'};
`
const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const Image = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  object-fit: contain;
`

const sortByStartDate = (a: OrderDataType, b: OrderDataType) => {
  if (a.fromDate && b.fromDate) {
    return isAfter(a.fromDate, b.fromDate) ? -1 : 1
  }
  return 0
}

const OtherRentals = ({
  isCustomerOtherRentals,
  orders,
}: {
  isCustomerOtherRentals?: boolean
  orders: Record<number, OrderDataType>
}) => {
  const totalValueOfRentals = Object.keys(orders).reduce((acc: number, orderId: any) => {
    const rental = orders[orderId]
    if (rental.paymentStatus === 'FULLY_PAID') {
      return acc + rental.totalEstimatedValue
    }
    return acc
  }, 0)

  const totalGMVOfRentals = Object.keys(orders).reduce((acc: number, orderId: any) => {
    const rental = orders[orderId]
    if (rental.paymentStatus === 'FULLY_PAID') {
      return acc + rental.orderFullTotal
    }
    return acc
  }, 0)

  const paidOrders = Object.values(orders).filter((order: any) => {
    return order.paymentStatus === 'FULLY_PAID' && order.orderStatus !== 'CANCELED'
  })

  const cancelledOrders = Object.values(orders).filter((order: any) => {
    return order.orderStatus === 'CANCELED'
  })

  const otherOrders = Object.values(orders).filter((order: any) => {
    return order.paymentStatus !== 'FULLY_PAID' && order.orderStatus !== 'CANCELED'
  })

  const hasAnyListLongerThanFive =
    paidOrders.length > 5 || cancelledOrders.length > 5 || otherOrders.length > 5

  const [showCancelled, setShowCancelled] = useState(!hasAnyListLongerThanFive)
  const [showPaid, setShowPaid] = useState(!hasAnyListLongerThanFive)
  const [showOther, setShowOther] = useState(!hasAnyListLongerThanFive)

  return (
    <>
      <table>
        <tbody>
          <tr>
            <TH>Product(s)</TH>
            {!isCustomerOtherRentals ? <TH>Image</TH> : <TH>highestFraudRisk</TH>}

            <TH>ID/Offer uuid</TH>
            <TH minWidth="65px">Start date</TH>
            <TH minWidth="65px">End date</TH>
            <TH>Status</TH>
            <TH>Security status</TH>
            <TH>Payment status</TH>

            <TH> {!isCustomerOtherRentals ? 'GMV' : ''}</TH>
            <TH width="90px">Item value(s)</TH>
          </tr>

          {cancelledOrders ? (
            <>
              <CursorRow
                cursor={cancelledOrders.length > 5 ? 'pointer' : ''}
                onClick={() => setShowCancelled(!showCancelled)}
              >
                <TD>
                  <Flex alignItems="center">
                    <Text mt={3} bold>
                      CANCELLED ORDERS ({cancelledOrders.length})
                      {cancelledOrders.length > 5 ? (showCancelled ? ' ↑' : ' ↓') : null}
                    </Text>
                  </Flex>
                </TD>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                  <TD key={i}></TD>
                ))}
              </CursorRow>
              {cancelledOrders.length <= 5 || showCancelled ? (
                <RentalsList
                  orders={cancelledOrders.sort(sortByStartDate)}
                  isCustomerOtherRentals={isCustomerOtherRentals}
                />
              ) : null}
            </>
          ) : null}

          {otherOrders ? (
            <>
              <CursorRow
                cursor={otherOrders.length > 5 ? 'pointer' : ''}
                onClick={() => setShowOther(!showOther)}
              >
                <TD>
                  <Flex alignItems="center">
                    <Text mt={3} bold>
                      ORDERS THAT ARE NOT FULLY PAID AND NOT CANCELLED ({otherOrders.length})
                      {otherOrders.length > 5 ? (showOther ? ' ↑' : ' ↓') : null}
                    </Text>
                  </Flex>
                </TD>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                  <TD key={i}></TD>
                ))}
              </CursorRow>
              {otherOrders.length <= 5 || showOther ? (
                <RentalsList
                  orders={otherOrders.sort(sortByStartDate)}
                  isCustomerOtherRentals={isCustomerOtherRentals}
                />
              ) : null}
            </>
          ) : null}

          {paidOrders ? (
            <>
              <CursorRow
                cursor={paidOrders.length > 5 ? 'pointer' : ''}
                onClick={() => setShowPaid(!showPaid)}
              >
                <TD>
                  <Flex alignItems="center">
                    <Text mt={3} bold>
                      FULLY PAID ORDERS THAT ARE NOT CANCELLED ({paidOrders.length})
                      {paidOrders.length > 5 ? (showPaid ? ' ↑' : ' ↓') : null}
                    </Text>
                  </Flex>
                </TD>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                  <TD key={i}></TD>
                ))}
              </CursorRow>
              {paidOrders.length <= 5 || showPaid ? (
                <RentalsList
                  orders={paidOrders.sort(sortByStartDate)}
                  isCustomerOtherRentals={isCustomerOtherRentals}
                />
              ) : null}
            </>
          ) : null}

          <tr>
            <TD></TD>
            <TD></TD>
            <TD></TD>
            <TD></TD>
            <TD></TD>
            <TD></TD>
            <TD></TD>
            <TD>
              <Box>
                <Text small mb={0} color="copyTwo">
                  {isCustomerOtherRentals
                    ? 'Total value of items in Paid orders'
                    : `Total item value`}
                </Text>
              </Box>
            </TD>

            <TD>
              {!isCustomerOtherRentals ? (
                <Text small mb={1}>
                  {formatWithCurrency(totalGMVOfRentals, 'GBP')}
                </Text>
              ) : null}
            </TD>
            <TD width="90px">
              <Text small mb={1}>
                {formatWithCurrency(totalValueOfRentals, 'GBP')}
              </Text>
            </TD>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const RentalsList = ({
  orders,
  isCustomerOtherRentals,
}: {
  orders: OrderDataType[]
  isCustomerOtherRentals?: boolean
}) => {
  const navigate = useNavigate()
  const header = document.getElementById('header') as any

  return (
    <>
      {' '}
      {orders.map((order: OrderDataType) => {
        const rental = order
        const {
          // isOffer,

          orderId,
          // transactionIds,
          // createdAt,
          highestFraudRisk,
          orderFullTotal,
          securityStatus,
          orderStatus,
          currency,
          fromDate,
          toDate,
          items,
          totalEstimatedValue,
          paymentStatus,
        } = rental

        const inValidStatuses = rental.paymentStatus === 'FULLY_PAID'

        const displayTotal = inValidStatuses ? orderFullTotal : null

        const displayValue = inValidStatuses ? totalEstimatedValue : null

        return (
          <tr
            key={orderId}
            onClick={() => {
              header.classList.remove('header-up')
              navigate(`/rentals/${orderId}`)
            }}
          >
            <TD>
              {' '}
              {items
                ? items?.length > 1
                  ? `${items.length} items`
                  : items[0].itemName
                : isCustomerOtherRentals && rental.itemNames
                  ? rental.itemNames.join(', ')
                  : null}
            </TD>
            {!isCustomerOtherRentals ? (
              <TD key={orderId}>
                <Image width="50px" height="35px" src={items ? items[0].imgUrls[0] : 'null'} />
              </TD>
            ) : (
              <TD>{highestFraudRisk}</TD>
            )}
            <TD>{orderId}</TD>
            <TD>{fromDate && formatDateUTC(fromDate, 'd MMM yy')}</TD>
            <TD>{toDate && formatDateUTC(toDate, 'd MMM yy')}</TD>
            <TD>{orderStatus}</TD>
            <TD>{securityStatus}</TD>
            <TD>{paymentStatus}</TD>
            {/* <TD>{result.basketItemCount}</TD> */}

            <TD>
              {isCustomerOtherRentals
                ? null
                : displayTotal
                  ? formatWithCurrency(displayTotal, currency)
                  : displayTotal}
            </TD>
            <TD width="90px">
              {displayValue ? formatWithCurrency(totalEstimatedValue, currency) : '-'}
            </TD>
          </tr>
        )
      })}
    </>
  )
}
export default OtherRentals
