import { CoreBase, Country, Brand, Language, Currency } from './enums'

import type { ReadonlyDeep } from 'type-fest'

// we need to treat enums as objects for this to be type safe in TS

export function getCountryFromEnum(
  rawCountry: Country | string | false | undefined
): Country | undefined {
  const country = typeof rawCountry === 'string' ? rawCountry.toUpperCase() : undefined

  if (country === 'AX') {
    return Country.FINLAND
  } else {
    return convertToEnum(rawCountry, Country)
  }
}

type BaseEnumObject = Record<string, string> | typeof CoreBase

export function convertToEnum<EnumObject extends BaseEnumObject>(
  value: unknown,
  type: EnumObject,
  defaultValue: EnumObject[keyof EnumObject]
): EnumObject[keyof EnumObject]
export function convertToEnum<EnumObject extends BaseEnumObject>(
  value: unknown,
  type: EnumObject,
  defaultValue?: EnumObject[keyof EnumObject]
): EnumObject[keyof EnumObject] | undefined
export function convertToEnum<EnumObject extends BaseEnumObject>(
  value: unknown,
  type: EnumObject,
  defaultValue?: EnumObject[keyof EnumObject]
): EnumObject[keyof EnumObject] | undefined {
  if (typeof value !== 'string') {
    return defaultValue
  }

  if (type === CoreBase) {
    const url = new URL(value)
    url.pathname = ''
    return stringToEnum<EnumObject>(type, url.href, defaultValue)
  }

  return stringToEnum<EnumObject>(type, value, defaultValue)
}
export function getBrandFromCountry(country: Country): Brand {
  switch (country) {
    case Country.SWEDEN:
    case Country.NORWAY:
    case Country.DENMARK:
    case Country.FINLAND:
      return Brand.HYGGLO
    case Country.UNITED_KINGDOM:
    case Country.UNITED_STATES:
    case Country.CANADA:
      return Brand.FATLLAMA
  }
}
export function getDefaultLanguageForCountry(country: Country): Language {
  switch (country) {
    case Country.SWEDEN:
      return Language.SWEDISH
    case Country.NORWAY:
      return Language.NORWEGIAN
    case Country.DENMARK:
      return Language.DANISH
    case Country.FINLAND:
      return Language.FINNISH
    case Country.UNITED_KINGDOM:
    case Country.UNITED_STATES:
    case Country.CANADA:
      return Language.ENGLISH
  }
}
export function getCurrencyFromCountry(country: Country): Currency {
  switch (country) {
    case Country.SWEDEN:
      return Currency.SEK
    case Country.NORWAY:
      return Currency.NOK
    case Country.DENMARK:
      return Currency.DKK
    case Country.FINLAND:
      return Currency.EUR
    case Country.UNITED_KINGDOM:
      return Currency.GBP
    case Country.UNITED_STATES:
      return Currency.USD
    case Country.CANADA:
      return Currency.CAD
  }
}

const LANGUAGE_NAMES = {
  [Language.ENGLISH]: { code: Language.ENGLISH, name: 'English' },
  [Language.SWEDISH]: { code: Language.SWEDISH, name: 'Svenska' },
  [Language.NORWEGIAN]: { code: Language.NORWEGIAN, name: 'Norsk' },
  [Language.FINNISH]: { code: Language.FINNISH, name: 'Suomi' },
  [Language.DANISH]: { code: Language.DANISH, name: 'Dansk' },
} satisfies Record<Language, { code: Language; name: string }>

const TRANSLATIONS_AVAILABLE_PER_COUNTRY = {
  [Country.SWEDEN]: [Language.SWEDISH, Language.ENGLISH].map((lang) => LANGUAGE_NAMES[lang]),
  [Country.NORWAY]: [Language.NORWEGIAN, Language.ENGLISH].map((lang) => LANGUAGE_NAMES[lang]),
  [Country.FINLAND]: [Language.FINNISH, Language.SWEDISH, Language.ENGLISH].map(
    (lang) => LANGUAGE_NAMES[lang]
  ),
  [Country.DENMARK]: [Language.DANISH, Language.ENGLISH].map((lang) => LANGUAGE_NAMES[lang]),
  [Country.UNITED_KINGDOM]: [],
  [Country.UNITED_STATES]: [],
  [Country.CANADA]: [],
} as const satisfies Record<Country, { name: string; code: Language }[]>

export const getTranslationsAvailableForCountry = (
  country: Country
): ReadonlyDeep<{ name: string; code: Language }[]> => {
  return TRANSLATIONS_AVAILABLE_PER_COUNTRY[country]
}

export function getCurrencySymbolFromCurrency(currency: Currency): {
  symbol: string
  prefix: boolean
} {
  if (currency === Currency.EUR) {
    return { symbol: '€', prefix: true }
  } else if (currency === Currency.GBP) {
    return { symbol: '£', prefix: true }
  } else if (currency === Currency.USD || currency === Currency.CAD) {
    return { symbol: '$', prefix: true }
  } else {
    return { symbol: 'kr', prefix: false }
  }
}

function stringToEnum<EnumObject extends BaseEnumObject>(
  type: EnumObject,
  value: string,
  defaultValue: EnumObject[keyof EnumObject] | undefined
) {
  const keys = Object.keys(type).filter((key) => {
    const enumValue = type[key as keyof EnumObject]

    if (typeof enumValue === 'string') {
      return enumValue.toUpperCase() === value.toUpperCase()
    }

    return false
  }) as (keyof EnumObject)[]

  if (keys.length > 1) {
    throw new Error(
      `Enum can not have multiple keys with same value, found value "${value}" in keys ${JSON.stringify(
        keys
      )}`
    )
  }
  const [key] = keys

  return key ? type[key] : defaultValue
}
