import { Flex, Box, Text, Modal, H3, Error, Button } from '@fatlama/llama-library'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import GetApi from '../../utils/api'

import RejectRental from './RejectRental'

import type { OrderDataType } from '../../interfaces'

interface Props {
  order: OrderDataType
}

const ApprovalSection = ({ order }: Props) => {
  const canApproveTransaction =
    order.orderStatus === 'ACCEPTED' &&
    (order.securityStatus === 'IN_MANUAL_REVIEW' || order.securityStatus === 'UNFULFILLED_REQS') &&
    order.paymentStatus === 'FUNDS_RESERVED'

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const api = GetApi()

  const approve = async () => {
    setOpenConfirmModal(false)
    setIsLoading(true)
    setError(undefined)

    try {
      const res = await api.approveTransaction(order.orderId)

      if (res.success) {
        navigate(`${pathname}?refresh=true`)
      } else {
        setError(res?.message || 'Unable to approve rental')
      }
    } catch (error) {
      const message = (error as any)?.message || 'Unable to approve rental'
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }

  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const openConfirmModalPress = () => {
    setOpenConfirmModal(true)
  }

  return (
    <Box>
      <H3 mb={3}>Approvals</H3>
      <Flex justifyContent="space-between" px={2}>
        <Flex flexDirection="column" maxWidth="400px">
          {canApproveTransaction ? (
            <>
              {order.securityStatus === 'UNFULFILLED_REQS' && (
                <Text small alert bold>
                  Warning: Since not all requirements are fullfilled, if you are approving this
                  rental now you are overriding the current ruleset.
                </Text>
              )}
              <Button
                mb={2}
                fullWidth
                isLoading={isLoading}
                label="Approve"
                onClick={
                  order.securityStatus === 'UNFULFILLED_REQS' ? openConfirmModalPress : approve
                }
              />
            </>
          ) : (
            <Text bold>Security status: {order.securityStatus}</Text>
          )}

          <Modal isOpen={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
            <Box px={2}>
              <Text alert bold>
                Are you sure you want to approve this rental? They have not fulfilled all the
                requirements. (Security status: {order.securityStatus})
              </Text>

              <Text alert small mb={3}>
                (If they have completed all requirements this is a bug and please inform the tech
                team)
              </Text>
            </Box>

            <Button
              mb={2}
              fullWidth
              isLoading={isLoading}
              label="Yes approve anyway"
              onClick={approve}
            />
          </Modal>
          <RejectRental redButton order={order} />
          {error && <Error>{error}</Error>}
        </Flex>
      </Flex>
    </Box>
  )
}

export default ApprovalSection
