import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { getBorder } from '../../theme/themeHelpers.js'
import { Flex, Box } from '../Layout/Base'

const ListItem = styled(Box)`
  border-bottom: ${getBorder('thinExtraLight')};
`

export class SectionListItem extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props
    return (
      <ListItem width="100%" {...rest}>
        {children}
      </ListItem>
    )
  }
}

SectionListItem.propTypes = {
  children: PropTypes.node.isRequired,
}

const List = styled(Flex)`
  border-top: ${getBorder('thinExtraLight')};
`

export class SectionList extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props
    return (
      <List flexDirection="column" {...rest}>
        {children}
      </List>
    )
  }
}

SectionList.propTypes = {
  children: PropTypes.node.isRequired,
}
