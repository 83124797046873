import React from 'react'
import styled from 'styled-components'
import { height, width } from 'styled-system'

import { getSpace } from '../theme/themeHelpers'

import { Box, Flex } from './Layout/Base'

const ModalContainer = styled(Flex)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 20;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: ${getSpace(3)};
`

const ModalContents = styled(Box)`
  position: relative;
  background-color: white;
  padding: ${getSpace(3)};
  max-width: 450px;
  border-radius: 4px;
  padding-top: ${getSpace(5)};
`

const TopRight = styled.div`
  cursor: pointer;
  padding: ${getSpace(3)};
  position: absolute;
  top: 0;
  right: 0;
`

export const BASE_ASSETS_URL = 'https://fat-lama-assets.s3-eu-west-1.amazonaws.com'

export const CloseIcon = styled((props) => (
  <img src={`${BASE_ASSETS_URL}/microsite/icons/rotatable-cross.png`} {...props} />
))`
  transform: rotate(45deg);
  ${height};
  ${width};
`

const CloseButton = ({ onClick }) => {
  if (!onClick) {
    return null
  }
  return (
    <TopRight onClick={onClick}>
      <CloseIcon height={16} width={16} />
    </TopRight>
  )
}

export class Modal extends React.PureComponent {
  componentDidMount() {
    const modal = document.getElementById('modal')
    modal &&
      modal.addEventListener(
        'touchmove',
        (e) => {
          e.preventDefault()
        },
        false
      )
  }

  render() {
    const { isOpen, onClose, children, zIndex } = this.props

    if (!isOpen) {
      return null
    }
    return (
      <ModalContainer id="modal" zIndex={zIndex}>
        <ModalContents>
          <CloseButton onClick={onClose} />
          {children}
        </ModalContents>
      </ModalContainer>
    )
  }
}

export default Modal
