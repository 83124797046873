import styled, { css } from 'styled-components'

import { getColor, getFontSize, getFont } from '../../theme/themeHelpers.js'

const getInitialBorderColor = (inverted, borderless) => {
  if (inverted) {
    return 'gsSlate'
  }
  if (borderless) {
    return 'white'
  }
  return 'gsCloud'
}

const getBackgroundColor = (isActive, borderless) => {
  if (!isActive && !borderless) {
    return 'white'
  }

  return 'whaleLighter'
}

const SVG_CLASS = 'styled-svg'

const focusAndActiveState = css`
  box-shadow: 0px 0px 4px 2px rgba(0, 102, 178, 0.25);
  .hover-responsive-icon {
    .${SVG_CLASS} {
      path {
        stroke: ${getColor('whale')};
      }
    }
  }
`

const getCoreInputEl = (Element) => styled(getInnerInputEl(Element))`
  resize: none;
  border-radius: ${(props) => (props.isRoundInput ? '26px' : '8px')};
  margin: 0;
  background-color: ${(props) => {
    return getColor(getBackgroundColor(props.isActive, props.borderless))
  }};
  height: 40px;
  .hover-responsive-icon {
    .${SVG_CLASS} {
      path {
        transition: 0.4s;
        stroke: ${getColor('gsRat')};
      }
    }
  }

  border: 1.2px solid
    ${(props) => getColor(getInitialBorderColor(props.inverted, props.borderless))};
  &:focus {
    ${(props) => (!props.borderless ? focusAndActiveState : '')};
  }
  &:focus-within {
    ${(props) => (!props.borderless ? focusAndActiveState : '')};
  }
`

const getInnerInputEl = (Element) => styled[Element]`
  background-color: transparent;
  border: none;
  color: ${getColor('copyOne')};
  font-family: ${getFont('body')};
  font-size: ${getFontSize(2)};
  line-height: 24px;
  -webkit-appearance: none;
  &::-webkit-input-placeholder {
    color: ${getColor('copyThree')};
  }
  width: 100%;
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const getInputVariant = (variant) => {
  if (variant === 'input') {
    return getInnerInputEl('input')
  }
  return getCoreInputEl(variant)
}

export default getInputVariant
