import styled from 'styled-components'

import { getColor, getFontSize, getFont } from '../../theme/themeHelpers.js'

const CharacterCount = styled.span`
  position: absolute;
  font-size: ${getFontSize(1)};
  color: ${getColor('mediumGrey')};
  margin: 0;
  top: -23px;
  right: 2px;
  font-family: ${getFont('body')};
`

export default CharacterCount
