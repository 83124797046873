import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { width, space, maxWidth } from 'styled-system'

import { getFont, getColor, getFontSize, getSpace } from '../../theme/themeHelpers.js'
import Icon from '../Icon/Icon'
import getInputVariant from '../Internal/getInputVariant'
import InputCharacterCount from '../Internal/InputCharacterCount'
import SpinnerIcon from '../Internal/SpinnerIcon'
import { Label, Error } from '../Typography/Index'

const Wrapper = styled.div`
  ${width};
  ${space};
  ${maxWidth};
  display: flex;
  flex-direction: column;
  font-family: ${getFont('body')};
`
/**
 * Specific styling applied to input div
 */
export const StyledBoxWithBorders = styled(getInputVariant('div'))`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${(props) =>
    props.disabled && !props.borderless ? getColor('gsCloud') : 'none'};
`

/**
 * Specific styling applied to icon in input
 */
const IconWrapper = styled.div`
  position: absolute;
  top: 11px;
`

const PrefixContainer = styled.div`
  padding-left: ${(props) => (props.noLeftPrefixPadding ? getSpace(0) : getSpace(3))};
  padding-right: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
`

const RightIconWrapper = styled(IconWrapper)`
  right: 11px;
`

const LeftIconWrapper = styled(IconWrapper)`
  left: 11px;
`

const ClearIconWrapper = styled(RightIconWrapper)`
  cursor: pointer;
  fill: ${getColor('gsCoal')};
`

const Unit = styled.div`
  background-color: transparent;
  color: ${getColor('gsGraphite')};
  font-family: ${getFont('body')};
  font-size: ${getFontSize(2)};
  line-height: 24px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-top: 7px;
`

/**
 * InputContainer is a component which looks and behaves like an input element,
 * but deals with everything external to the actual input. For example, the container
 * knows how to consistently render labels, icons, prefixes, errors, etc for inputs
 * but does not actually change the value of the input.
 */
class InputContainer extends React.PureComponent {
  getLeftIcons() {
    if (this.props.LeftIcon) {
      return (
        <LeftIconWrapper className="hover-responsive-icon">{this.props.LeftIcon}</LeftIconWrapper>
      )
    }
    return null
  }

  getRightIcons() {
    if (this.props.isLoading) {
      return (
        <RightIconWrapper>
          <SpinnerIcon />
        </RightIconWrapper>
      )
    }
    if (this.props.clearableField && this.props.value) {
      return (
        <ClearIconWrapper
          onClick={() => {
            if (this.props.clearFieldAction) {
              this.props.clearFieldAction()
              return
            }
            const event = { target: { value: '' } }
            this.props.onChange && this.props.onChange(event)
          }}
        >
          <Icon icon="icon-clear-search" width={16} height={16} color="gsRat" />
        </ClearIconWrapper>
      )
    }
    return null
  }

  render() {
    const {
      id,
      isActive,
      value,
      label,
      unit,
      width = '100%',
      maxLength,
      disabled,
      className,
      containerClassName,
      error,
      prefix,
      borderless,
      inverted,
      darkLabel,
      noLeftPrefixPadding,
      isRoundInput,
      ...rest
    } = this.props
    const wordsLeft = maxLength - (value ? value.length : 0)
    return (
      <Wrapper width={width} className={containerClassName} {...rest}>
        {label && (
          <Label htmlFor={id} color={darkLabel ? 'midNight' : null}>
            {label}
          </Label>
        )}
        <StyledBoxWithBorders
          className={className}
          disabled={disabled}
          borderless={borderless}
          value={value}
          inverted={inverted}
          isActive={isActive}
          isRoundInput={isRoundInput}
        >
          {maxLength && <InputCharacterCount>{wordsLeft}</InputCharacterCount>}
          {prefix && (
            <PrefixContainer noLeftPrefixPadding={noLeftPrefixPadding}>{prefix}</PrefixContainer>
          )}
          {this.getLeftIcons()}
          {this.getRightIcons()}
          {this.props.children}
          {unit && <Unit>{unit}</Unit>}
        </StyledBoxWithBorders>
        {error && <Error>{error}</Error>}
      </Wrapper>
    )
  }
}

InputContainer.propTypes = {
  value: PropTypes.string,
  /** Label for the input */
  /** Need object for react-intl */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Should the input label use a darker color */
  darkLabel: PropTypes.bool,
  /** Unit for the right side of the input */
  unit: PropTypes.string,
  /** Limit on number of characters */
  maxLength: PropTypes.number,
  /** Should input be disabled */
  disabled: PropTypes.bool,
  /** classname to apply to input wrapper element */
  className: PropTypes.string,
  /** classname to apply to container element */
  containerClassName: PropTypes.string,
  /** prefix to render in left hand side of input */
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** whether the input field has a button to clear */
  clearableField: PropTypes.bool,
  /** function to execute when clicking clear field button */
  clearFieldAction: PropTypes.func,
  /** controls whether the input shows a loading spinner */
  isLoading: PropTypes.bool,
  /** Whether to render input without a border */
  borderless: PropTypes.bool,
  /** Error from form to display */
  error: PropTypes.string,
  ...width.propTypes,
  ...space.propTypes,
  ...maxWidth.propTypes,
}

export default InputContainer
