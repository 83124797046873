/* eslint import/no-extraneous-dependencies:off */
import React from 'react'
import styled from 'styled-components'

import { getSpace, getBreakpoint } from '../../theme/themeHelpers'
import { Flex } from '../Layout/Base'

export const HeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
  height: 88px;
`

const Logo = styled.img`
  height: 50px;
  object-fit: contain;
  margin-right: ${getSpace(3)};
  @media (max-width: ${getBreakpoint(3)}) {
    height: 40px;
  }
`

const Icon = styled.img`
  height: 10px;
  width: 15px;
`

const BackContainer = styled(Flex)`
  cursor: pointer;
  padding: 12px;
  padding-left: 0;
  width: auto;
`

const BackIcon = ({ goBack }) => {
  if (!goBack) {
    return null
  }
  return (
    <BackContainer onClick={goBack}>
      <Icon
        src="https://fat-lama-assets.s3-eu-west-1.amazonaws.com/partnership-sites/arrow-icon.svg"
        height={10}
        width={11}
      />
    </BackContainer>
  )
}
const Header = ({ goBack, logoUrl, RightElement, showBorder }) => {
  return (
    <HeaderContainer bg="white" borderBottom={showBorder ? 'thinLight' : 'none'}>
      <Flex alignItems="center" width="auto">
        <BackIcon goBack={goBack} />
        <Logo
          src={
            logoUrl ||
            'https://fat-lama-assets.s3-eu-west-1.amazonaws.com/fatllama-logos/logo-no-llama.png'
          }
        />
      </Flex>

      {RightElement || ''}
    </HeaderContainer>
  )
}

export default Header
