import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import GetApi from '../../../utils/api'

import type { SecurityCall } from '../../../interfaces'

const getCacheKey = (userId?: number) => [`security-calls-${userId}`]

export const useSecurityCalls = ({ userId }: { userId: number }) => {
  const api = GetApi()

  return useQuery({
    enabled: !!userId,
    queryKey: getCacheKey(userId),
    queryFn: async () => {
      const { payload } = await api.getUserSecurityCalls(userId)
      return payload.secCalls as SecurityCall[]
    },
  })
}

export const useSecurityCallsMutation = (userId: number) => {
  const api = GetApi()
  const queryClient = useQueryClient()

  const revalidate = () => {
    queryClient.invalidateQueries({ queryKey: getCacheKey(userId) })
  }

  const requestSecurityCall = useMutation({
    mutationFn: async (data: {
      scheduledFor?: Date
      requestedBy: string
      visibleToUser: boolean
    }) => {
      const { payload } = await api.createSecurityCall({
        userId,
        scheduledFor: data.scheduledFor,
        visibleToUser: data.visibleToUser,
        requestedBy: data.requestedBy || 'ADMIN',
      })
      return payload
    },
    onSuccess: revalidate,
  })

  const logSecurityCall = useMutation({
    mutationFn: async (data: { id: number; answered: boolean; notes: string }) => {
      const { payload } = await api.updateSecurityCall(data)
      return payload
    },
    onSuccess: revalidate,
  })

  return {
    requestSecurityCall: (data: {
      scheduledFor?: Date
      requestedBy: string
      visibleToUser: boolean
    }) => {
      return requestSecurityCall.mutateAsync(data)
    },
    logSecurityCall: (data: { id: number; answered: boolean; notes: string }) => {
      return logSecurityCall.mutateAsync(data)
    },
    revalidate,
  }
}
