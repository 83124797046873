import PropTypes from 'prop-types'
import { omit } from 'ramda'
import React from 'react'

// check for SSR and transition support
const isTransitionEndSupported = () =>
  Boolean(typeof window !== 'undefined' && 'TransitionEvent' in window)

/**
 * Animates a dropdown using the css easing property. Wrap child elements inside
 * AnimateDropdown in order to use. Setting the prop height to 'auto' will expand the
 * children, while setting it to 0 will close them. Duration notes how long to
 * animate for in ms. Based on https://github.com/Stanko/react-animate-height
 * @extends React
 */
class AnimateDropdown extends React.Component {
  constructor(props) {
    super(props)
    let height = 'auto'
    let overflow = 'visible'
    if (!isNaN(this.props.height)) {
      height = this.props.height < 0 ? 0 : this.props.height
      overflow = this.props.transitionOverflow
    }
    this.state = { height, overflow }
  }

  componentDidMount() {
    if (this.contentElement && this.props.height === 0) {
      this.contentElement.style.display = 'none'
    }
  }

  nextHeightIsAuto(contentHeight, duration) {
    // Animate to height of children
    this.setState((state, props) => ({
      height: contentHeight,
      overflow: props.transitionOverflow,
    }))

    const isCurrentHeightAuto = this.state.height === 'auto'
    const timeoutDuration = isCurrentHeightAuto ? 50 : duration
    const timeoutHeight = isCurrentHeightAuto ? contentHeight : 'auto'

    // Set timeout to stop transition
    this.setTransitionTimeout(timeoutHeight, 'visible', timeoutDuration)
  }

  setTransitionTimeout(timeoutHeight, timeoutOverflow, timeoutDuration) {
    this.timeoutID = setTimeout(() => {
      this.setState({
        height: timeoutHeight,
        overflow: timeoutOverflow,
      })

      if (!isTransitionEndSupported()) {
        this.onTransitionEnd()
      }
    }, timeoutDuration)
  }

  nextHeightIsNumber(contentHeight, givenHeight) {
    const isCurrentHeightAuto = this.state.height === 'auto'

    const getNewHeight = () => {
      if (isCurrentHeightAuto) {
        return contentHeight
      }
      return givenHeight < 0 ? 0 : givenHeight
    }

    this.setState((state, props) => ({
      height: getNewHeight(),
      overflow: props.transitionOverflow,
    }))

    if (isCurrentHeightAuto) {
      const timeoutHeight = givenHeight < 0 ? 0 : givenHeight
      // Set timeout to stop transition
      this.setTransitionTimeout(timeoutHeight, this.props.transitionOverflow, 50)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { height } = this.props

    // Check if 'height' prop has changed
    if (!(this.contentElement && nextProps.height !== height)) {
      return
    }

    // Cache content height
    this.contentElement.style.display = ''
    this.contentElement.style.overflow = this.props.transitionOverflow
    // This is the magic. Gets the height of the wrapped children.
    const contentHeight = this.contentElement.offsetHeight
    this.contentElement.style.overflow = ''

    clearTimeout(this.timeoutID)

    if (!isNaN(nextProps.height)) {
      this.nextHeightIsNumber(contentHeight, nextProps.height)
    } else {
      this.nextHeightIsAuto(contentHeight, nextProps.duration)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID)
    this.timeoutID = null
  }

  onTransitionEnd() {
    if (this.contentElement && this.props.height === 0) {
      this.contentElement.style.display = 'none'
    }
    if (this.props.onAnimationComplete) {
      this.props.onAnimationComplete()
    }
  }

  render() {
    const { children, duration, easing, style, ...rest } = this.props

    const { height, overflow } = this.state

    const componentStyle = {
      ...style,
      height,
      overflow,
      WebkitTransition: `height ${duration}ms ${easing} `,
      MozTransition: `height ${duration}ms ${easing} `,
      OTransition: `height ${duration}ms ${easing} `,
      msTransition: `height ${duration}ms ${easing} `,
      transition: `height ${duration}ms ${easing} `,
    }

    const propsToPass = omit(['onAnimationComplete', 'height', 'transitionOverflow'], rest)

    return (
      <div
        style={componentStyle}
        onTransitionEnd={this.onTransitionEnd.bind(this)}
        {...propsToPass}
      >
        <div
          ref={(el) => {
            this.contentElement = el
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

AnimateDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // see https://easings.net/ for different easings
  easing: PropTypes.string,
  transitionOverflow: PropTypes.string,
  onAnimationComplete: PropTypes.func,
  style: PropTypes.object,
}

AnimateDropdown.defaultProps = {
  easing: 'ease',
  onAnimationComplete: null,
  transitionOverflow: 'hidden',
  style: {},
}

export default AnimateDropdown
