import { Flex, Text, H2 } from '@fatlama/llama-library'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Page } from '../../interfaces'
import Logout from '../Authentication/Logout'
import Icon from '../Common/Icon'

export const NAV_WIDTH = 155

const PointerFlex = styled(Flex)`
  cursor: pointer;
` as any

const NavItem = ({
  iconName,
  title,
  route,
}: {
  iconName: string
  title: string
  route: string
}) => {
  const location = useLocation()
  const isActive = location.pathname.includes(route)

  return (
    <Link to={route}>
      <PointerFlex alignItems="center" mb={2} p={2}>
        <Icon
          mr={2}
          height={18}
          width={18}
          icon={iconName}
          color={isActive ? 'marineMute' : 'white'}
        />

        <Text mb={0} color={isActive ? 'marineMute' : 'white'}>
          {title}
        </Text>
      </PointerFlex>
    </Link>
  )
}

const UserName = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const HyggloIcon = styled.img`
  height: 72px;
  width: 72px;

  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 16px;
  object-fit: cover;
  border-radius: 8px;
`

const CursorText = styled(Text)`
  cursor: pointer;
  margin-bottom: 8px;
`

const NavSideBar = () => {
  // const currentBaseUrl = getBaseurl();
  const location = useLocation()
  const isOnRentalPage = location.pathname.includes(Page.rentals + '/')
  const username = localStorage.getItem('username')

  const scrollToId = (id: string, block?: 'start' | 'center' | 'end' | undefined) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: block || 'center', // Center vertically in the viewport
        inline: 'nearest', // Horizontal alignment (optional)
      })
    }
  }

  return (
    <Flex
      bg="midNight"
      width={NAV_WIDTH}
      minWidth={NAV_WIDTH}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex flexDirection="column" px={2}>
        <Flex
          width="72px"
          height="72px"
          borderRadius="8px"
          overflow="hidden"
          bg="white"
          my={3}
          mx={2}
          justifyContent="center"
          alignItems="center"
        >
          <Icon height={57} width={42} icon="larry-logo" />
        </Flex>

        <HyggloIcon src="/images/app-icon-hygglo.png" />

        <NavItem route={Page.rentals} iconName="rentals" title="Rentals" />
        {isOnRentalPage ? (
          <Flex pl={4} flexDirection="column">
            <CursorText small onClick={() => scrollToId('otherRentals')} color="white">
              Other rentals
            </CursorText>
            <CursorText small onClick={() => scrollToId('secCalls')} color="white">
              Security calls
            </CursorText>
            <CursorText small onClick={() => scrollToId('confDocs')} color="white">
              Conf docs
            </CursorText>
            <CursorText small onClick={() => scrollToId('requirements', 'start')} color="white">
              Requirements
            </CursorText>
            <CursorText small onClick={() => scrollToId('approveSection')} color="white">
              Approvals
            </CursorText>
          </Flex>
        ) : null}

        <NavItem
          route={Page.customerRentals + '/userId'}
          iconName="profile"
          title="Customer rentals"
        />
      </Flex>

      <Flex flexDirection="column" p={2}>
        <Link to="/add">
          <H2 fontSize="40px">🤑</H2>
        </Link>
        <Text color="white" mb={0}>
          Logged in as:
        </Text>
        <UserName color="white" bold mb={2}>
          {username}
        </UserName>
        <Logout />
      </Flex>
    </Flex>
  )
}

export default NavSideBar
