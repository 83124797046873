import React from 'react'
import styled from 'styled-components'

import { getBreakpoint, resSettings } from '../theme/themeHelpers'

import { Box, Container, Flex } from './Layout/Base'
import { H1, Text } from './Typography/Index'

const TextRight = styled(Text)`
  white-space: nowrap;
  text-align: right;
  @media (min-width: ${getBreakpoint(3)}) {
    display: none;
  }
`

const Step = ({ step, totalSteps }) => {
  if (!step && !totalSteps) {
    return null
  }
  return (
    <TextRight large bold color="gsRat">
      Step {step}/{totalSteps}
    </TextRight>
  )
}

export const TemplateBody = ({ title, step, totalSteps, children, maxWidth, ...rest }) => {
  return (
    <Container justifyContent="center" pb={3} {...rest}>
      <Box maxWidth={maxWidth || '450px'}>{children}</Box>
    </Container>
  )
}

export const TemplateTop = ({ title, step, totalSteps, children, maxWidth, ...rest }) => {
  return (
    <Container justifyContent="center" pt={3} {...rest}>
      <Box maxWidth={maxWidth || '450px'}>
        <Flex justifyContent={resSettings('center', 'space-between', 3)} alignItems="center">
          <H1 mb={3}>{title}</H1>
          <Step step={step} totalSteps={totalSteps} />
        </Flex>
        {children}
      </Box>
    </Container>
  )
}

export const Template = ({ title, step, totalSteps, children, maxWidth, ...rest }) => {
  return (
    <Container justifyContent="center" py={3} {...rest}>
      <Box maxWidth={maxWidth || '450px'}>
        <Flex justifyContent={resSettings('center', 'space-between', 3)} alignItems="center">
          {title && <H1 mb={3}>{title}</H1>}
          <Step step={step} totalSteps={totalSteps} />
        </Flex>
        {children}
      </Box>
    </Container>
  )
}
