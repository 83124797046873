import PropTypes from 'prop-types'
import React from 'react'

import { SectionList } from '../SectionList/SectionList'

import withMultipleItems from './withMultipleItems'
import withSingleItem from './withSingleItem'

class CollapseBase extends React.PureComponent {
  render() {
    const { children, ...rest } = this.props
    return <SectionList {...rest}>{children}</SectionList>
  }
}

class Collapse extends React.PureComponent {
  constructor(props) {
    super(props)
    const wrapper = this.props.accordion ? withSingleItem : withMultipleItems
    this.CollapseComponent = wrapper(CollapseBase)
  }
  render() {
    const CollapseComponent = this.CollapseComponent

    return <CollapseComponent {...this.props} />
  }
}

Collapse.propTypes = {
  // if set to true only one item can be open at a time
  accordion: PropTypes.bool,
  children: PropTypes.node.isRequired,
  handler: PropTypes.func,
}

export default Collapse
