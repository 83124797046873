import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {
  space,
  width,
  color,
  flex,
  order,
  fontSize,
  alignSelf,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  borders,
  minWidth,
  minHeight,
  borderRadius,
  size,
  height,
  maxWidth,
  textAlign,
  boxShadow,
  borderColor,
  position,
  display,
  top,
  bottom,
  left,
  right,
  zIndex,
} from 'styled-system'

import { getBorder } from '../../theme/themeHelpers.js'

// Note that Box and Flex have 100% width by default
export const Box = styled.div`
  width: 100%;
  ${position};
  ${width};
  ${space};
  ${fontSize};
  ${color};
  ${flex};
  ${order};
  ${alignSelf};
  ${borders};
  ${borderColor};
  ${minWidth};
  ${minHeight};
  ${borderRadius};
  ${size};
  ${height};
  ${textAlign};
  ${maxWidth};
  ${boxShadow};
  ${display};
  ${top};
  ${bottom};
  ${left};
  ${right};
  ${zIndex};
`

export const Flex = styled(Box)`
  display: flex;
  ${flexWrap};
  ${flexDirection};
  ${alignItems};
  ${justifyContent};
`

export const FlexGrid = styled(Box)`
  display: flex-grid;
`

const ContainerStyle = styled(Flex)`
  max-width: ${(props) => props.maxWidth || props.theme.breakpoints[5]};
`

export const Container = (props) => <ContainerStyle px={3} {...props} />

Container.defaultProps = {
  mx: 'auto',
}

const HrStyle = styled(Box)`
  border-bottom: ${getBorder('thinExtraLight')};
`

export const Hr = (props) => <HrStyle height={props.height || '1px'} {...props} />

Hr.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
}
