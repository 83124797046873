import PropTypes from 'prop-types'
import { find, pathEq, identity } from 'ramda'
import React, { Children, cloneElement } from 'react'
import wrapDisplayName from 'recompose/wrapDisplayName'

// finds the first child with the 'initiallyExpanded' prop set to true
const getFirstExpandedChild = (children) => {
  const isInitiallyExpanded = pathEq(['props', 'initiallyExpanded'], true)
  const childToObj = (child, key) => (isInitiallyExpanded(child) ? child.key || key : null)
  const mappedChildren = Children.map(children, childToObj)
  return find(identity, mappedChildren) || null
}

// HOC
// Exposes bound openItem and closeItem methods in boundHandler object which is passed
// to all children to aid with manipulating which items are expanded.
const withSingleItem = (BaseComponent) => {
  class WithSingleItem extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        expanded: getFirstExpandedChild(props.children),
      }
    }

    // preserves the original onClick and calls it after setting state
    toggleItem(key, previousOnClick) {
      const { expanded } = this.state
      this.setState({ expanded: expanded === key ? null : key }, previousOnClick)
    }

    renderChild(child, key) {
      // attempt to set key to user given key, otherwise fallback to react generated one
      const keyToSet = child.key || key

      const previousOnClick = child.props.onClick
      const expanded = this.state.expanded === keyToSet
      const onClick = () => this.toggleItem(keyToSet, previousOnClick)

      // pass through bound methods to children
      const boundHandlers = {
        openItem: this.openItem.bind(this),
        closeItem: this.closeItem.bind(this),
      }
      // pass expanded to instruct children whether they are open
      return cloneElement(child, { ref: keyToSet, expanded, onClick, boundHandlers })
    }

    openItem(key) {
      this.setState({ expanded: key })
    }

    // closes specific key if given. If key is omitted closes any open items
    closeItem(key) {
      if (key === this.state.expanded) {
        return this.setState({ expanded: null })
      }
      return this.setState({ expanded: null })
    }

    render() {
      const { children, ...rest } = this.props
      return (
        <BaseComponent {...rest}>{Children.map(children, this.renderChild, this)}</BaseComponent>
      )
    }
  }

  WithSingleItem.propTypes = {
    children: PropTypes.node.isRequired,
  }

  // https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
  WithSingleItem.displayName = wrapDisplayName(BaseComponent, 'WithSingleItem')

  return WithSingleItem
}

export default withSingleItem
