import { OrderConfirmationDocumentRequirementType } from '../../interfaces'

export const AVAILABLE_CONFIRMATION_DOCUMENT_TEMPLATES: {
  id: number
  label: string
  value: string
}[] = [
  {
    id: 1,
    label: 'Film and Photo',
    value: OrderConfirmationDocumentRequirementType.FILM_AND_PHOTO,
  },
  {
    id: 2,
    label: 'Drones',
    value: OrderConfirmationDocumentRequirementType.DRONES,
  },
  {
    id: 3,
    label: 'DJ Equipment',
    value: OrderConfirmationDocumentRequirementType.DJ_EQUIPMENT,
  },
  {
    id: 4,
    label: 'Other',
    value: OrderConfirmationDocumentRequirementType.OTHER,
  },
]

export const DEFAULT_CONFIRMATION_DOCUMENT_TEMPLATES = {
  [OrderConfirmationDocumentRequirementType.DRONES]: {
    type: OrderConfirmationDocumentRequirementType.DRONES,
    description: `We would like to request the following information to verify your rental:
  
  - Drone footage/portfolio connected to your likeness, or credited to your name
  - Your drone license, if you own one`,
  },
  [OrderConfirmationDocumentRequirementType.FILM_AND_PHOTO]: {
    type: OrderConfirmationDocumentRequirementType.FILM_AND_PHOTO,
    description: `We would like to request the following information to verify your rental:
- Call sheet 
- Treatment
- Project brief 
- Location booking 
- Shot schedule 
- Invoice for shoot 
- Storyboard
- Booking confirmation via email/invoice/message conversation
  
Please make sure that the date of the shoot is visible on the document provided.`,
  },
  [OrderConfirmationDocumentRequirementType.DJ_EQUIPMENT]: {
    type: OrderConfirmationDocumentRequirementType.DJ_EQUIPMENT,
    description: `We would like to request the following information to verify your rental:
- Your name/ DJ name on an upcoming event
- A link to some music production connected to your name/ likeness
- Photos/Videos of past events using DJ equipment
- Birthday/Wedding invitation`,
  },
  [OrderConfirmationDocumentRequirementType.OTHER]: {
    type: OrderConfirmationDocumentRequirementType.OTHER,
    description: 'Reason for requesting this document',
  },
}
