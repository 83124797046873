import PropTypes from 'prop-types'
import React, { Children, cloneElement } from 'react'

import AnimateDropdown from '../Animation/AnimatedDropdown'
import { Flex } from '../Layout/Base'
import { SectionListItem } from '../SectionList/SectionList'
import { Text } from '../Typography/Index'

class CollapseItem extends React.PureComponent {
  renderBody({ expanded, children, boundHandlers }) {
    if (!children) {
      return null
    }

    return (
      <AnimateDropdown height={expanded ? 'auto' : 0} duration={300}>
        {Children.map(children, (child) => cloneElement(child, { boundHandlers }))}
      </AnimateDropdown>
    )
  }

  defaultHeader(text) {
    return ({ onClick }) => (
      <Flex justifyContent="space-between" alignItems="center" height="56px" onClick={onClick}>
        <Text light>{text}</Text>
      </Flex>
    )
  }

  render() {
    // passes through any boundHandlers injected by parent Collapse
    const { expanded, children, header, onClick, boundHandlers, disabled, ...rest } = this.props
    const Header = typeof header === 'string' ? this.defaultHeader(header) : header

    return (
      <SectionListItem {...rest}>
        <Header onClick={disabled ? null : onClick} />

        {this.renderBody({ expanded, children, boundHandlers })}
      </SectionListItem>
    )
  }
}

CollapseItem.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  expanded: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  boundHandlers: PropTypes.objectOf(PropTypes.func),
  // disables onClick
  disabled: PropTypes.bool,
  initiallyExpanded: PropTypes.bool,
}

export default CollapseItem
