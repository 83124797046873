import { Flex, Box, getColor, Text } from '@fatlama/llama-library'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import styled from 'styled-components'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import Icon from './Icon'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

let passwordTrys = 0

const RotatedLeftIcon = styled(Icon)`
  position: relative;
  right: 1px;
  transform: rotate(90deg);
`

const RotatedRightIcon = styled(Icon)`
  position: relative;
  left: 1px;
  transform: rotate(270deg);
`

const ClickableText = styled(Text)`
  color: ${getColor('whale')};
  cursor: pointer;
`

const PageNavigationArrow = styled(Flex)`
  background-color: ${getColor('gsWhite')};
  border: 1px solid ${getColor('midNight')};
  color: ${getColor('midNight')};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  opacity: ${(props: any) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props: any) => (props.disabled ? 'none' : 'auto')};
`

// These icons don't fit into our styled system because of their triangular nature,
// which makes them look weird sized compared to other things in our styled system. They are a special case.
const ChevronLeft = () => <RotatedLeftIcon icon="chevron" width={10} height={10} color="midNight" />
const ChevronRight = () => (
  <RotatedRightIcon icon="chevron" width={10} height={10} color="midNight" />
)

const PDF = ({ src, height }: any) => {
  const [numPages, setNumPages] = useState(0)
  const [renderBlank, setRenderBlank] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const windowDimensions = useWindowDimensions()

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages)
  }

  function onPassword(callback: any) {
    // To stop promot on load
    if (passwordTrys === 0 || passwordTrys === 3) {
      passwordTrys = 0
      setRenderBlank(true)
      return
    }
    passwordTrys += 1

    function callbackProxy(password: any) {
      // Cancel button handler
      if (password === null) {
        setRenderBlank(true)
        // Reset your `document` in `state`, un-mount your `<Document />`, show custom message, whatever
      }
      callback(password)
    }

    if (passwordTrys < 4 && passwordTrys > 0) {
      const password = prompt(
        `Enter the password to open this PDF file. (try ${passwordTrys - 1}/2)`
      )
      callbackProxy(password)
    }
  }

  return (
    <>
      <Flex
        flexDirection="column"
        maxHeight={isFullScreen ? '400px' : 'auto'}
        bg={isFullScreen ? 'gsCloud' : 'transparent'}
        alignItems="center"
        zIndex={isFullScreen ? 20 : 0}
        top={0}
        bottom={0}
        left={0}
        right={0}
        height={'100%'}
        width={isFullScreen ? '100%' : 'auto'}
        position={isFullScreen ? 'fixed' : 'relative'}
      >
        {numPages > 0 && (
          <>
            <Flex
              position="absolute"
              width="auto"
              height="100%"
              p={2}
              left={0}
              zIndex={2}
              justifyContent="center"
              flexDirection="column"
            >
              <PageNavigationArrow
                mr={1}
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                <ChevronLeft />
              </PageNavigationArrow>
            </Flex>

            <Flex
              position="absolute"
              width="auto"
              height="100%"
              p={2}
              right={0}
              zIndex={2}
              justifyContent="center"
              flexDirection="column"
            >
              <PageNavigationArrow
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numPages}
              >
                <ChevronRight />
              </PageNavigationArrow>
            </Flex>
          </>
        )}
        {!renderBlank ? (
          <Document
            onLoadError={(error) => {
              console.log(error)
            }}
            onPassword={onPassword}
            file={src}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              height={isFullScreen ? windowDimensions.height : height}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
            />
          </Document>
        ) : (
          <Flex justifyContent="center" alignItems="center" height="100%">
            <ClickableText
              bold
              onClick={() => {
                passwordTrys = 1
                setRenderBlank(false)
              }}
            >
              Password protected - Click to see PDF
            </ClickableText>
          </Flex>
        )}

        <Box
          bg="gsGraphite"
          width="auto"
          px={2}
          py={1}
          position="absolute"
          bottom={0}
          left={0}
          zIndex={3}
        >
          <Icon
            icon={isFullScreen ? 'fullscreen-2' : 'fullscreen-1'}
            small
            mb={0}
            color="white"
            onClick={() => setIsFullScreen(!isFullScreen)}
          />
        </Box>

        <Box bg="gsGraphite" width="auto" px={2} py={1} position="absolute" bottom={0} right={0}>
          <Text small mb={0} color="white" onClick={() => setPageNumber(pageNumber + 1)}>
            Page {pageNumber} of {numPages}
          </Text>
        </Box>
      </Flex>
    </>
  )
}

export default PDF
