const currencyMap = {
  GBP: '£',
  USD: '$',
  CAD: 'CA$',
  EUR: '€',
  SEK: 'kr',
  NOK: 'kr',
  DKK: 'kr',
} as any

export default currencyMap
