/* eslint-disable */


import styled from 'styled-components'
import { Label, Flex } from '@fatlama/llama-library'

import Select from 'react-select'

const Styler = styled(Flex)`
  .react-select {
    width: 100%;
    box-shadow:
      0px 1px 2px rgba(50, 50, 71, 0.08),
      0px 0px 1px rgba(50, 50, 71, 0.2);
    border: none;
  }
  cursor: pointer !important;
`

interface SelectProps {
  options: any
  value: any
  onChange: (v: any) => void
  placeholder: string
  isMulti: boolean
  isClearable: boolean
  label?: string
  fullWidth: boolean
  disabled?: boolean
}

export default ({
  options,
  value,
  onChange,
  placeholder,
  isMulti,
  isClearable,
  label,
  fullWidth,
  disabled,
  ...rest
}: SelectProps) => {
  return (
    <Flex height="auto" width={fullWidth ? '100%' : 'auto'} flexDirection="column">
      {label ? <Label>{label}</Label> : null}
      <Styler {...rest}>
        <Select
          isMulti={isMulti}
          placeholder={placeholder}
          className="react-select"
          value={options.find((el: any) => el.value === value)}
          onChange={onChange}
          isClearable={isClearable}
          options={options}
          isDisabled={disabled}
        />
      </Styler>
    </Flex>
  )
}
