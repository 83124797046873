import { theme, getSpace, getColor } from '@fatlama/llama-library'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import 'react-dates/initialize'

import './styles/index.css'
import App from './App'
import AppProvider from './state/Context'
import './utils/initReactDates'

const GlobalStyle = createGlobalStyle`
  table,
  th,
  td {
    border-collapse: collapse;
    
  }
  th,
  td,
  tr {
    padding-top: ${getSpace(2)};
    padding-bottom: ${getSpace(2)};
    padding-left: ${getSpace(2)};
    padding-right: ${getSpace(2)};
  }
  th {
    padding-bottom: ${getSpace(2)} !important;

  }
  .no-table-shadow {
    tr {
      padding-left: ${getSpace(0)} !important;
      padding-right: ${getSpace(0)} !important;
    }
    td:first-child, td:last-child,   th:first-child, th:last-child  {
      padding-left: ${getSpace(0)} !important;
      padding-right: ${getSpace(0)} !important;
    }
  
  }


  td {
    vertical-align: middle;
  }
  tr:nth-child(even) {
    background-color: rgb(250, 250, 250);
  }
 
  tr {
    border-bottom: 1px solid ${getColor('gsSlate')};
    &:hover {
      background-color: ${getColor('marineLight')};
    }
  }
  tr:first-child {
    &:hover {
      background-color: transparent;
    }
  }
  th {
    color: ${getColor('copyTwo')};
    text-align: left;
  }
  table {
    width: 100%
  }
  .no-row-hover {
    tr:hover {
      background-color: transparent !important;
    }
  }
  .clickable-rows {
    tr {
      cursor: pointer;
    }
  }
`

const space = {
  0: '0',
  1: '4px',
  2: '8px',
  3: '16px',
  4: '24px',
  5: '32px',
  6: '40px',
  7: '48px',
  8: '56px',
  9: '64px',
}

const orangeColor = '#ff9900'
const orangeLightColor = '#fff5e6'
// alreayd built with old and a faff to change nw
const themeWithOldSpacing = {
  ...theme,
  colors: {
    ...theme.colors,
    orange: orangeColor,
    orangeLight: orangeLightColor,
  },
  space,
}
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <ThemeProvider theme={themeWithOldSpacing}>
      <GlobalStyle />
      <AppProvider>
        <App />
      </AppProvider>
    </ThemeProvider>
  </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
