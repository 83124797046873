import { Modal, Flex, H3, Text, Button } from '@fatlama/llama-library'
import { DateTime } from 'luxon'
import { Fragment, useMemo, useState } from 'react'
import styled from 'styled-components'

import Icon from '../Common/Icon'
import Textarea from '../Common/TextArea'
import { ImageDisplay } from '../Documents/ImageOrVideo'
import Select from '../Select'
import { SectionBlock, SectionCollapsableBlock } from '../SelectedRental/Components'

import {
  AVAILABLE_CONFIRMATION_DOCUMENT_TEMPLATES,
  DEFAULT_CONFIRMATION_DOCUMENT_TEMPLATES,
} from './constants'
import {
  useAllUserConfirmationRequirements,
  useConfirmationDocuments,
  useConfirmationDocumentsMutation,
  useGetSignedUrls,
} from './useConfirmationDocuments'

import type { OrderConfirmationDocument, OrderConfirmationRequirementMessage } from '@/interfaces'
import type { FormEvent } from 'react'

import {
  ConfirmationDocumentRequirementStatus,
  ConfirmationDocumentStatus,
  OrderConfirmationDocumentRequirementType,
} from '@/interfaces'

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin: 16px 0;
`

const statusColor = (
  status: ConfirmationDocumentStatus | ConfirmationDocumentRequirementStatus
) => {
  return status === ConfirmationDocumentStatus.APPROVED ||
    status === ConfirmationDocumentRequirementStatus.COMPLETED
    ? 'primary'
    : status === ConfirmationDocumentStatus.REJECTED
      ? 'pomMute'
      : '#eeeeee'
}

// Obtain only the documents
const useAllApprovedUserConfirmationDocuments = (userId: number) => {
  const { data, isLoading, isError } = useAllUserConfirmationRequirements(userId)

  const documents = useMemo(() => {
    return (data ?? []).reduce((acc: any, requirement: any) => {
      if (!requirement.orderConfirmationDocuments) return acc
      requirement.orderConfirmationDocuments.forEach((doc: any) => {
        if (doc.status === ConfirmationDocumentStatus.APPROVED) {
          acc.push(doc)
        }
      })
      return acc
    }, [])
  }, [data])

  return { documents, isLoading, isError }
}

export const ConfirmationDocumentRequirementItem = ({
  status,
  statusUpdatedAt,
  description,
  id,
  userId,
  orderId,
  reRequestModalOpenForRequirementId,
  closeReRequestModal,
  setReRequestModalOpenForRequirementId,
  orderConfirmationRequirementMessages,
  orderConfirmationDocuments,
  setActiveSignedUrl,
}: {
  status: ConfirmationDocumentStatus | ConfirmationDocumentRequirementStatus
  statusUpdatedAt: Date
  description: string
  id: number
  userId: number
  orderId: number
  reRequestModalOpenForRequirementId: boolean
  closeReRequestModal: () => void
  setReRequestModalOpenForRequirementId: (id: number) => void
  orderConfirmationRequirementMessages: OrderConfirmationRequirementMessage[]
  orderConfirmationDocuments: OrderConfirmationDocument[]
  setActiveSignedUrl: (signedUrl: string | undefined) => void
}) => {
  const {
    deleteConfirmationDocumentRequirement,
    approveConfirmationDocumentRequirement: {
      mutateAsync: approveConfirmationDocumentRequirementAsync,
      isPending: isApproving,
    },
  } = useConfirmationDocumentsMutation({ orderId, userId })

  const handleApproveConfirmationDocumentRequirement = (requirementId: number) => {
    approveConfirmationDocumentRequirementAsync(requirementId)
    setDocumentsHasBeenRejectedWithoutReRequest(false)
  }

  const handleDeleteConfirmationDocumentRequirement = (requirementId: number) => {
    const confirmation = confirm(
      'Are you sure you want to delete this confirmation document requirement?'
    )
    if (confirmation) {
      deleteConfirmationDocumentRequirement.mutateAsync(requirementId)
    }
  }

  // Whether a admin has made changes to the documents but not re-requested (local state only)
  const [documentsHasBeenRejectedWithoutReRequest, setDocumentsHasBeenRejectedWithoutReRequest] =
    useState(false)

  return (
    <Flex flexDirection="row">
      <Flex mr={3} width="10px" borderRadius={3} bg={statusColor(status)} />
      <Flex flexDirection="column" pr={3}>
        <Text color={statusColor(status)} title={statusUpdatedAt}>
          {status}
        </Text>
        <Text style={{ whiteSpace: 'pre-wrap', fontStyle: 'italic' }} mb={3}>
          &quot;{description}&quot;
        </Text>
        <Flex flexDirection="row">
          <Button
            small
            onClick={() => handleApproveConfirmationDocumentRequirement(id)}
            disabled={status === ConfirmationDocumentRequirementStatus.COMPLETED || isApproving}
          >
            {status === ConfirmationDocumentRequirementStatus.COMPLETED ? 'Completed' : 'Approve'}
          </Button>
          <Button
            ml={2}
            small
            variant="alert"
            onClick={() => {
              setReRequestModalOpenForRequirementId(id)
            }}
          >
            Re-request
          </Button>
          <ReRequestModal
            userId={userId}
            orderId={orderId}
            isOpen={reRequestModalOpenForRequirementId}
            onClose={closeReRequestModal}
            requirementId={id}
            defaultDescription={description}
            setDocumentsHasBeenRejectedWithoutReRequest={
              setDocumentsHasBeenRejectedWithoutReRequest
            }
          />
          <Flex flex={1} />
          <Button
            variant="secondary"
            small
            onClick={() => handleDeleteConfirmationDocumentRequirement(id)}
          >
            Delete
          </Button>
        </Flex>
        {documentsHasBeenRejectedWithoutReRequest && (
          <Text small mt={3}>
            Changes has been made to documents but not re-requested. Re-request to notify borrower
            of changes needed.
          </Text>
        )}
      </Flex>
      <Flex flexDirection="column">
        {orderConfirmationRequirementMessages &&
        orderConfirmationRequirementMessages.length === 0 ? null : (
          <>
            <Text bold>Uploaded confirmation message:</Text>
            <Flex mb={4}>
              {orderConfirmationRequirementMessages?.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Text key={i} mb={0}>
                  {item.message}
                </Text>
              ))}
            </Flex>
          </>
        )}

        {orderConfirmationDocuments && orderConfirmationDocuments.length === 0 ? (
          <Text textAlign="center">No uploaded confirmation documents</Text>
        ) : (
          <Text bold>Uploaded confirmation documents:</Text>
        )}
        {orderConfirmationDocuments?.map((document, i) => (
          <ConfirmationDocumentItem
            key={document.id}
            index={i}
            orderId={orderId}
            userId={userId}
            document={document}
            onClick={setActiveSignedUrl}
            setDocumentsHasBeenRejectedWithoutReRequest={
              setDocumentsHasBeenRejectedWithoutReRequest
            }
          />
        ))}
      </Flex>
    </Flex>
  )
}

export const ConfirmationDocumentItem = ({
  index,
  orderId,
  userId,
  document,
  onClick,
  setDocumentsHasBeenRejectedWithoutReRequest,
}: {
  index: number
  orderId: number
  userId: number
  document: OrderConfirmationDocument
  onClick: (signedUrl: string | undefined) => void
  setDocumentsHasBeenRejectedWithoutReRequest: (
    documentsHasBeenRejectedWithoutReRequest: boolean
  ) => void
}) => {
  const [rejectModalOpenForDocumentId, setRejectModalOpenForDocumentId] = useState<number | null>(
    null
  )

  const { data: signedUrls } = useGetSignedUrls([document.url])
  const signedUrl = signedUrls?.[0]

  const { approveConfirmationDocument, rejectConfirmationDocument } =
    useConfirmationDocumentsMutation({
      orderId,
      userId,
    })

  const handleApproveConfirmationDocument = async (documentId: number) => {
    await approveConfirmationDocument.mutateAsync(documentId)
  }

  const handleRejectConfirmationDocument = async (payload: {
    documentId: number
    reason: string
  }) => {
    await rejectConfirmationDocument.mutateAsync(payload)
    setDocumentsHasBeenRejectedWithoutReRequest(true)
  }

  return (
    <>
      <Flex
        key={document.id}
        flexDirection="row"
        mt={index === 0 ? 0 : 2}
        border="thinLight"
        borderRadius={8}
        alignItems="center"
        p={3}
        width="100%"
      >
        <Flex flex={1} flexDirection="column" width="100px" pr={3}>
          <Text
            color={statusColor(document.status)}
            title={`Reason: ${document.rejectedReason} - ${DateTime.fromJSDate(
              document.statusUpdatedAt
            ).toFormat('dd MMM yyyy HH:mm')}`}
            mb={0}
          >
            {document.status}
          </Text>
          <Text
            small
            bold
            mb={0}
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
          >
            {document.fileName ?? 'Unknown file name'}
          </Text>
          <Text
            small
            mb={0}
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              display: 'block',
            }}
          >
            {document.comment ?? 'No comment from user.'}
          </Text>
        </Flex>
        <Flex flex={0}>
          <Button
            small
            variant="secondary"
            title={signedUrl ? 'View document' : 'No signed URL'}
            disabled={!signedUrl}
            onClick={() => {
              onClick(signedUrl)
            }}
          >
            View
          </Button>
          <Button
            mx={2}
            small
            variant="primary"
            disabled={document.status === ConfirmationDocumentStatus.APPROVED}
            onClick={() => handleApproveConfirmationDocument(document.id)}
          >
            <Icon icon="check-bold" />
          </Button>
          <Button
            small
            variant="alert"
            disabled={document.status === ConfirmationDocumentStatus.REJECTED}
            onClick={() => setRejectModalOpenForDocumentId(document.id)}
          >
            <Icon icon="close" />
          </Button>
          <RejectDocumentModal
            onReject={handleRejectConfirmationDocument}
            isRejecting={rejectConfirmationDocument.isPending}
            isOpen={rejectModalOpenForDocumentId === document.id}
            onClose={() => setRejectModalOpenForDocumentId(null)}
            documentId={document.id}
          />
        </Flex>
      </Flex>
    </>
  )
}

export const ConfirmationDocuments = ({ orderId, userId }: { orderId: number; userId: number }) => {
  const [type, setType] = useState(OrderConfirmationDocumentRequirementType.FILM_AND_PHOTO)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [reRequestModalOpenForRequirementId, setReRequestModalOpenForRequirementId] = useState<
    number | null
  >(null)
  const [activeSignedUrl, setActiveSignedUrl] = useState<string | undefined>(undefined)

  const { data: requirements, error } = useConfirmationDocuments(orderId)

  const userDocumentStatus = useMemo(() => {
    if (!requirements) return null

    return requirements?.reduce(
      (acc, requirement) => {
        if (!requirement.orderConfirmationDocuments) return acc
        requirement.orderConfirmationDocuments.forEach((doc) => {
          if (doc.status === ConfirmationDocumentStatus.PENDING) {
            acc.pending++
          } else if (doc.status === ConfirmationDocumentStatus.APPROVED) {
            acc.approved++
          } else if (doc.status === ConfirmationDocumentStatus.REJECTED) {
            acc.rejected++
          }
        })
        return acc
      },
      {
        pending: 0,
        approved: 0,
        rejected: 0,
      }
    )
  }, [requirements])

  const buttonText = requirements && requirements.length > 0 ? 'Request more' : 'Request'

  if (error) {
    return (
      <SectionBlock mb={3} id="confirmation-documents">
        <Text>Error: {error.message}</Text>
      </SectionBlock>
    )
  }

  return (
    <SectionBlock mb={3} id="confirmation-documents">
      <H3>Confirmation Document Requirement</H3>
      {userDocumentStatus && (
        <Text>{userDocumentStatus.pending} pending confirmation documents</Text>
      )}
      <Divider />
      {requirements?.map((requirement) => (
        <Fragment key={requirement.id}>
          <ConfirmationDocumentRequirementItem
            reRequestModalOpenForRequirementId={
              reRequestModalOpenForRequirementId === requirement.id
            }
            closeReRequestModal={() => setReRequestModalOpenForRequirementId(null)}
            userId={userId}
            orderId={orderId}
            id={requirement.id}
            status={requirement.status}
            statusUpdatedAt={requirement.statusUpdatedAt}
            description={requirement.description}
            setActiveSignedUrl={setActiveSignedUrl}
            setReRequestModalOpenForRequirementId={setReRequestModalOpenForRequirementId}
            orderConfirmationRequirementMessages={requirement.orderConfirmationRequirementMessages}
            orderConfirmationDocuments={requirement.orderConfirmationDocuments}
          />
          <Divider />
        </Fragment>
      ))}

      <Flex flexDirection="row">
        <Select
          options={AVAILABLE_CONFIRMATION_DOCUMENT_TEMPLATES}
          value={type}
          onChange={(v) => {
            setType(v.value)
          }}
          placeholder="Select a template..."
          fullWidth
          isMulti={false}
          isClearable={false}
        />
        <Button ml={3} onClick={() => setCreateModalOpen(true)}>
          {buttonText}
        </Button>
      </Flex>
      <DocumentPreviewModal
        isOpen={activeSignedUrl !== undefined}
        onClose={() => setActiveSignedUrl(undefined)}
        url={activeSignedUrl}
      />
      <CreateConfirmationDocumentRequestModal
        key={type}
        userId={userId}
        orderId={orderId}
        type={type}
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </SectionBlock>
  )
}

const RejectDocumentModal = ({
  isOpen,
  onClose,
  documentId,
  onReject,
  isRejecting,
}: {
  isOpen: boolean
  onClose: () => void
  documentId: number
  onReject: (payload: { documentId: number; reason: string }) => Promise<void>
  isRejecting: boolean
}) => {
  const [rejectionReason, setRejectionReason] = useState('')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await onReject({ documentId, reason: rejectionReason })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} p={0}>
      <H3>Reject</H3>
      <Text small>
        This will reject a specific document. To notify the user about the review, re-request the
        confirmation documents.
      </Text>

      <form onSubmit={handleSubmit}>
        <Textarea
          rows={4}
          placeholder="Description"
          value={rejectionReason}
          onChange={(e: any) => setRejectionReason(e.target.value)}
        />
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button large ml={2} type="submit" disabled={isRejecting || !rejectionReason}>
            Reject
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}

const ReRequestModal = ({
  userId,
  orderId,
  isOpen,
  onClose,
  defaultDescription,
  requirementId,
  setDocumentsHasBeenRejectedWithoutReRequest,
}: {
  userId: number
  orderId: number
  isOpen: boolean
  onClose: () => void
  requirementId: number
  defaultDescription: string
  setDocumentsHasBeenRejectedWithoutReRequest: (
    documentsHasBeenRejectedWithoutReRequest: boolean
  ) => void
}) => {
  const [description, setDescription] = useState(defaultDescription)
  const {
    reRequestConfirmationDocumentRequirement: {
      mutateAsync: reRequestConfirmationDocumentRequirement,
      isPending: isReRequesting,
    },
  } = useConfirmationDocumentsMutation({ orderId, userId })

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await reRequestConfirmationDocumentRequirement({
      requirementId,
      description,
    })
    setDocumentsHasBeenRejectedWithoutReRequest(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} p={0}>
      <H3>Re-request</H3>
      <form onSubmit={handleSubmit}>
        <Textarea
          rows={4}
          placeholder="Description"
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button large ml={2} type="submit" disabled={isReRequesting || !description}>
            Re-request
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}

const CreateConfirmationDocumentRequestModal = ({
  userId,
  orderId,
  isOpen,
  onClose,
  type,
}: {
  userId: number
  orderId: number
  isOpen: boolean
  onClose: () => void
  type: OrderConfirmationDocumentRequirementType
}) => {
  const {
    createConfirmationDocumentRequirement: {
      mutateAsync: createConfirmationDocumentRequirement,
      isPending: isCreating,
    },
  } = useConfirmationDocumentsMutation({ orderId, userId })

  const [items, setItems] = useState<
    Array<{
      type: OrderConfirmationDocumentRequirementType
      description: string
    }>
  >([DEFAULT_CONFIRMATION_DOCUMENT_TEMPLATES[type]])

  const handleInputChange = (index: number, field: 'description', value: string) => {
    setItems((prevItems) =>
      prevItems.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    )
  }

  const handleSendRequest = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const payload = items[0] // Future: can handle multiple items
    await createConfirmationDocumentRequirement(payload)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} p={0}>
      <H3>Request new confirmation documents</H3>
      <Divider />
      <form onSubmit={handleSendRequest}>
        <Flex
          flexDirection="column"
          flex={1}
          style={{ maxHeight: '70vh', overflow: 'scroll' }}
          p={1}
        >
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {index !== 0 && <Divider />}
              <Flex flexDirection="column">
                <Textarea
                  rows={4}
                  placeholder="Description"
                  value={item.description}
                  onChange={(e: any) => handleInputChange(index, 'description', e.target.value)}
                />
              </Flex>
            </Fragment>
          ))}
        </Flex>
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button large ml={2} type="submit" disabled={isCreating}>
            Send request
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}

const DocumentPreviewModal = ({
  url,
  isOpen,
  onClose,
}: {
  url: string | undefined
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} p={0}>
      <ImageDisplay allowDownload src={url} defaultAsPdf={false} />
    </Modal>
  )
}

export const AllUserConfirmationDocuments = ({ userId }: { userId: number }) => {
  const { documents, isLoading, isError } = useAllApprovedUserConfirmationDocuments(userId)

  const [activeDocument, setActiveDocument] = useState<OrderConfirmationDocument | undefined>(
    undefined
  )

  const { data: signedUrls } = useGetSignedUrls(activeDocument ? [activeDocument.url] : [])
  const signedUrl = signedUrls?.[0]

  if (isError) {
    return <Text>Something went wrong loading all user confirmation documents.</Text>
  }

  return (
    <>
      {isLoading ? (
        'Loading...'
      ) : (
        <SectionCollapsableBlock
          initiallyClosed
          mb={3}
          Title={
            <Flex flexDirection="column" width="100%">
              <H3>All approved confirmation documents</H3>
              {documents && documents.length > 0 && (
                <Text>
                  {documents.length} approved confirmation documents for this customer including all
                  orders.
                </Text>
              )}
            </Flex>
          }
        >
          <DocumentPreviewModal
            isOpen={signedUrl !== undefined}
            onClose={() => setActiveDocument(undefined)}
            url={signedUrl}
          />
          <Divider />
          {documents.map((doc: any) => (
            <Text link key={doc.id} onClick={() => setActiveDocument(doc)}>
              {doc.fileName ?? 'Unknown file name'}
            </Text>
          ))}
        </SectionCollapsableBlock>
      )}
    </>
  )
}
