import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'

import GetApi from '../../utils/api'

import type { OrderConfirmationDocumentRequirementType } from '@/interfaces'

const getCacheKey = (orderId?: number) => [`confirmation-documents-${orderId}`]
const getSignedUrlsCacheKey = (urls: string[]) => [`signed-urls-${urls.join('-')}`]

const getAllUserConfirmationRequirementsCacheKey = (userId: number) => [
  `all-user-confirmation-requirements-${userId}`,
]

export const useConfirmationDocuments = (orderId?: number) => {
  const api = GetApi()

  return useQuery({
    enabled: !!orderId,
    queryKey: getCacheKey(orderId),
    queryFn: async () => {
      const { payload } = await api.getConfirmationDocuments(orderId as number)
      return payload
    },
  })
}

export const useAllUserConfirmationRequirements = (userId: number) => {
  const api = GetApi()
  return useQuery({
    enabled: !!userId,
    queryKey: getAllUserConfirmationRequirementsCacheKey(userId),
    queryFn: async () => {
      const { payload } = await api.getAllUserConfirmationRequirements(userId)
      return payload
    },
  })
}

export const useGetSignedUrls = (urls: string[]) => {
  const api = GetApi()
  return useQuery({
    queryKey: getSignedUrlsCacheKey(urls),
    queryFn: async () => {
      const { payload } = await api.getSignedUrls({ urls })
      return payload
    },
    retry: false,
  })
}

export const useConfirmationDocumentsMutation = ({
  orderId,
  userId,
}: {
  orderId: number
  userId: number
}) => {
  const api = GetApi()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const revalidate = () => {
    queryClient.invalidateQueries({ queryKey: getCacheKey(orderId) })
    queryClient.invalidateQueries({
      queryKey: getAllUserConfirmationRequirementsCacheKey(userId),
    })
  }

  const createConfirmationDocumentRequirement = useMutation({
    mutationFn: async (data: {
      type: OrderConfirmationDocumentRequirementType
      description: string
    }) => {
      const { payload } = await api.createConfirmationDocumentRequirement(orderId, data)
      navigate(`${pathname}?refresh=true`)

      return payload
    },
    onSuccess: revalidate,
  })

  const deleteConfirmationDocumentRequirement = useMutation({
    mutationFn: async (requirementId: number) => {
      const { payload } = await api.deleteConfirmationDocumentRequirement(requirementId)
      navigate(`${pathname}?refresh=true`)
      return payload
    },
    onSuccess: revalidate,
  })

  const approveConfirmationDocumentRequirement = useMutation({
    mutationFn: async (requirementId: number) => {
      const { payload } = await api.approveConfirmationDocumentRequirement(requirementId)
      navigate(`${pathname}?refresh=true`)
      return payload
    },
    onSuccess: revalidate,
  })

  const reRequestConfirmationDocumentRequirement = useMutation({
    mutationFn: async (data: { requirementId: number; description: string }) => {
      const { payload } = await api.reRequestConfirmationDocumentRequirement(
        data.requirementId,
        data.description
      )
      navigate(`${pathname}?refresh=true`)
      return payload
    },
    onSuccess: revalidate,
  })

  const approveConfirmationDocument = useMutation({
    mutationFn: async (documentId: number) => {
      const { payload } = await api.approveConfirmationDocument(documentId)
      return payload
    },
    onSuccess: revalidate,
  })

  const rejectConfirmationDocument = useMutation({
    mutationFn: async (data: { documentId: number; reason: string }) => {
      const { payload } = await api.rejectConfirmationDocument(data.documentId, data.reason)
      return payload
    },
    onSuccess: revalidate,
  })

  return {
    createConfirmationDocumentRequirement,
    deleteConfirmationDocumentRequirement,
    approveConfirmationDocumentRequirement,
    reRequestConfirmationDocumentRequirement,
    approveConfirmationDocument,
    rejectConfirmationDocument,
  }
}
