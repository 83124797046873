import { Box, Flex, getBorder, getSpace, Text } from '@fatlama/llama-library'
import { useState } from 'react'
import styled from 'styled-components'

import Icon from '../Common/Icon'
import { FadeInHeightBox } from '../Common/TransitionContainers'

export const SectionBlock = styled(Box)`
  border: ${({ noBorder }) => (!noBorder ? getBorder('thinLight') : '')};
  padding: ${getSpace(3)};
  border-radius: 16px;
  margin-bottom: 56px;
  background-color: white;
  position: relative;
`

const RotatableIcon = styled(Icon)`
  position: relative;
  right: 1px;
  transform: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 300ms;
` as any

export const TwoGrid = styled(Box)`
  display: grid;
  grid-column-gap: ${getSpace(3)};
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${getSpace(3)};
`

export const SectionCollapsableBlock = ({
  children,
  Title,
  initiallyClosed = false,
  noBorder = false,
}: any) => {
  const [isOpen, setIsOpen] = useState(!initiallyClosed)
  return (
    <SectionBlock noBorder={noBorder}>
      <Flex alignItems="center" justifyContent="space-between" onClick={() => setIsOpen(!isOpen)}>
        {Title}
        <RotatableIcon isOpen={isOpen} icon="chevron2" width={24} height={15} color="gsRat" />
      </Flex>

      <FadeInHeightBox shouldShow={isOpen}>{children}</FadeInHeightBox>
    </SectionBlock>
  )
}

const WhiteSpaceText = styled(Text)`
  white-space: break-spaces;
`

export const DataRow = ({
  title,
  value,
  bg,
  alert,
  reverseSize,
  rowCount,
}: {
  title: string
  value: any
  bg?: string
  alert?: boolean
  reverseSize?: boolean
  rowCount?: number
}) => {
  return (
    <Flex
      borderBottom="thinLight"
      py={2}
      alignItems="center"
      bg={bg ?? (rowCount && rowCount % 2 === 0 ? 'gsMist' : undefined)}
    >
      <WhiteSpaceText
        small
        mb={0}
        width={reverseSize ? '60%' : '35%'}
        bold={alert}
        color={alert ? 'pomMute' : 'copyOne'}
      >
        {title}
      </WhiteSpaceText>
      <WhiteSpaceText
        small
        mb={0}
        width={reverseSize ? '30%' : '65%'}
        bold={alert}
        color={alert ? 'pomMute' : 'copyOne'}
      >
        {value}
      </WhiteSpaceText>
    </Flex>
  )
}

export const DoubleDataRow = ({
  title,
  value,
  value2,
  bg,
  rowCount,
}: {
  title: string
  value: any
  value2: any
  bg?: string
  rowCount?: number
}) => {
  return (
    <Flex
      id="tableRow"
      borderBottom="thinLight"
      py={2}
      bg={bg ?? (rowCount && rowCount % 2 === 0 ? 'gsMist' : undefined)}
    >
      <Text small mb={0} width={1 / 3}>
        {title}
      </Text>
      <Text small mb={0} width={1 / 3}>
        {value}
      </Text>
      <Text small mb={0} width={1 / 3}>
        {value2}
      </Text>
    </Flex>
  )
}
