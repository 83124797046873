import { Box, Button, Hr } from '@fatlama/llama-library'
import { Formik } from 'formik'
import { DateTime } from 'luxon'

import { roundToNearest15 } from '../../../helpers/roundToNearest15'

import { SharedRequestSecurityCallForm, sharedValidate } from './SharedRequestSecurityCallForm'

const validate = sharedValidate(false)

export const RequestNewSecurityCall = ({
  requestSecurityCall,
  isLoadingSecurityCalls,
}: {
  isLoadingSecurityCalls: boolean

  requestSecurityCall: (params: {
    scheduledFor?: Date
    requestedBy: string
    visibleToUser: boolean
  }) => Promise<void>
}) => {
  const onSubmit = async (values: any, formikBag: any) => {
    await requestSecurityCall({
      visibleToUser: values.visibleToUser === 'YES',
      requestedBy: 'ADMIN',
      scheduledFor: values.callDate ? values.callDate : undefined,
    })
    formikBag.resetForm()
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validate={validate}
      initialErrors={{
        notes: 'Notes are required',
      }}
      initialValues={{
        willCallNow: '',
        callDate: roundToNearest15(DateTime.now().toJSDate()),
        notes: '',
        visibleToUser: 'NO',
      }}
      isInitialValid={true}
    >
      {({ handleSubmit, values, setFieldValue, errors, isValid }) => {
        return (
          <Box>
            <Hr mt={4} mb={4} />
            <SharedRequestSecurityCallForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              showRequestedByUser={false}
            />
            <Box>
              <Button
                disabled={!isValid}
                loading={isLoadingSecurityCalls}
                label="Request"
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}
