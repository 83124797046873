import fetch from 'isomorphic-unfetch'
import * as qs from 'qs'

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  // 'Access-Control-Request-Headers': 'origin, x-requested-with'
}

export enum RequestType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export interface RequestObj {
  url: string
  payload?: any
  method: RequestType
  params?: any
}

const checkStatus = async (response: any) => {
  // Note that the promise won't be rejected in case of HTTP 4xx or 5xx server responses.
  // The promise will be resolved just as it would be for HTTP 2xx.
  const is400 = response.status >= 400 && response.status <= 499
  const is500 = response.status >= 500 && response.status <= 599
  if (response.ok && !is400 && !is500) {
    return response
  }
  console.log(response.status, '-response===========')
  console.log(response, '-response')
  if (response.status === 404) {
    return response
  }
  if (response.status === 403) {
    return (window.location.href = '/error/403')
  }
  if (response.status === 401) {
    localStorage.clear()
    window?.location.reload()
    return
  }

  const error = new Error(response.statusText) as any
  error.response = response

  const parsed = await response.json()
  console.log(parsed, '===parsed')
  error.message = parsed.message
  return Promise.reject(error)
}

export interface FetcherConfig {
  rtn200Failures?: boolean
  signal?: any
  returnHeaders?: boolean
}

export const makeApiCall =
  ({ navigate }: { navigate: (path: string) => void }) =>
  async (requestObj: RequestObj, config?: FetcherConfig) => {
    const url = requestObj.params
      ? requestObj.url + '?' + qs.stringify(requestObj.params)
      : requestObj.url

    const token = localStorage.getItem('token')

    // options.headers.set('Authorization', `Bearer ${token}`);

    const response = await fetch(url, {
      method: requestObj.method,
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
      signal: config && config.signal,
      mode: 'cors',
      // credentials: "include",
      body: JSON.stringify(requestObj.payload),
    })

    const checkResponseStatus = await checkStatus(response)

    if (checkResponseStatus.status === 403) {
      navigate('/error/403')
      return {
        success: false,
      }
    }

    const raw = await response.text()
    let parsedResponse
    try {
      parsedResponse = JSON.parse(raw)
    } catch {
      parsedResponse = { message: raw }
    }

    if (response.status >= 400) {
      return {
        success: false,
        message: parsedResponse.message,
      }
    }

    if (config?.returnHeaders) {
      return {
        success: true,
        payload: parsedResponse,
        headers: response.headers,
      }
    }

    return {
      success: true,
      payload: parsedResponse,
    }
  }
