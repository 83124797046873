import { H3 } from '@fatlama/llama-library'
import { prop } from 'ramda'
import styled from 'styled-components'

import { SectionCollapsableBlock } from './Components'

import type { UserCreditScore } from '../../interfaces/index'

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const CustomerCreditScoreComponent = ({
  customerCreditScore,
}: {
  customerCreditScore?: UserCreditScore
}): any => {
  return (
    <>
      {customerCreditScore && customerCreditScore.createdAt && (
        <SectionCollapsableBlock mb={3} Title={<H3>Customer credit report</H3>}>
          <table>
            <tbody>
              {Object.keys(customerCreditScore).map((key) => {
                if (key === 'raw') {
                  return null
                }
                return (
                  <tr key={key}>
                    <TH>{key}</TH>
                    <TD>{JSON.stringify(prop(key, customerCreditScore))}</TD>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </SectionCollapsableBlock>
      )}
    </>
  )
}

export default CustomerCreditScoreComponent
