const CURRENCT_FX_MAP = {
  ['GBP']: 1,
  ['USD']: 1.38,
  ['CAD']: 1.73,
  ['SEK']: 13.83,
  ['NOK']: 13.49,
  ['DKK']: 8.68,
  ['EUR']: 1.16,
}

export default CURRENCT_FX_MAP
