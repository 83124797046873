import { Text, Box, getColor } from '@fatlama/llama-library'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const CopiedBox = styled(Box)`
  width: auto;
  opacity: 0.9;
  position: absolute;
  border-radius: 10px;
  height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  top: -10px;
  right: -30px;
  z-index: 10;
  transform: rotate(15deg);
  background: white;
  border: 1px solid ${getColor('pomMute')};
`

const CursorBox = styled(Box)`
  cursor: pointer;
  user-select: none;
`

const ClickToCopy = ({ text, children }: any) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(false)
    }, 1700)
  }, [visible])

  return (
    <CursorBox
      cursor="pointer"
      position="relative"
      width="auto"
      onClick={() => {
        navigator.clipboard.writeText(text)
        setVisible(true)
      }}
    >
      {visible && (
        <CopiedBox>
          <Text mb={0} small color="pomMute">
            Copied!
          </Text>
        </CopiedBox>
      )}
      {children}
    </CursorBox>
  )
}

export default ClickToCopy
