import { Flex, H3 } from '@fatlama/llama-library'
import styled from 'styled-components'

import { formatDateTimeUTC } from '../../utils/dateUtils'

import { SectionCollapsableBlock } from './Components'

import type { AdminEvent } from '../../interfaces/index'

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const AdminEvents = ({ events }: { events: AdminEvent[] }): any => {
  if (!events || events.length === 0) {
    return null
  }
  return (
    <Flex flexDirection="column">
      <SectionCollapsableBlock mb={3} Title={<H3>Admin events for Order</H3>}>
        <table>
          <tbody>
            <tr>
              <TH>Type</TH>
              <TH>Admin name</TH>

              <TH>Description</TH>

              <TH>Timestamp</TH>
            </tr>

            {events.map((event) => {
              return (
                <tr key={event.id}>
                  <TD>{event.type}</TD>
                  <TD>{event.adminName}</TD>
                  <TD>{event.description}</TD>
                  <TD>{formatDateTimeUTC(event.createdAt, 'd MMM yy, HH:mm')}</TD>
                </tr>
              )
            })}
          </tbody>
        </table>
      </SectionCollapsableBlock>
    </Flex>
  )
}

export default AdminEvents
