import { Flex, Box, Button, Error, Input } from '@fatlama/llama-library'
import { Formik, Field } from 'formik'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { AutoApproveDisabledReason } from '../../interfaces'
import GetApi from '../../utils/api'
import Dropdown from '../Common/Dropdown'
import formikFieldWrapper from '../Common/formikFieldWrapper'

import type { AutoApproveDisabled } from '../../interfaces'

const FormInput = formikFieldWrapper(Input)

interface Props {
  userId: number
  current: AutoApproveDisabled | null
}

interface FormValues {
  reason?: AutoApproveDisabledReason | null
  disabledAboveValue: number | null
}

const validate = (values: FormValues) => {
  const errors = {} as any
  const { reason, disabledAboveValue } = values

  if (!reason) {
    errors.reason = 'Required'
  }
  if (reason === AutoApproveDisabledReason.CAPPED && !disabledAboveValue) {
    errors.disabledAboveValue = 'Required'
  }
  return errors
}

const AutoApproveDisabledComponent = ({ userId, current }: Props) => {
  // const [showCancelModal, setShowCancelModal] = useState(false);
  const [error, setError] = useState<string>()
  const [isRemoving, setIsRemoving] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const api = GetApi()
  const onSubmit = async (values: FormValues, { setSubmitting, isSubmitting }: any) => {
    if (isSubmitting) {
      return
    }
    setSubmitting(true)
    const { reason, disabledAboveValue } = values
    if (!reason) {
      return
    }
    try {
      setError(undefined)

      const res = await api.addAutoApproveDisabled({
        disabledBecause: reason,
        ...(disabledAboveValue ? { disabledAboveValue } : {}),
        userId,
      })

      if (res.success) {
        navigate(`${pathname}?refresh=true`)
        // setShowCancelModal(false);
      } else {
        setError('Unable to restrict user')
      }
    } catch (error) {
      console.log(error)
      setError('Unable to restrict user')
    } finally {
      setSubmitting(false)
    }
  }

  const initialValues = {
    reason: current?.disabledBecause,
    disabledAboveValue: current?.disabledAboveValue || null,
  }

  return (
    <>
      {/* <Modal isOpen={showCancelModal} onClose={() => setShowCancelModal(false)}> */}
      <Formik onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
        {({
          handleSubmit,
          values,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
          isValid,
          setFieldTouched,
        }) => {
          const removePause = async () => {
            setFieldTouched('reason', false)
            setIsRemoving(true)
            const res = await api.enableAutoApprove({ userId })

            if (res.success) {
              navigate(`${pathname}?refresh=true`)
            } else {
              setError('Unable to unblock user')
            }
            setIsRemoving(false)
            setFieldValue('reason', null)
          }

          return (
            <Flex flexDirection="column">
              {/* <H3 mb={2}>Restrict user account</H3> */}
              <Box>
                <Box mb={3} maxWidth="300px">
                  <Dropdown
                    label="Type of restriction (Required)"
                    error={errors.reason && touched.reason ? errors.reason : undefined}
                    options={[
                      AutoApproveDisabledReason.CAPPED,
                      AutoApproveDisabledReason.NOT_YET_TRUSTED,
                      AutoApproveDisabledReason.UNTRUSTED,
                      AutoApproveDisabledReason.NON_RETURN,
                    ]}
                    handleChange={(event: any) => {
                      setFieldValue('reason', event.target.value)
                      setFieldTouched('reason', true, false)
                    }}
                    value={values.reason ? values.reason : ''}
                  />

                  {values?.reason === AutoApproveDisabledReason.CAPPED ? (
                    <Field
                      name="disabledAboveValue"
                      type="number"
                      required={true}
                      label="Limit Value of Rentals"
                      component={FormInput}
                    />
                  ) : null}
                </Box>

                {error && <Error>{error}</Error>}
              </Box>
              <Box flexDirection="row">
                <Button
                  mr={2}
                  variant="primary"
                  isLoading={isSubmitting}
                  disabled={!isValid || isSubmitting}
                  onClick={handleSubmit}
                  label={
                    values?.reason && values.reason !== current?.disabledBecause
                      ? 'Change restriction'
                      : ' Restrict'
                  }
                />

                {current?.disabledBecause ? (
                  <Button
                    variant="alert"
                    isLoading={isRemoving}
                    disabled={isRemoving}
                    onClick={removePause}
                    alert
                    label="Un Restrict"
                  />
                ) : null}
              </Box>
            </Flex>
          )
        }}
      </Formik>
      {/* </Modal> */}
    </>
  )
}

export default AutoApproveDisabledComponent
