import PropTypes from 'prop-types'
import { pathEq, reduce, merge, keys, mergeAll } from 'ramda'
import React, { Children, cloneElement } from 'react'
import wrapDisplayName from 'recompose/wrapDisplayName'

// returns object with key-value pairs of {KEY: true/false} for all children based on initiallyExpanded prop
const getExpandedChildren = (children) => {
  const isInitiallyExpanded = pathEq(['props', 'initiallyExpanded'], true)
  const childToObj = (child, key) => ({ [child.key || key]: isInitiallyExpanded(child) })
  const mappedChildren = Children.map(children, childToObj)
  return mergeAll(mappedChildren)
}

// HOC
// Exposes bound openItem and closeItem methods in boundHandler object which is passed
// to all children to aid with manipulating which items are expanded.
const withMultipleItems = (BaseComponent) => {
  class WithMultipleItems extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = getExpandedChildren(props.children)
    }

    // preserves original onClick
    toggleItem(key, previousOnClick) {
      const expanded = this.state[key]
      this.setState({ [key]: !expanded }, previousOnClick)
    }

    renderChild(child, key) {
      // attempt to set key to user given key, otherwise fallback to react generated one
      const keyToSet = child.key || key

      const previousOnClick = child.props.onClick
      const expanded = this.state[keyToSet] === true
      const onClick = () => this.toggleItem(keyToSet, previousOnClick)

      // pass through bound methods to children
      const boundHandlers = {
        openItem: this.openItem.bind(this),
        closeItem: this.closeItem.bind(this),
      }
      // pass expanded to instruct children whether they are open
      return cloneElement(child, { ref: keyToSet, expanded, onClick, boundHandlers })
    }

    openItem(key) {
      this.setState({ [key]: true })
    }

    // closes specific key if given. If key is omitted closes any open items
    closeItem(key) {
      if (this.state[key] === true) {
        return this.setState({ [key]: false })
      }
      return this.setState(reduce((acc, key) => merge(acc, { [key]: false }), {})(keys(this.state)))
    }

    openChildSatisfyingPredicate(predicate) {
      const nextChildToOpen = predicate(this.props.children)
      if (nextChildToOpen) {
        this.openItem(nextChildToOpen.key)
      }
    }

    render() {
      const { children, ...rest } = this.props
      return (
        <BaseComponent {...rest}>{Children.map(children, this.renderChild, this)}</BaseComponent>
      )
    }
  }

  WithMultipleItems.propTypes = {
    children: PropTypes.node.isRequired,
  }

  // https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
  WithMultipleItems.displayName = wrapDisplayName(BaseComponent, 'WithMultipleItems')

  return WithMultipleItems
}

export default withMultipleItems
