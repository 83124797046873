import { Country } from '@my/enums'

import canada from './images/flags/ca.png'
import denmark from './images/flags/dk.png'
import finland from './images/flags/fi.png'
import unitedKingdom from './images/flags/gb.png'
import norway from './images/flags/no.png'
import sweden from './images/flags/se.png'
import usa from './images/flags/us.png'

export const flags = {
  [Country.SWEDEN]: sweden,
  [Country.UNITED_STATES]: usa,
  [Country.DENMARK]: denmark,
  [Country.NORWAY]: norway,
  [Country.FINLAND]: finland,
  [Country.CANADA]: canada,
  [Country.UNITED_KINGDOM]: unitedKingdom,
} satisfies Record<Country, unknown>
