import LoginForm from './LoginForm'

const AuthSwitch = ({ children }: any) => {
  const authToken = localStorage.getItem('token')
  if (!authToken) {
    return <LoginForm />
  }
  return children
}

export default AuthSwitch
