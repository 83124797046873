import { H3, Text } from '@fatlama/llama-library'
import styled from 'styled-components'

import { DataRow, SectionCollapsableBlock } from './Components'

import type { OrderVerification } from '../../interfaces'

const RULE_EXPLANATIONS = {
  IS_RISKY_1: 'Less than 3 completed rentals & has done risky searches',
  IS_RISKY_2:
    'Less than 3 completed rentals, has rentals in risky categories & registered in the last 24 hours',
  IS_RISKY_3:
    'Less than 3 completed rentals, has a rental that starts within 24 hours & high risk item (fraud risk 4 or 5) value is greater than 500 or item value is greater than 2000',
} as Partial<Record<string, string>>

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin: 16px 0;
`

const OrderVerificationComponent = ({
  orderVerification,
}: {
  orderVerification?: OrderVerification
}) => {
  if (!orderVerification) {
    return null
  }
  const inputParsed =
    typeof orderVerification.verificationAlgorithmInput === 'string'
      ? JSON.parse(orderVerification.verificationAlgorithmInput)
      : orderVerification.verificationAlgorithmInput

  const outputParsed =
    typeof orderVerification?.verificationAlgorithmOutput === 'string'
      ? JSON.parse(orderVerification?.verificationAlgorithmOutput)
      : orderVerification?.verificationAlgorithmOutput

  return (
    <SectionCollapsableBlock
      Title={<H3>Auto approval check done by the system</H3>}
      initiallyClosed
    >
      <Divider />
      <H3>Data used for the check</H3>
      {Object.keys(inputParsed).map((key, index) => {
        return (
          <DataRow
            key={key}
            rowCount={index + 1}
            reverseSize
            title={key}
            value={inputValueToString(inputParsed[key])}
          />
        )
      })}

      <H3 mt={3}>Check results</H3>
      <Text small>(Rental won&apos;t be auto approved if any of these is true)</Text>
      {Object.keys(outputParsed).map((key, index) => {
        const title = key in RULE_EXPLANATIONS ? `${key} (${RULE_EXPLANATIONS[key]})` : key

        return (
          <DataRow
            key={key}
            rowCount={index + 1}
            reverseSize
            title={title}
            alert={typeof outputParsed[key] === 'boolean' && outputParsed[key] ? true : false}
            value={
              typeof outputParsed[key] === 'object'
                ? outputParsed[key].length
                : outputParsed[key].toString()
            }
          />
        )
      })}
    </SectionCollapsableBlock>
  )
}

function inputValueToString(value: any) {
  if (Array.isArray(value)) {
    return `(${value.length})`
  }

  if (value === null) {
    return 'Not set yet!'
  }

  if (typeof value === 'object') {
    return JSON.stringify(value, null, 2)
  }

  return value.toString()
}

export default OrderVerificationComponent
