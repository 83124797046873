import { getColor, getFontSize, getLineHeight, getSpace, Span } from '@fatlama/llama-library'
import styled from 'styled-components'

// Inspiration: https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  stroke-linecap: round;
`

// The actual checkbox element that controls the view
const HiddenField = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const VisibleCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props: any) => (props.checked ? getColor('whale') : getColor('white'))};
  border: 1px solid ${(props: any) => (props.checked ? getColor('whale') : getColor('gsRat'))};
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;

  &:hover {
    border-color: ${getColor('whale')};
  }

  ${HiddenField}:disabled + & {
    background-color: ${getColor('gsCloud')};
    border-color: ${getColor('gsRat')};
    cursor: not-allowed;
  }

  ${Icon} {
    visibility: ${(props: any) => (props.checked ? 'visible' : 'hidden')};
  }
` as any

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const Checkbox = ({ className, checked, ...props }: any) => (
  <CheckboxContainer className={className}>
    <HiddenField checked={checked} {...props} />
    <VisibleCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 5 12" />
      </Icon>
    </VisibleCheckbox>
  </CheckboxContainer>
)

const getCheckboxWrapper = (hasLabel: boolean) => (hasLabel ? 'label' : 'div')
// Using a label so that all of the content inside can be clicked
// However when custom label used blocks clicking of checkbox
const getContainer = (Element: any) => styled(Element)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LabelContainer = styled(Span)`
  display: inline-block;
  margin-bottom: ${getSpace(1)};
  color: ${getColor('gsGraphite')};
  font-size: ${getFontSize(1)};
  line-height: ${getLineHeight(1)};
`

const Label = ({ label }: any) => {
  if (!label) {
    return null
  }
  return <LabelContainer>{label}</LabelContainer>
}

const CheckboxComponent = ({ label, ...props }: any) => {
  const wrapperElement = getCheckboxWrapper(label)
  const Container = getContainer(wrapperElement)
  return (
    <Container>
      <Label label={label} />
      <Checkbox {...props} />
    </Container>
  )
}

export default CheckboxComponent
