import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import { getColor, getFont, getFontSize } from '../../theme/themeHelpers.js'
import SpinnerIcon from '../Internal/SpinnerIcon'

// Must be careful when using truncate in containers with other elements inside
const getIconFill = (color) => (props) =>
  !props.isLoading &&
  css`
    svg path {
      fill: ${color};
    }
  `

const getButtonWidth = (props) => {
  if (props.fullWidth) {
    return '100%'
  }
  if (props.width) {
    return props.width
  }
  return 'fit-content'
}

const fontColors = {
  primary: 'gsWhite',
  secondary: 'gsCoal',
  alert: 'gsWhite',
  tertiary: 'gsCoal',
}

const CoreButtonElement = styled.button`
  ${space};
  border: none;
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  height: ${(props) => (props.small ? '34px' : '40px')};
  min-height: ${(props) => (props.small ? '34px' : '40px')};
  width: ${(props) => getButtonWidth(props)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  text-align: center;
  transition-property: all;
  transition-duration: 450ms;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0ms;
  font-size: ${getFontSize(2)};
  padding: 0 24px;
  user-select: none;
`

const PrimaryButton = styled(CoreButtonElement)`
  color: ${getColor('white')};
  background-color: ${getColor('primaryCTA')};
  ${getIconFill(getColor('white'))};

  &:hover {
    opacity: 0.9;
  }
  :active {
    transition: none;
    opacity: 0.8;
  }

  :disabled,
  &[disabled] {
    opacity: 0.2;
  }
`

const secondaryButtonColorMap = {
  base: 'gsSlate',
  hover: 'gsCloud',
  active: 'gsRat',
}

const SecondaryButton = styled(CoreButtonElement)`
  color: ${(props) => getColor(fontColors[props.variant])};
  background-color: ${getColor(secondaryButtonColorMap['base'])};
  ${getIconFill(getColor('white'))};
  &:hover {
    background-color: ${getColor(secondaryButtonColorMap['hover'])};
  }
  :active {
    transition: none;
    background-color: ${getColor(secondaryButtonColorMap['active'])};
  }

  :disabled,
  &[disabled] {
    background-color: ${getColor(secondaryButtonColorMap['base'])};
    opacity: 0.2;
  }
`

const AlertButton = styled(CoreButtonElement)`
  color: ${(props) => getColor(fontColors[props.variant])};
  background-color: ${getColor('pomNeon')};
  ${getIconFill(getColor('white'))};
  &:hover {
    opacity: 0.9;
  }
  :active {
    transition: none;
    opacity: 0.8;
  }

  :disabled,
  &[disabled] {
    background-color: ${getColor('pomNeon')};
    opacity: 0.2;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SpinnerWrapper = styled.div`
  position: absolute;
`

const ContentContainer = styled.span`
  visibility: ${(props) => (props.isLoading ? 'hidden' : 'inherit')};
  font-family: ${getFont('body')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const buttonComponents = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  alert: AlertButton,
}

class Button extends React.PureComponent {
  render() {
    const { onClick, children, label, variant } = this.props
    const ButtonComponent = buttonComponents[variant]
    return (
      <ButtonComponent
        {...this.props}
        onClick={(event) => {
          // if (disabled) {
          //   onDisabledClick && onDisabledClick(event)
          //   return null
          // }
          if (typeof onClick !== 'function') {
            return
          }
          onClick(event)
        }}
      >
        <ContentWrapper>
          {this.props.isLoading && (
            <SpinnerWrapper>
              <SpinnerIcon color={['gsRat', 'white']} width={20} height={20} />
            </SpinnerWrapper>
          )}
          <ContentContainer isLoading={this.props.isLoading}>{children || label}</ContentContainer>
        </ContentWrapper>
      </ButtonComponent>
    )
  }
}

Button.propTypes = {
  /** should button be disabled */
  disabled: PropTypes.bool,
  /** function to execute when onClick is fired */
  onClick: PropTypes.func,
  /** color of text in button */
  color: PropTypes.string,
  /** classname for container element */
  containerClassName: PropTypes.string,
  /** classname for button */
  className: PropTypes.string,
  /** button's inner text */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** width value of the Button and Container */
  width: PropTypes.string,
  /** alternative small (34px height) Button and Container */
  small: PropTypes.bool,
  /** Alternative for full width button */
  fullWidth: PropTypes.bool,
  /** Determines color */
  variant: PropTypes.oneOf(['primary', 'secondary', 'alert']),
  /** Icon to sit to the left of label - accepts icon name */
  icon: PropTypes.string,
  /** controls whether the button shows a loading spinner */
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  variant: 'primary',
}

export default Button
