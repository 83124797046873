type PhoneCallType = {
  value: string
  label: string
  isRenter: boolean
}

export const phoneCallTypes: PhoneCallType[] = [
  {
    value: 'lender-phone-call-answered',
    label: 'Phone Call Answered',
    isRenter: false,
  },
  {
    value: 'borrower-phone-call-answered',
    label: 'Phone Call Answered',
    isRenter: true,
  },
  {
    value: 'lender-phone-call-no-answered',
    label: 'Phone Call Not Answered',
    isRenter: false,
  },
  {
    value: 'borrower-phone-call-no-answered',
    label: 'Phone Call Not Answered',
    isRenter: true,
  },
  {
    value: 'lender-phone-voicemail',
    label: 'Phone Voicemail',
    isRenter: false,
  },
  {
    value: 'borrower-phone-voicemail',
    label: 'Phone Voicemail',
    isRenter: true,
  },
  { value: 'lender-inbound', label: 'Inbound Call', isRenter: false },
  { value: 'borrower-inbound', label: 'Inbound Call', isRenter: true },
]

export const templates = [
  {
    name: 'CUSTOM',
    subject: '',
    email: '',
    text: '',
  },
  // CALL BACK
  {
    name: 'Call Back',
    subject: 'Verification call request',
    email: `Hi,

In order to get you renting, we need a bit more information.

Please do let us know when you're free to connect by messaging us on verify@fatllama.com so we can give you a call. 

Thanks,

The Fat Llama Team`,
    text: `Hi,

In order to get you renting, we need a bit more information. Please do let us know when you're free to connect by messaging us on verify@fatllama.com so we can give you a call. 

Thanks,

The Fat Llama Team`,
  },

  // Account not verified
  //   {
  //     name: 'Account not verified',
  //     subject: 'Account not verified',
  //     email: `Hi,

  // We can't pass your request to the lender until you've verified. Tap the link below to do so:

  // https://fatllama.com/verification

  // The Fat Llama Team`,
  //     text: `Hi,

  // We can't pass your request to the lender until you've verified. Tap the link below to do so:

  // https://fatllama.com/verification

  // The Fat Llama Team`
  //   },

  // No phone number
  {
    name: 'No phone number',
    subject: 'Fat Llama Verification',
    email: `Hi,

We are yet to receive your phone number. Please update your Fat Llama profile so we can continue with your verification. 

Many thanks, 

Fat Llama`,
    text: `Hi,

We are yet to receive your phone number. Please update your Fat Llama profile so we can continue with your verification. 

Many thanks, 

Fat Llama`,
  },

  // Not valid proof of income
  {
    name: 'Not valid proof of income',
    subject: 'Fat Llama Verification',
    email: `Hi,

The proof of employment that you have provided unfortunately does not meet our requirements, and we will not be able to accept it as a valid proof of income.

Please kindly resubmit a payslip or invoice paid to you dated within the past 3 months so that we can process your request.

Many thanks, 

Fat Llama 
`,
    text: `Hi,

The proof of employment that you have provided unfortunately does not meet our requirements, and we will not be able to accept it as a valid proof of income.

Please kindly resubmit a payslip or invoice paid to you dated within the past 3 months so that we can process your request.
`,
  },

  // Not valid proof of address
  {
    name: 'Not valid proof of address',
    subject: 'Fat Llama Verification',
    email: `Hi,

The proof of address that you have provided unfortunately does not meet our requirements, and we will not be able to accept it as a valid proof of address.

Please kindly resubmit a bank statement, HMRC letter or a utility, WiFi, or phone bill dated within the past 3 months, so that we can process your request. We also accept online documents. 

Many thanks,

Fat Llama
`,
    text: `Hi,

The proof of address that you have provided unfortunately does not meet our requirements, and we will not be able to accept it as a valid proof of address.

Please kindly resubmit a bank statement, HRMC letter or a utility bill dated within the past 3 months, so that we can process your request.

 
`,
  },

  // Request social links - wrong name
  {
    name: 'Request social links - wrong name',
    subject: 'Fat Llama Verification',
    email: `Hi,

Thank you for providing us with the requested documents. 

Unfortunately, we're yet not able to verify your request, as the social account you have provided us with is not associated with your name or likeness. We need to be able to verify your online presence, so please kindly provide us with one of the below:  

- Personal LinkedIn account
- Personal Facebook account
- Personal Instagram account

Kind Regards, 
Fat Llama
`,
    text: `We're yet not able to verify your request, as the [Facebook] account you have provided us with is not associated with your name. We need to be able to verify your online presence, so please kindly provide us with [Document]. Fat Llama`,
  },

  // Request social links - additional
  {
    name: 'Request social links - additional',
    subject: 'Fat Llama Verification',
    email: `Hi,

We’re not able to approve your social media link as the profile you have sent is not sufficient.
Please kindly provide us with another social media link (such as Facebook, Instagram or LinkedIn) so we can verify you. 
    
Fat Llama
`,
    text: `Hi,

    We’re not able to approve your social media link as the profile you have sent is not sufficient.
    Please kindly provide us with another social media link (such as Facebook, Instagram or LinkedIn) so we can verify you. 
    
    Fat Llama`,
  },

  // Request social links - link not working
  {
    name: 'Request social links - follow request',
    subject: 'Fat Llama Verification',
    email: `Hi,

We’re not able to approve your social media link as the profile you have sent is set to [private/has insufficient connections/has not been updated within the past 6 months]. Please kindly provide us with another social media link (such as Facebook,Instagram or LinkedIn) or alternatively, accept our follow request so we can verify you. 

Fat Llama`,
    text: `Hi,

    We’re not able to approve your social media link as the profile you have sent is set to [private/has insufficient connections/has not been updated within the past 6 months]. Please kindly provide us with another social media link (such as Facebook,Instagram or LinkedIn) or alternatively, accept our follow request so we can verify you. 
    
    Fat Llama`,
  },

  // Request for insurance
  {
    name: 'Request for insurance',
    subject: 'Fat Llama Verification',
    email: `Hello,

Thank you for uploading the relevant documents.

Given the high value of the kit that you have requested, we will not be able to approve your rental without proof that you have your own insurance cover for any damage, loss or theft.

If you don’t have a policy already, there are many places which allow you to purchase hire equipment cover for short periods of time. Here is a company that we recommend:  https://www.performance-insurance.com/short-period-insurance

Please ensure that your insurance meets the following criteria:

It has your full name as the Policyholder

The dates on the policy match exactly the dates of the rental on the Fat Llama platform

The Policy covers the value of the items

The Policy cover damage and theft of hired equipment

As soon as you have cover, please submit all of the documentation via the Rental Insurance Submission Form: https://airtable.com/shr9kNuQJJFFasqwh

We will review the Policy promptly, and then we can then get your request sent to the lender straight away.

Kind Regards, 

Fat Llama 
`,

    text: `Hello,

Thank you for uploading the relevant documents.

Given the high value of the kit that you have requested, we will not be able to approve your rental without proof that you have your own insurance cover for any damage, loss or theft.

If you don’t have a policy already, there are many places which allow you to purchase hire equipment cover for short periods of time. Here is a company that we recommend:  https://www.performance-insurance.com/short-period-insurance

Please ensure that your insurance meets the following criteria:

It has your full name as the Policyholder

The dates on the policy match exactly the dates of the rental on the Fat Llama platform

The Policy covers the value of the items

The Policy cover damage and theft of hired equipment

As soon as you have cover, please submit all of the documentation via the Rental Insurance Submission Form: https://airtable.com/shr9kNuQJJFFasqwh

We will review the Policy promptly, and then we can then get your request sent to the lender straight away.`,
  },

  // Stripe Surname does not match
  {
    name: 'Stripe Surname does not match',
    subject: 'Fat Llama Verification',
    email: `Hi,

Thank you for your request on the platform. 

Unfortunately, the surname on the payment card you have used does not match the surname on your identity documents and therefore we're not able to approve your rental. 

Please try again by making sure that you use your own credit/ debit card. 

Many thanks, 

Fat Llama`,
    text: `Hi,

Thank you for your request on the platform. 

Unfortunately, the surname on the payment card you have used does not match the surname on your identity documents and therefore we're not able to approve your rental. 

Please try again by making sure that you use your own credit/ debit card. 

Many thanks, 

Fat Llama`,
  },

  // Work - Uni Email
  {
    name: 'Work - Uni Email',
    subject: 'Fat Llama Verification',
    email: `Hi,

In order to get you renting, we need a bit more information.

Using a Work or University email address, please send an email to verify@fatllama.com titled ‘Verify me'.

In the message, please reference the email address associated with your Fat Llama account.

Many thanks, 

Fat Llama`,
    text: `Hi,

In order to get you renting, we need a bit more information.

Using a Work or University email address, please send an email to verify@fatllama.com titled ‘Verify me'.

In the message, please reference the email address associated with your Fat Llama account.

Many thanks, 

Fat Llama`,
  },

  // Verification rejection
  {
    name: 'Rental rejection',
    subject: 'Fat Llama Verification',
    email: `Hi,

We regret to inform you that on this occasion, your rental request couldn't be verified, as your verification score did not meet the required threshold for this item.

As your rental history with us grows so will your ability to rent higher value equipment as part of our Trust Policy. We recommend X as a possible alternative for you. 

Your rental has been cancelled and issued a full refund, however if you would like some help or advice on renting with Fat Llama please email us at verify@fatllama.com.

Kind Regards, 

Fat Llama
`,
    text: `Hi,

We regret to inform you that on this occasion, your rental request couldn't be verified, as your verification score did not meet the required threshold for this item.

Your rental has been cancelled, however if you would like some help or advice on renting with Fat Llama please email us at verify@fatllama.com.

Kind Regards, 

Fat Llama`,
  },

  // Cancellation - refund request
  {
    name: 'Cancellation - refund request',
    subject: 'Fat Llama Refund Request',
    email: `Hi, 

**BORROWER**, the borrower who requested to rent your [ITEM], would like to cancel the rental.

Because this is outside the Cancellation Period, you can choose whether or not you'd like to offer a refund.

Please reply to this message to let us know whether or not you're happy to give the borrower a refund.

Thanks,
 
Fat Llama`,
    text: `Hi, 

**BORROWER**, the borrower who requested to rent your [ITEM], would like to cancel the rental.

Because this is outside the Cancellation Period, you can choose whether or not you'd like to offer a refund.

Please reply to this message to let us know whether or not you're happy to give the borrower a refund.

Thanks,
  
Fat Llama`,
  },

  // Outstanding Request Lender
  {
    name: 'Outstanding Request Lender',
    subject: 'Fat Llama Outstanding Request',
    email: `Hi, 

You have an outstanding rental request from **BORROWER** on your,

https://fatllama.com/my-rentals

Please tap above to accept or decline.

Thanks,

Fat Llama`,
    text: `Hi, 

You have an outstanding rental request from **BORROWER** on your,

https://fatllama.com/my-rentals

Please tap above to accept or decline.

Thanks,

Fat Llama`,
  },
  // Outstanding Request Lender
  {
    name: 'Account Rejection',
    subject: 'Fat Llama Verification',
    email: `Hi there,

We regret to inform you that your account couldn't be verified, as your verification score did not meet the required threshold.

Our algorithms factor in multiple data points to determine a user's ability to rent on Fat Llama. On this occasion you did not satisfy one or several of the factors that include:

Verification criteria
Identity documentation
Payment provider risk score
Details associated with another account

Your rental has been cancelled, and your account removed from the platform. You will not be able to make further rentals on the platform.

If you have any questions, please let us know at feedback@fatllama.com.

Kind Regards, 

Fat Llama
`,
    text: `Hi there,

We regret to inform you that your account couldn't be verified, as your verification score did not meet the required threshold.

Our algorithms factor in multiple data points to determine a user's ability to rent on Fat Llama. On this occasion you did not satisfy one or several of the factors that include:

Verification criteria
Identity documentation
Payment provider risk score
Details associated with another account

Your rental has been cancelled, and your account removed from the platform. You will not be able to make further rentals on the platform.

If you have any questions, please let us know at feedback@fatllama.com.

Kind Regards, 

Fat Llama
`,
  },

  //   // Alternatives
  //   {
  //     name: 'Alternatives',
  //     subject: 'Fat Llama Alternatives',
  //     email: `Hi,

  // We're sorry your rental request didn’t work out. We've found you an alternative which we hope will fit the bill:

  //         **** ADD LINK HERE *****

  // Want us to book it on your behalf for the same dates? If so, simply get in touch with us on Live Chat at fatllama.com

  // Fat Llama`,
  //     text: `Hi,

  // We're sorry your rental request didn’t work out. We've found you an alternative which we hope will fit the bill:

  //         **** ADD LINK HERE *****

  // Want us to book it on your behalf for the same dates? If so, simply get in touch with us on Live Chat at fatllama.com

  // Fat Llama`
  //   },
  // old lender coming back
  {
    name: 'Fat Llama: Former borrower returning to platform',
    subject: 'Fat Llama Verification',
    email: `Hi, 

We are grateful here at Fat Llama for your longevity on the site. As we have updated our verification procedure, please kindly submit the following to be verified:

A new proof of address dated in the last 3 months
A new proof of employment dated in the last 3 months
A Video selfie

Thanks, 

Fat Llama

    
    `,
    text: `Hi, 

We are grateful here at Fat Llama for your longevity on the site. As we have updated our verification procedure, please kindly submit the following to be verified:

A new proof of address dated in the last 3 months
A new proof of employment dated in the last 3 months
A Video selfie (where you read out the 4 digit code displayed on the screen)

Thanks, 

Fat Llama

`,
  },
  // return ya items

  {
    name: 'Please Return Your Item',
    subject: 'Your rental has ended and lender has reported the item has not been returned yet',
    email: `Hi, 

The Lender has reported that you have still not returned your items for the rental. Please bring back the items to the Lender within 24 hours. Failure to do so will refult in further action against you. 

Thanks,

Fat Llama
`,
    text: `Hi, 

The Lender has reported that you have still not returned your items for the rental. Please bring back the items to the Lender within 24 hours. Failure to do so will refult in further action against you. 

Thanks,

Fat Llama
`,
  },

  {
    name: 'Online Presence - Linkedin',
    subject: 'Fat Llama Verification',
    email: `Hi there, 

We're yet not able to verify your request, as the Linkedin account you have provided us with is not associated with your likeness.

We need to be able to verify your online presence, so please change your profile image or privacy settings temporarily, or kindly provide us with an active Instagram or Facebook account.

Thanks,
Fat Llama
`,
    text: `Hi there, 

We're yet not able to verify your request, as the Linkedin account you have provided us with is not associated with your likeness.

We need to be able to verify your online presence, so please change your profile image or privacy settings temporarily, or kindly provide us with an active Instagram or Facebook account.

Thanks,
Fat Llama
`,
  },

  {
    name: 'Online Presence - Facebook',
    subject: 'Fat Llama Verification',
    email: `Hi there, 

We're yet not able to verify your request, as the Facebook account you have provided us with is private or inactive.

We need to be able to verify your online presence, so please kindly change your privacy settings temporarily, or provide us with an active Instagram or Linkedin account.

Thanks,
Fat Llama
`,
    text: `Hi there, 

We're yet not able to verify your request, as the Facebook account you have provided us with is private or inactive.

We need to be able to verify your online presence, so please kindly change your privacy settings temporarily, or provide us with an active Instagram or Linkedin account.

Thanks,
Fat Llama
`,
  },

  {
    name: 'Online Presence - Instagram',
    subject: 'Fat Llama Verification',
    email: `Hi there, 

We're yet not able to verify your request, as the Instagram account you have provided us with is private or inactive.

We need to be able to verify your online presence, so please accept our ‘follow’ request, change your privacy settings temporarily, or kindly provide us with an active Facebook or Linkedin account.

Thanks,
Fat Llama
`,
    text: `Hi there, 

We're yet not able to verify your request, as the Instagram account you have provided us with is private or inactive.

We need to be able to verify your online presence, so please accept our ‘follow’ request, change your privacy settings temporarily, or kindly provide us with an active Facebook or Linkedin account.

Thanks,
Fat Llama`,
  },

  {
    name: 'Duplicate Booking',
    subject: `You've made a duplicate booking`,
    email: `Hi there, 

We've noticed that you have a duplicate rental booking for the **ITEM** with the same lender and the same dates. Could you please contact us at info@fatllama.com to let us know if you would like both of these rentals to be processed, or for one of them to be cancelled and issued a full refund?

We won't proceed without your confirmation.

Thanks,
Fat Llama
    
`,
    text: `Hi there,

We've noticed that you have a duplicate rental booking for the **ITEM** with the same lender and the same dates. Could you please contact us at info@fatllama.com to let us know if you would like both of these rentals to be processed, or for one of them to be cancelled and issued a full refund?

We won't proceed without your confirmation.

Thanks,
Fat Llama`,
  },

  {
    name: 'Rental Rejection- Duplicate account',
    subject: `Rental rejected - duplicate account detected`,
    email: `Hi,

We regret to inform you that on this occasion, your rental request couldn't be verified, as your verification score did not meet the required threshold for this item. This is because you have a duplicate account attached to X. Please only continue to make rentals from this account.

Your rental has been cancelled and issued a full refund, if you would like a hand updating any account details please contact us at info@fatllama.com.

Kind Regards,
Fat Llama    
`,
    text: `Hi,

We regret to inform you that on this occasion, your rental request couldn't be verified, as your verification score did not meet the required threshold for this item. This is because you have a duplicate account attached to X. Please only continue to make rentals from this account.

Your rental has been cancelled and issued a full refund, if you would like a hand updating any account details please contact us at info@fatllama.com.

Kind Regards,
Fat Llama 
`,
  },

  {
    name: 'Rental Rejection- Name Mismatch',
    subject: `Rental rejected - shipping and payment details do not match`,
    email: `Hi,

We regret to inform you that on this occasion, your rental request couldn't be verified, as your verification score did not meet the required threshold for this item. This is because there's a name mismatch between your payment card/ shipping address, and your ID and documents provided. We need all details to be consistent to one person in order to approve a rental.

Your rental has been cancelled and issued a full refund, please contact us at info@fatllama.com if you would like a helping hand in updating your account details.

Kind Regards,
Fat Llama
    
`,
    text: `Hi,

We regret to inform you that on this occasion, your rental request couldn't be verified, as your verification score did not meet the required threshold for this item. This is because there's a name mismatch between your payment card/ shipping address, and your ID and documents provided. We need all details to be consistent to one person in order to approve a rental.

Your rental has been cancelled and issued a full refund, please contact us at info@fatllama.com if you would like a helping hand in updating your account details.

Kind Regards,
Fat Llama  
`,
  },

  //   {
  //     name: 'Name Mismatch - opportunity to change',
  //     subject: `Unable to process request`,
  //     email: `Hi,

  // Thank you for your request on the platform.

  // Unfortunately, the name on the payment card you have used does not match the name on your identity documents and therefore we're not able to approve your rental.

  // Please try again by making sure that you use your own credit/ debit card.

  // Many thanks,

  // Fat Llama
  // `,
  //     text: `Hi,
  // Thank you for your request on the platform.

  // Unfortunately, the name on the payment card you have used does not match the name on your identity documents and therefore we're not able to approve your rental.

  // Please try again by making sure that you use your own credit/ debit card.

  // Many thanks,

  // Fat Llama
  // `
  //   },

  {
    name: 'Rental Rejection self referral ',
    subject: `Rental rejected - self referral`,
    email: `Hi,

We have cancelled your rental and refunded you. Please request the rental via the original email you have signed up with:rachaelnaome@gmail.com

Many thanks,
Fat Llama
`,
    text: `Hi,

We have cancelled your rental and refunded you. Please request the rental via the original email you have signed up with:rachaelnaome@gmail.com

Many thanks,
Fat Llama
`,
  },

  {
    name: 'Address Not From UK',
    subject: `Address validation`,
    email: `Hi there,

We can see that you are not a permanent resident of the UK. Please can you send to verify@fatllama.com:
- A proof of address for your permanent address. For example, a bank statement, government letter or utility bill.
- Confirmation of your flight details.
- Confirmation of where you will be staying whilst in the UK.

Thanks
Fat Llama 

`,
    text: `Hi there

We can see that you are not a permanent resident of the UK. Please can you send to verify@fatllama.com:
- A proof of address for your permanent address. For example, a bank statement, government letter or utility bill.
- Confirmation of your flight details.
- Confirmation of where you will be staying whilst in the UK.

Thanks
Fat Llama 

`,
  },

  {
    name: 'Three Docs Rejected',
    subject: `We're here to help with your verification`,
    email: `Hey there,

It looks like you’re having trouble getting this rental request verified. Please connect with us at verify@fatllama.com so we can help you.

Thanks,
Fat Llama
`,
    text: `Hey there,

It looks like you’re having trouble getting this rental request verified. Please connect with us at verify@fatllama.com so we can help you.

Thanks,
Fat Llama
`,
  },

  //   {
  //     name: 'Pending Debt ',
  //     subject: `We're here to help with your verification`,
  //     email: `Hey there,

  // It looks like you’re having trouble getting this rental request verified. Please connect with us at verify@fatllama.com so we can help you.

  // Thanks,
  // Fat Llama
  // `,
  //     text: `Hey there,

  // It looks like you’re having trouble getting this rental request verified. Please connect with us at verify@fatllama.com so we can help you.

  // Thanks,
  // Fat Llama
  // `
  //   },

  {
    name: 'Capped Rental Value',
    subject: `Rental verification level`,
    email: `Hi,
    
Based on the documents you have provided, our verification team are able to approve you for a rental value up to [X amount] By renting a lower value item as an alternative, this will enable your rental history to grow. 

As this rental history becomes more extensive, so will your ability to rent higher value equipment as part of our Trust Policy. One way you can rent at a lower value is to look for an older version of the equipment you are looking for, or to rent less (eg less lenses, lights) and only rent what you absolutely need). Your rental has been cancelled and issued a full refund, this will be back with you shortly.
`,
    text: `**USER**, Based on the documents you have provided, our verification team are able to approve you for a rental value up to [X amount] By renting a lower value item as an alternative, this will enable your rental history to grow. 

As this rental history becomes more extensive, so will your ability to rent higher value equipment as part of our Trust Policy. One way you can rent at a lower value is to look for an older version of the equipment you are looking for, or to rent less (eg less lenses, lights) and only rent what you absolutely need). Your rental has been cancelled and issued a full refund, this will be back with you shortly.
`,
  },
  //   {
  //     name: 'Holding Verification Message',
  //     subject: 'Fat Llama Verification',
  //     email: `Hi,

  // Your account verification will now continue tomorrow as we are closed for the evening. As we are based in the UK, this may take place at an unexpected time and we thank you for your patience with us.

  // Fat Llama`,
  //     text: `Hi,

  //     Your account verification will now continue tomorrow as we are closed for the evening. As we are based in the UK, this may take place at an unexpected time and we thank you for your patience with us. Fat Llama`
  //   },

  // Outstanding Request Lender
  //   {
  //     name: 'Credit Kudos',
  //     subject: 'Fat Llama Verification',
  //     email: `Hi,

  // If you do not have a proof of employment readily available at this time, you can also get verified by going through Credit Kudos.

  // Credit Kudos allows you to securely share a snapshot of your account history via Open Banking. This will not affect your credit score, and will only be used to verify you on the Fat Llama platform. You can learn more about Credit Kudos by visiting https://www.creditkudos.com/individuals.

  // To proceed through the Credit Kudos registration process click on this link:

  // Here you will be transferred to your online banking and give authorisation for Credit Kudos to share a snapshot of your account history with Fat Llama.

  // Let us know if you have any questions.`,
  //     text: `Hi,

  // If you do not have a proof of employment readily available at this time, you can also get verified by going through Credit Kudos.

  // Credit Kudos allows you to securely share a snapshot of your account history via Open Banking. This will not affect your credit score, and will only be used to verify you on the Fat Llama platform. You can learn more about Credit Kudos by visiting https://www.creditkudos.com/individuals.

  // To proceed through the Credit Kudos registration process click on this link:

  // Here you will be transferred to your online banking and give authorisation for Credit Kudos to share a snapshot of your account history with Fat Llama.

  // Let us know if you have any questions.`
  //   },

  // Requesting Call Sheet
  {
    name: 'Requesting Conf Docs',
    subject: 'Fat Llama Call Verification',
    email: `Hi,
Thank you for the call just now. As discussed, please can you send over one of the following to verify@fatllama.com:

- Call sheet 
- Treatment
- Project brief 
- Location booking 
- Shot schedule 
- Invoice for shoot 
- Storyboard
- Booking confirmation via email/invoice/message conversation
    
Please make sure that the date of the shoot is visible on the document provided 

Thanks,

Fat Llama`,
    text: `Hi,
Thank you for the call just now. As discussed, please can you send over one of the following to verify@fatllama.com:

- Call sheet 
- Treatment
- Project brief 
- Location booking 
- Shot schedule 
- Invoice for shoot 
- Storyboard
- Booking confirmation via email/invoice/message conversation
    
Please make sure that the date of the shoot is visible on the document provided `,
  },
  // Add to Instagram Bio
  {
    name: 'Add to Instagram Bio',
    subject: 'Fat Llama Instagram Verification',
    email: `Hi, 

As your Instagram account can’t be connected to your likeness, we need to confirm the account belongs to you. If you can temporarily put your full name in the bio on your account and reupload your Instagram link, we will be able to review your online presence.

Thanks,

Fat Llama`,
    text: `Hi, 

As your Instagram account can’t be connected to your likeness, we need to confirm the account belongs to you. If you can temporarily put your full name in the bio on your account and reupload your Instagram link, we will be able to review your online presence.

Thanks,

Fat Llama`,
  },

  {
    name: 'Showing Debt',
    subject: 'Fat Llama Verification',
    email: `Hi, 

Unfortunately, we cannot accept letters which show debt owed on Fat Llama. 

Please email us at verify@fatllama.com to discuss with us and show you have paid off this debt.

Kind regards,

Fat Llama`,
    text: `Hi, 

Unfortunately, we cannot accept letters which show debt owed on Fat Llama. 

Please email us at verify@fatllama.com to discuss with us and show you have paid off this debt.

Kind regards,

Fat Llama`,
  },

  {
    name: 'Fat Llama Blurry ID ',
    subject: 'Fat Llama: Document upload blurry',
    email: `Hi,

Unfortunately we couldn't verify your selfie against your ID.
Please can you reupload an image of your ID so that we can clearly see:

- All details
- The expiry date
- In a room that is well lit.

Many Thanks,
Fat Llama
           
`,
    text: `Hi,
Unfortunately we couldn't verify your selfie against your ID.
Please can you reupload an image of your ID so that we can clearly see:

- All details
- The expiry date
- In a room that is well lit.

Many Thanks,
Fat Llama
 `,
  },

  {
    name: 'Fat Llama Drone',
    subject: 'Fat Llama Drone rental additional verification',
    email: `Hi,

As you have requested to rent a professional level item, could you please send one of the following to us at verify@fatllama.com?

- Drone footage/portfolio connected to your likeness, or credited to your name
- Your drone license, if you own one

Please contact the same email address if these are not possible to provide, and our customer services will do their best to help you.

Thanks!
Fat Llama

           
`,
    text: `Hi,

As you have requested to rent a professional level item, could you please send one of the following to us at verify@fatllama.com?

- Drone footage/portfolio connected to your likeness, or credited to your name
- Your drone license, if you own one

Please contact the same email address if these are not possible to provide, and our customer services will do their best to help you.

Thanks!
Fat Llama

 `,
  },

  {
    name: 'Fat Llama Student',
    subject: 'Fat Llama: Student',
    email: `Hi,

As you're a student we will need some extra information for insurance purposes. Could you please use your student email address to send your student ID card (with a visible expiry date) to our team?

We will also require the following:

- Valid social media
- Evidence of the project or planning/booking of the shoot FROM your UNI email address

This is a standard procedure for high value (RRP) bookings like yours.

Many thanks, 

Fat Llama           
`,
    text: `Hi,

As you're a student we will need some extra information for insurance purposes. Could you please use your student email address to send your student ID card (with a visible expiry date) to our team?

We will also require the following:

- Valid social media
- Evidence of the project or planning/booking of the shoot FROM your UNI email address

This is a standard procedure for high value (RRP) bookings like yours.

Many thanks, 

Fat Llama 
 `,
  },

  {
    name: 'Requesting DJ Doc',
    subject: 'Fat Llama: DJ experience, requesting document',
    email: `Hi,

Thank you for the call just now. As discussed, please can you send over one of the following to verify@fatllama.com:

- Your name/ DJ name on an upcoming event
- A link to some music production connected to your name/ likeness
- Photos/Videos of past events using DJ equipment
- Birthday/Wedding invitation
 
Please contact the same email address if these are not possible to provide, and our customer services will do their best to help you.

Thanks!

Fat Llama     
`,
    text: `Hi,

Thank you for the call just now. As discussed, please can you send over one of the following to verify@fatllama.com:

- Your name/ DJ name on an upcoming event
- A link to some music production connected to your name/ likeness
- Photos/Videos of past events using DJ equipment
- Birthday/Wedding invitation
 
Please contact the same email address if these are not possible to provide, and our customer services will do their best to help you.

Thanks!

Fat Llama
 `,
  },

  {
    name: 'Fat Llama ID expired',
    subject: 'Fat Llama: Identification expired',
    email: `Hi,

It looks like your ID has expired! Fat Llama only accepts Passports. Driver's Licenses and National ID cards as Valid forms of ID.

Please upload an image of one of the following:
 - Passport
 - Driver's License
 - National ID Card
so we can verify you.

Thanks,
Fat Llama  
`,
    text: `Hi,

It looks like your ID has expired! Fat Llama only accepts Passports. Driver's Licenses and National ID cards as Valid forms of ID.

Please upload an image of one of the following:
 - Passport
 - Driver's License
 - National ID Card
so we can verify you.

Thanks,
Fat Llama
 `,
  },
]
