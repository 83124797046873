export const getBaseurl = (): string | null => {
  return `${import.meta.env.VITE_APP_API_URL || 'http://localhost:5000'}/api/admin`
}

export const getAdminUrl = (path?: string): string | null => {
  const currentBaseUrl = getBaseurl()
  if (currentBaseUrl?.includes('staging')) {
    return 'https://admin-staging.hygglo.se' + path
  } else {
    return 'https://admin.hygglo.se' + path
  }
}
