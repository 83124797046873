import { Flex, H2, getSpace, getColor, Text, Input, SpinnerIcon } from '@fatlama/llama-library'
import { flags } from '@my/assets/flags'
import { Country } from '@my/enums'
import styled from 'styled-components'

import Dropdown from '../Common/DropdownPopover'
import { NAV_WIDTH } from '../NavSideBar/Index'

import DateFilter from './DateFilter'
import { Tab, SortableField, SortOrder, VerificationStatusFilter, CountryFilter } from './types'

const HeaderContainer = styled(Flex)`
  flex-direction: column;
  z-index: 2;
  background-color: white;
  padding: ${getSpace(3)};
  padding-bottom: 0;
  width: calc(100% - ${NAV_WIDTH}px);
  position: fixed;
  border-bottom: 1px solid ${getColor('gsRat')};
`

const ClearText = styled(Text)`
  cursor: pointer;
  white-space: nowrap;
  margin-left: ${getSpace(2)};
  color: ${getColor('whale')};
  margin-bottom: 0;
`

const TabContainer = styled(Flex)`
  border-bottom: 1px solid
    ${(props) => (props.isActive ? getColor('marineMute')(props) : 'transparent')};
  align-items: center;
  margin-bottom: -1px;
  justify-content: center;
  height: 56px;
  cursor: pointer;
  margin-right: ${getSpace(3)};
  padding-top: ${getSpace(2)};
  padding-right: ${getSpace(2)};
  padding-bottom: ${getSpace(2)};

  p {
    white-space: nowrap;
  }
`

const SearchInput = styled(Input)`
  border-radius: 40px;
  input::placeholder {
    font-weight: 300;
  }
`

const FlagIcon = styled.img`
  height: 36px;
  width: 36px;

  margin-right: 16px;
  margin-left: 16px;
  object-fit: cover;
  border-radius: 36px;
`

const countryFilterMap = {
  [CountryFilter.ALL]: <FlagIcon src="/images/world-icon.png" />,
  [CountryFilter.UK]: <FlagIcon src={flags[Country.UNITED_KINGDOM]} />,
  [CountryFilter.NA]: <FlagIcon src="/images/north-america.svg" />,
  [CountryFilter.NORDICS]: <FlagIcon src="/images/nordics-icon.png" />,
}

export const TabButton = ({
  title,
  onClick,
  isActive,
}: {
  title: string
  isActive: boolean
  onClick(t?: any): any
}) => {
  return (
    <TabContainer onClick={onClick} isActive={isActive}>
      <Text color={isActive ? 'marineMute' : 'copyOne'} mb={0}>
        {title}
      </Text>
    </TabContainer>
  )
}

const Header = ({
  activeTab,
  countryFilter,
  setCountryFilter,
  setActiveTab,
  setSearchTerm,
  searchTerm,
  isLoading,
  clearFilters,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  activeFilter,
  id,
  setDateFilter,
  dateFilter,
  customerId,
}: {
  activeTab: Tab
  id?: string
  searchTerm: string
  countryFilter: CountryFilter
  setSearchTerm: any
  isLoading: boolean
  clearFilters: any
  activeFilter: VerificationStatusFilter
  sortBy: SortableField
  sortOrder: SortOrder
  dateFilter: any
  setDateFilter: any
  customerId?: number
  setActiveTab(t: Tab): void
  setCountryFilter(x: CountryFilter): void
  setSortBy(x: SortableField): void
  setSortOrder(x: SortOrder): void
}) => {
  const onSearch = (e: any) => {
    const newValue = e.target.value
    setSearchTerm(newValue)
  }

  const sortByText =
    sortBy === SortableField.TX_CREATED_AT
      ? 'Created at'
      : sortBy === SortableField.TX_FROM_DATE
        ? 'From date'
        : 'Sec. review'

  const sortOrderText = sortOrder === SortOrder.ASCENDING ? 'Asc' : 'Desc'
  return (
    <HeaderContainer id={id}>
      {customerId ? <H2>Rentals - customer: {customerId}</H2> : <H2>Rentals </H2>}
      <Flex>
        <Flex width="auto" pr={3}>
          <TabButton
            isActive={activeTab === Tab.ACTIVE}
            title="Active"
            onClick={() => {
              setActiveTab(Tab.ACTIVE)
              setSortBy(SortableField.TX_FROM_DATE)
              setSortOrder(SortOrder.ASCENDING)
            }}
          />
          <TabButton
            isActive={activeTab === Tab.PAST}
            title="Past rentals"
            onClick={() => {
              setSortOrder(SortOrder.DESCENDING)
              setSortBy(SortableField.TX_CREATED_AT)
              setActiveTab(Tab.PAST)
            }}
          />

          <TabButton
            isActive={activeTab === Tab.ALL}
            title="All"
            onClick={() => {
              setSortOrder(SortOrder.DESCENDING)
              setSortBy(SortableField.TX_CREATED_AT)
              setActiveTab(Tab.ALL)
            }}
          />
        </Flex>
        <Flex alignItems="center">
          {!customerId ? (
            <SearchInput
              value={searchTerm}
              placeholder="Search by user email, telephone or ID..."
              onChange={onSearch}
            />
          ) : (
            <Flex />
          )}
          {!customerId ? (
            <Dropdown ButtonOverride={countryFilterMap[countryFilter]} width="135px" mr={3} ml={3}>
              <Text
                mb={0}
                onClick={() => {
                  setCountryFilter(CountryFilter.ALL)
                }}
              >
                All
              </Text>
              <Text
                mb={0}
                onClick={() => {
                  setCountryFilter(CountryFilter.UK)
                }}
              >
                UK
              </Text>

              <Text
                mb={0}
                onClick={() => {
                  setCountryFilter(CountryFilter.NORDICS)
                }}
              >
                Nordics
              </Text>

              <Text
                mb={0}
                onClick={() => {
                  setCountryFilter(CountryFilter.NA)
                }}
              >
                North America
              </Text>
            </Dropdown>
          ) : null}

          <Dropdown title={sortByText} width="135px" mr={3}>
            <Text
              mb={0}
              onClick={() => {
                setSortBy(SortableField.TX_FROM_DATE)
                setSortOrder(SortOrder.DESCENDING)
              }}
            >
              From date
            </Text>
            <Text
              mb={0}
              onClick={() => {
                setSortBy(SortableField.TX_CREATED_AT)
                setSortOrder(SortOrder.DESCENDING)
              }}
            >
              Created at
            </Text>
            {activeFilter === VerificationStatusFilter.SECURITY_CALL && (
              <Text
                mb={0}
                onClick={() => {
                  setSortBy(SortableField.SCHEDULED_FOR)
                  setSortOrder(SortOrder.ASCENDING)
                }}
              >
                Call scheduled for
              </Text>
            )}
          </Dropdown>

          <Dropdown title={sortOrderText} width="135px" mr={3}>
            <Text
              mb={0}
              onClick={() => {
                setSortOrder(SortOrder.DESCENDING)
              }}
            >
              Descending
            </Text>
            <Text
              mb={0}
              onClick={() => {
                setSortOrder(SortOrder.ASCENDING)
              }}
            >
              Ascending
            </Text>
          </Dropdown>

          <DateFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />

          <Flex minWidth="75px" maxWidth="75px" ml={2} justifyContent="center">
            {isLoading ? (
              <SpinnerIcon />
            ) : (
              <ClearText cursor="pointer" small onClick={clearFilters}>
                Clear filters
              </ClearText>
            )}
          </Flex>
        </Flex>
      </Flex>
    </HeaderContainer>
  )
}

export default Header
