import { getColor, getSpace, Text, Flex } from '@fatlama/llama-library'
import styled from 'styled-components'

const ToggleContainer = styled(Flex)`
  border-radius: 40px;
  height: 38px;
  border: 1px solid ${getColor('gsRat')};
  padding: 4px;
  width: auto;
  background-color: white;
`

const ToggleButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
  max-width: fit-content;
  transition: background-color 300ms;
  cursor: pointer;
`
export const Toggle = ({
  leftText,
  rightText,
  onLeftClick,
  onRightClick,
  leftActive,
}: {
  leftText: string
  rightText: string
  leftActive: boolean
  onLeftClick(): void
  onRightClick(): void
}) => {
  return (
    <ToggleContainer>
      <ToggleButton
        minWidth="30%"
        bg={leftActive ? 'marineMuteDark' : 'transparent'}
        onClick={onLeftClick}
      >
        <Text bold mb={0} color={leftActive ? 'white' : 'copyTwo'} noWrap>
          {leftText}
        </Text>
      </ToggleButton>
      <ToggleButton
        minWidth="30%"
        bg={!leftActive ? 'marineMuteDark' : 'transparent'}
        onClick={onRightClick}
      >
        <Text bold mb={0} color={!leftActive ? 'white' : 'copyTwo'} noWrap>
          {rightText}
        </Text>
      </ToggleButton>
    </ToggleContainer>
  )
}

export const TripleToggle = ({
  leftText,
  centerText,
  rightText,
  onLeftClick,
  onCenterClick,
  onRightClick,
  leftActive,
  centerActive,
  rightActive,
}: {
  leftText: string
  centerText: string
  rightText: string
  leftActive: boolean
  centerActive: boolean
  rightActive: boolean
  onLeftClick(): void
  onCenterClick(): void
  onRightClick(): void
}) => {
  return (
    <ToggleContainer>
      <ToggleButton
        minWidth="33.3%"
        bg={leftActive ? 'marineMuteDark' : 'transparent'}
        onClick={onLeftClick}
      >
        <Text bold mb={0} color={leftActive ? 'white' : 'copyTwo'}>
          {leftText}
        </Text>
      </ToggleButton>
      <ToggleButton
        minWidth="33.3%"
        bg={centerActive ? 'marineMuteDark' : 'transparent'}
        onClick={onCenterClick}
      >
        <Text bold mb={0} color={centerActive ? 'white' : 'copyTwo'}>
          {centerText}
        </Text>
      </ToggleButton>
      <ToggleButton
        minWidth="33.3%"
        bg={rightActive ? 'marineMuteDark' : 'transparent'}
        onClick={onRightClick}
      >
        <Text bold mb={0} color={rightActive ? 'white' : 'copyTwo'}>
          {rightText}
        </Text>
      </ToggleButton>
    </ToggleContainer>
  )
}

export default Toggle
