export interface ApiRequestType {
  REQUEST: string
  SUCCESS: string
  FAILURE: string
}

function createRequestTypes(base: string): ApiRequestType {
  return {
    REQUEST: `${base}.REQUEST`,
    SUCCESS: `${base}.SUCCESS`,
    FAILURE: `${base}.FAILURE`,
  }
}

export const SET_DELIVERY_STATUS = createRequestTypes('SET_DELIVERY_STATUS')
export const GET_RENTAL = createRequestTypes('GET_RENTAL')
export const LOGOUT = createRequestTypes('LOGOUT')
export const LOGIN = createRequestTypes('LOGIN')
export const AUTH = createRequestTypes('AUTH')
