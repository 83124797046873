/* eslint react/display-name:off */
import omit from 'lodash/omit'

import type { MouseEvent } from 'react'

const withFormik = (Component: any) => {
  return (props: any) => {
    const {
      onChange,
      onBlur,
      onFocus,
      isSubmitting,
      field,
      form: { touched, errors },
    } = props
    const propsToPass = omit(props, ['input', 'meta', 'onChange', 'onBlur', 'onFocus'])
    const errorToPass = touched[field.name] && errors[field.name] ? errors[field.name] : undefined
    return (
      <Component
        error={errorToPass}
        isLoading={isSubmitting}
        name={field.name}
        value={field.value}
        onChange={(e: MouseEvent<HTMLElement>) => {
          onChange?.(e)
          field.onChange(e)
        }}
        onBlur={(e: MouseEvent<HTMLElement>) => {
          onBlur?.(e)
          field.onBlur(e)
        }}
        onFocus={(e: MouseEvent<HTMLElement>) => {
          onFocus?.(e)
        }}
        {...propsToPass}
      />
    )
  }
}

export default withFormik
