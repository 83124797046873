import { Error, Flex, SpinnerIcon, Text } from '@fatlama/llama-library'
import { prop } from 'ramda'

import formatWithCurrency from '../../helpers/formatWithCurrency'
import { formatDateUTC } from '../../utils/dateUtils'

import { DataRow } from './Components'

import type { UserStats } from '@/interfaces'

const UserDetails = ({
  isLoadingBorrower,
  error,
  borrower,
  highestValueCOMPLETEDRental,
  stats,
}: {
  isLoadingBorrower: boolean
  error?: string
  borrower: any
  highestValueCOMPLETEDRental: number
  stats?: UserStats
}) => {
  if (isLoadingBorrower || !borrower) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <SpinnerIcon />
      </Flex>
    )
  }

  if (error) {
    return <Error>{error}</Error>
  }

  const statsKeysWithCustomer = Object.keys(stats ?? {}).filter((key) => key.includes('customer'))

  let rowCount = 1

  return (
    <Flex flexDirection="column" id="customerDetails">
      <DataRow
        rowCount={rowCount++}
        title="Name"
        value={`${borrower.firstName} ${borrower.lastName}`}
      />
      <DataRow rowCount={rowCount++} title="User ID" value={`${borrower.id}`} />
      <DataRow rowCount={rowCount++} title="Email" value={`${borrower.email}`} />
      <DataRow rowCount={rowCount++} title="Telephone" value={`${borrower.phone}`} />
      <DataRow
        rowCount={rowCount++}
        title="Signup Date"
        value={formatDateUTC(borrower.createdAt, 'd MMM yyyy')}
      />
      <DataRow rowCount={rowCount++} title="Signed up with" value={`${borrower.signedUpWith}`} />
      <DataRow
        rowCount={rowCount++}
        title="Highest value COMPLETED rental"
        value={
          highestValueCOMPLETEDRental > 0
            ? `${formatWithCurrency(highestValueCOMPLETEDRental, 'GBP')}`
            : 'N/A'
        }
      />
      <DataRow
        rowCount={rowCount++}
        title="Response time"
        value={`${borrower.responseTime || 'N/A'}`}
      />
      {statsKeysWithCustomer.map((key) => {
        return <DataRow key={key} rowCount={rowCount++} title={key} value={prop(key, stats)} />
      })}
      <DataRow
        rowCount={rowCount++}
        title="Stripe customer account"
        value={
          <a
            href={`https://dashboard.stripe.com/customers/${borrower.stripeCustomerId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text small bold color="whale" mb={0}>
              View
            </Text>
          </a>
        }
      />
    </Flex>
  )
}

export default UserDetails
