import { Box, Button, Error, Flex, Label } from '@fatlama/llama-library'
import { Field, Formik } from 'formik'
import { DateTime } from 'luxon'

import { roundToNearest15 } from '../../../helpers/roundToNearest15'
import formikFieldWrapper from '../../Common/formikFieldWrapper'
import TextArea from '../../Common/TextArea'
import { TripleToggle } from '../../Common/Toggle'

import { SharedRequestSecurityCallForm, sharedValidate } from './SharedRequestSecurityCallForm'

const FormTextArea = formikFieldWrapper(TextArea)

const validateLogCall = (values: { notes: string; answered: string }) => {
  if (!values.notes) {
    return { notes: 'Notes are required' }
  }
  const sharedValidateErrors = sharedValidate(values.answered === 'YES')(values)
  return {
    ...sharedValidateErrors,
  }
}

export const LogSecurityCallHandled = ({
  callId,
  onComplete,
  logSecurityCall,
  requestSecurityCall,
}: {
  callId: number
  onComplete(): void
  logSecurityCall(params: { answered: boolean; notes: string; id: number }): Promise<void>
  requestSecurityCall(params: {
    scheduledFor?: Date
    visibleToUser?: boolean
    requestedBy: string
  }): Promise<void>
}) => {
  const onSubmitLogSecurityCall = async (values: any, formikBag: any) => {
    const answered = values.answered === 'YES' ? true : false

    await logSecurityCall({ answered, notes: values.notes, id: callId })

    if (values.newCall === 'YES') {
      await requestSecurityCall({
        visibleToUser: values.visibleToUser === 'YES' || values.requestedByUser === 'YES',
        requestedBy: values.requestedByUser === 'YES' ? 'USER' : 'ADMIN',
        scheduledFor: values.callDate ? values.callDate : undefined,
      })
      formikBag.resetForm()
    }
    onComplete()
  }

  return (
    <Formik
      onSubmit={onSubmitLogSecurityCall}
      validate={validateLogCall}
      initialErrors={{
        notes: 'Notes are required',
      }}
      initialValues={{
        answered: '',
        newCall: '',
        callDate: roundToNearest15(DateTime.now().toJSDate()),
        notes: '',
        visibleToUser: 'NO',
        requestedByUser: '',
      }}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        errors,
        touched,
        isSubmitting,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <Flex flexDirection="column">
            <Flex mb={3}>
              <Flex flexDirection="column" mt={3} width={'auto'} mr={7} minWidth="220px">
                <Box maxWidth="220px" mb={4}>
                  <Label>Answered?</Label>
                  <TripleToggle
                    leftText={'YES'}
                    centerText={''}
                    rightText={'NO'}
                    onLeftClick={() => {
                      setFieldValue('answered', 'YES')
                    }}
                    onCenterClick={() => {
                      setFieldValue('answered', '')
                    }}
                    onRightClick={() => {
                      setFieldValue('answered', 'NO')
                    }}
                    leftActive={values.answered === 'YES'}
                    centerActive={values.answered === ''}
                    rightActive={values.answered === 'NO'}
                  />
                </Box>

                {values.answered !== '' ? (
                  <Box maxWidth="220px" mb={4}>
                    <Label>Request new call?</Label>
                    <TripleToggle
                      leftText={'YES'}
                      centerText={''}
                      rightText={'NO'}
                      onLeftClick={() => {
                        setFieldValue('newCall', 'YES')
                      }}
                      onCenterClick={() => {
                        setFieldValue('newCall', '')
                      }}
                      onRightClick={() => {
                        setFieldValue('newCall', 'NO')
                      }}
                      leftActive={values.newCall === 'YES'}
                      centerActive={values.newCall === ''}
                      rightActive={values.newCall === 'NO'}
                    />
                  </Box>
                ) : null}
              </Flex>
              <Box>
                <Label>Call notes</Label>
                <Field
                  name="notes"
                  type="text"
                  label="Call notes"
                  placeholder="Enter notes here"
                  style={{ height: '180px', marginBottom: '0px' }}
                  minRows={5}
                  maxRows={8}
                  required={true}
                  onChange={(e: any) => {
                    setFieldValue('notes', e.target.value)
                    setFieldTouched('notes', true, false)
                  }}
                  component={FormTextArea}
                />
                {errors.notes && touched.notes ? (
                  <Error>{errors.notes}</Error>
                ) : (
                  <Error color="white">.</Error>
                )}
              </Box>
            </Flex>

            {values.newCall === 'YES' && values.answered !== '' ? (
              <SharedRequestSecurityCallForm
                errors={errors}
                showRequestedByUser={values.answered === 'YES'}
                values={values}
                setFieldValue={setFieldValue}
              />
            ) : null}

            <Flex justifyContent="flex-end">
              <Button
                onClick={() => {
                  setFieldTouched('notes', true)
                  setFieldTouched('answered', true)
                  handleSubmit()
                }}
                large
                label={values.newCall === 'YES' ? 'Log call & request another' : 'Log call'}
                isLoading={isSubmitting}
                disabled={!isValid || !values.answered}
              />
            </Flex>
          </Flex>
        )
      }}
    </Formik>
  )
}
