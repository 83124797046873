// Breakpoints
const breakpoints = [
  '320px', // small phone
  '374px', // medium phone
  '450px', // large phone
  '768px', // tablet
  '1024px', // desktop
  '1200px', // large desktop
  '1200px', // large desktop
]

// styled-systems spacing scale (used for margin and padding)
const space = {
  0: '0',
  1: '8px',
  2: '16px',
  3: '24px',
  4: '32px',
  5: '40px',
  6: '48px',
  7: '56px',
  8: '64px',
}

const fontSizes = {
  0: '12px',
  1: '14px',
  2: '16px',
  3: '20px',
  4: '24px',
  5: '32px',
  6: '48px',
  7: '72px',
}

const lineHeights = {
  0: '1.2em',
  1: '1.5em',
}

const fonts = {
  body: 'MuseoSans, Helvetica',
  header: 'MuseoSans, Helvetica',
}

const colorCodes = {
  // colors
  orange: '#FFB949',

  // Core colours
  pomMute: '#FF7264',
  pomNeon: '#FF3F64',
  pomLight: '#FFF1F4',
  marineMute: '#2CCBBB',
  marineNeon: '#63FFD4',
  marineLight: '#EDF8F7',
  marineMuteDark: '#25AC9F',
  banMute: '#FFC217',
  banMuteTwo: '#FFEFC4',
  banNeon: '#FCFC51',
  banLight: '#FFF8E7',
  midNight: '#030D54',
  midNightLight: '#28316D',
  whale: '#4629D3',
  whaleHover: '#295CD3',
  whaleActive: '#3316BC',
  white: '#FFFFFF',
  whiteHover: 'rgba(255,255,255,0.1)',
  whiteActive: 'rgba(255,255,255,0.3)',
  whaleLight: '#F1EEFC',
  whaleLighter: '#F8F7FD',
  whaleLightest: '#F9F8FE',
  destructive: '#E22C1A',

  // Social colors
  facebookBlue: '#3D5A98',
  facebookBlueHover: '#3D5A98',
  facebookIcon: '#3B5998',
  twitterIcon: '#1DA1F2',
  linkedinIcon: '#0077B5',

  // Greyscale
  gsCoal: '#111111',
  gsGraphite: '#767676',
  gsRat: '#CCCCCC',
  gsCloud: '#EEEEEE',
  gsSlate: '#F5F5F5',
  gsMist: '#FAFAFA',
  gsWhite: '#FFFFFF',
}

const colors = {
  ...colorCodes,
  copyOne: colorCodes['gsCoal'],
  copyTwo: colorCodes['gsGraphite'],
  copyThree: colorCodes['gsRat'],
  primary: colorCodes['marineMute'],
  secondary: colorCodes['whale'],
  accentPrimary: colorCodes['marineLight'],
  error: colorCodes['pomNeon'],

  // These two will be configured by the partner
  primaryCTA: colorCodes['midNight'],
  accent: colorCodes['marineMute'],
  titleColor: colorCodes['midNight'],
}

const borders = {
  whaleLight: `1px solid ${colors['whaleLight']}`,
  brand: `2px solid ${colors['marineMute']}`,
  thinLight: `1px solid ${colors['gsRat']}`,
  thinExtraLight: `1px solid ${colors['gsCloud']}`,
  thinTransparent: '1px solid transparent',
  warning: `1px solid ${colors['banMute']}`,
  thinLightDashed: `1px dashed ${colors['gsRat']}`,
  thickWhite: `2px solid ${colors['white']}`,
}

const shadows = {
  thinLightBottom: `inset 0 -1px 0 0 ${colors['gsRat']}`,
  mediumWhaleBottom: `inset 0 -2px 0 0 ${colors['whale']}`,
  lightGray: `0 0 1px 0 rgba(0, 0, 0, 0.05),
                0 2px 4px 0 rgba(0, 0, 0, 0.05),
                0 0 8px 0 rgba(0, 0, 0, 0.05);`,
  heavyGray: `0 0 32px 0 rgba(0, 0, 0, 0.05),
                0 8px 16px 0 rgba(0, 0, 0, 0.05),
                0 0 1px 0 rgba(0, 0, 0, 0.05);`,
}

export default {
  breakpoints,
  space,
  fonts,
  fontSizes,
  lineHeights,
  colors,
  borders,
  shadows,
}
