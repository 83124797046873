import { Box, Flex, H3, Hr, Text } from '@fatlama/llama-library'

import { SectionBlock } from '../Components'
import { useSecurityCalls, useSecurityCallsMutation } from '../hooks/useSecurityCalls'

import { LogSecurityCallHandled } from './LogSecurityCall'
import { RequestNewSecurityCall } from './RequestNewSecurityCall'
import { SecurityCallTable } from './SecurityCallList'

const SecurityCalls = ({ userId }: { userId: number }) => {
  const { data: securityCalls, isLoading: loadingSecurityCalls } = useSecurityCalls({ userId })
  const { requestSecurityCall, logSecurityCall } = useSecurityCallsMutation(userId)

  const completedCalls = securityCalls?.filter((call) => Boolean(call.calledAt))
  const pendingSecurityCall = securityCalls?.find((call) => !call.calledAt)

  return (
    <SectionBlock mb={3}>
      <H3>Security Calls</H3>

      <Box mb={2}>
        <Text bold>Pending security call</Text>
        {pendingSecurityCall ? (
          <>
            <SecurityCallTable securityCalls={[pendingSecurityCall]} />
            <Box mt={6}>
              <Text bold>Log pending security call</Text>
              <LogSecurityCallHandled
                requestSecurityCall={requestSecurityCall}
                logSecurityCall={logSecurityCall}
                callId={pendingSecurityCall.id}
                onComplete={() => {}}
              />
            </Box>
          </>
        ) : (
          <Text color="copyTwo">No pending security calls</Text>
        )}
      </Box>

      {!pendingSecurityCall ? (
        <Flex justifyContent="flex-end" mt={2}>
          <RequestNewSecurityCall
            isLoadingSecurityCalls={loadingSecurityCalls}
            requestSecurityCall={requestSecurityCall}
          />
        </Flex>
      ) : null}

      {completedCalls && completedCalls.length > 0 ? (
        <>
          <Hr mt={4} mb={4} />
          <Text bold>Completed security call(s)</Text>
          <SecurityCallTable securityCalls={completedCalls} isCompletedCalls />
        </>
      ) : null}
    </SectionBlock>
  )
}

export default SecurityCalls
