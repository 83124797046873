import type { TransactionStatus, CMSTableRental } from '../../interfaces/index'

export enum Tab {
  PAST = 'PAST',
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
}

export enum CountryFilter {
  ALL = 'ALL',
  UK = 'UK',
  NA = 'NA',
  NORDICS = 'NORDICS',
}

export enum VerificationStatusFilter {
  ALL = 'ALL',
  SECURITY_REVIEW = 'SECURITY_REVIEW',
  DOC_REVIEW = 'DOC_REVIEW',
  CONFIRMATION_DOCUMENT_REVIEW = 'CONFIRMATION_DOCUMENT_REVIEW',
  CONFIRMATION_DOCUMENTS_PENDING = 'CONFIRMATION_DOCUMENTS_PENDING',

  PENDING_ANY_DOCS = 'PENDING_ANY_DOCS',
  SECURITY_CALL = 'SEC_CALL',
}

export enum RentalDateFilter {
  PAST = 'PAST',
  ACTIVE = 'ACTIVE',
}

export interface QueryRequest {
  filters: QueryFilters
  sortBy: SortingCriteria
  pageNumber: number
  pageSize?: number
}

export interface QueryResponse {
  txs: CMSTableRental[]
  pageNumber: number
  total: number
  pageCount: number
}

export interface QueryFilters {
  txStatuses?: TransactionStatus[]
  verificationStatus?: VerificationStatusFilter
  searchTerm?: string
  toDate?: string
  fromDate?: string
  dateFilter?: RentalDateFilter
  countryFilter?: CountryFilter
  customerId?: number
}

export enum SortableField {
  TX_FROM_DATE = 'TX_FROM_DATE',
  SECURITY_REVIEW_DATE = 'SECURITY_REVIEW_DATE',
  SCHEDULED_FOR = 'SCHEDULED_FOR',
  TX_CREATED_AT = 'TX_CREATED_AT',
}

export enum SortOrder {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export interface SortingCriteria {
  sortByField: SortableField
  sortOrder: SortOrder
}

export enum CalculatedSelectField {
  BASKET_ITEM_COUNT = 'basketItemCount',
}
