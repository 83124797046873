import { Box, Flex, Text, SpinnerIcon } from '@fatlama/llama-library'
import { flags } from '@my/assets/flags'
import { Country, Currency } from '@my/enums'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import currencyMap from '../../constants/currencyMap'
import { formatDateUTC, formatDateTimeUTC, isAfter, isBefore } from '../../utils/dateUtils'
import StatusPill from '../Common/StatusPill'

import { getTimeSince } from './utils'

import type { CMSTableRental } from '../../interfaces'

const FlagIconImg = styled.img`
  height: 20px;
  width: 20px;
  margin: auto;
  margin-bottom: 8px;
  object-fit: cover;
  border-radius: 10px;
`

const currencyToCountry = {
  [Currency.SEK]: Country.SWEDEN,
  [Currency.NOK]: Country.NORWAY,
  [Currency.EUR]: Country.FINLAND,
  [Currency.DKK]: Country.DENMARK,
  [Currency.USD]: Country.UNITED_STATES,
  [Currency.CAD]: Country.CANADA,
  [Currency.GBP]: Country.UNITED_KINGDOM,
} satisfies Record<Currency, Country>

const FlagIcon = ({ currency }: { currency: Currency }) => {
  const country = currencyToCountry[currency]
  return <FlagIconImg src={flags[country]} />
}

const ResultsContainer = styled(Box)`
  flex-grow: 1;
  overflow: auto;
  border-radius: 0px 8px 8px 8px;
`

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const TD = styled.td`
  font-size: 14px;
  position: relative;
  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

// box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.05),
// 0px 2px 4px rgba(0, 0, 0, 0.05);

const TableHeader = ({
  isSecurityReviewView,
  isSecurityCallView,
  isPendingAnyDocs,
}: {
  isSecurityReviewView?: boolean
  isSecurityCallView?: boolean
  isPendingAnyDocs?: boolean
}) => {
  return (
    <tr>
      <TH>ID</TH>
      <TH minWidth="70px">Dates</TH>
      <TH>Status</TH>
      {isSecurityReviewView && <TH>Time in review</TH>}
      {isSecurityCallView && <TH>Scheduled for</TH>}
      {isSecurityCallView && <TH>Scheduled by</TH>}
      {isPendingAnyDocs && <TH>Time pending docs</TH>}

      <TH minWidth="100px">Created</TH>
      <TH>GMV</TH>
      <TH minWidth="200px">Item Name(s)</TH>
      <TH>Borrower</TH>
      <TH>Lender</TH>
    </tr>
  )
}
const Results = ({
  results,
  searchTerm,
  isSecurityReviewView,
  isPendingAnyDocs,
  isSecurityCallView,
  inAll,
  isLoading,
}: {
  results: CMSTableRental[]
  isSecurityReviewView: boolean
  isPendingAnyDocs: boolean
  isSecurityCallView?: boolean
  searchTerm?: string
  inAll: boolean
  isLoading: boolean
}) => {
  const navigate = useNavigate()

  if ((!results || results.length === 0) && searchTerm) {
    return (
      <ResultsContainer>
        <table className="clickable-rows">
          <tbody>
            <TableHeader />
          </tbody>
        </table>
        <Flex alignItems="center" justifyContent="center" pt="70px">
          {isLoading ? <SpinnerIcon size="large" /> : <Text bold>No results</Text>}
        </Flex>
      </ResultsContainer>
    )
  }

  // Hacky way to sort security calls by scheduled date
  const sortedResults = isSecurityCallView
    ? results.sort((a, b) => {
        const aDate = a.securityCall?.scheduledFor ? new Date(a.securityCall.scheduledFor) : null
        const bDate = b.securityCall?.scheduledFor ? new Date(b.securityCall.scheduledFor) : null

        // If both are null
        if (aDate === null && bDate === null) {
          return 0
        }
        // If only a is null, a goes on top
        if (aDate === null) {
          return -1
        }
        // If only b is null, b goes on top
        if (bDate === null) {
          return 1
        }
        // Here TypeScript knows aDate and bDate are definitely not null
        if (isBefore(aDate, bDate)) {
          return -1
        }
        if (isAfter(aDate, bDate)) {
          return 1
        }
        return 0
      })
    : results

  return (
    <ResultsContainer>
      <table className="clickable-rows">
        <tbody>
          <TableHeader
            isSecurityReviewView={isSecurityReviewView}
            isSecurityCallView={isSecurityCallView}
            isPendingAnyDocs={isPendingAnyDocs}
          />
          {sortedResults.map((rental) => {
            const isCompleted =
              rental.toDate &&
              (rental.orderStatus === 'COMPLETED' ||
                (rental.orderStatus === 'ACTIVE' && isAfter(new Date(), rental.toDate)))

            const timeCustomerInReview =
              rental.timeCustomerWaitingForReview &&
              getTimeSince(rental.timeCustomerWaitingForReview)

            const timeWaitingForCustomerPendingDocs =
              rental.timeWaitingCustomerToAddDocs &&
              getTimeSince(rental.timeWaitingCustomerToAddDocs)

            const scheduledFor = rental.securityCall?.scheduledFor
              ? formatDateTimeUTC(rental.securityCall.scheduledFor, 'd MMM yy, HH:mm')
              : null

            const requestedBy = rental.securityCall?.requestedBy

            const scheduledForHasPassed =
              rental.securityCall?.scheduledFor &&
              isBefore(rental.securityCall.scheduledFor, new Date())

            return (
              <tr
                key={rental.orderId}
                onClick={() => {
                  navigate(`/rentals/${rental.orderId}`)
                }}
              >
                <TD position="relative">
                  <Flex width="auto" alignItems="center">
                    <FlagIcon currency={rental.currency} />
                  </Flex>

                  <Flex flexDirection="column" alignItem="center">
                    <Text mb={0} small textAlign="center">
                      {rental.orderId}
                    </Text>
                    {isCompleted && searchTerm ? (
                      <Text small textAlign="center">
                        (complete)
                      </Text>
                    ) : null}
                  </Flex>
                </TD>

                <TD minWidth="70px">
                  <Text small>
                    {rental.fromDate ? formatDateUTC(rental.fromDate, 'd MMM yy') : '-'}
                  </Text>
                  <Text small>
                    {rental.toDate ? formatDateUTC(rental.toDate, 'd MMM yy') : '-'}
                  </Text>
                </TD>

                <TD>
                  <StatusPill small mb={1} securityStatus={rental.securityStatus} />
                  {inAll ? (
                    <>
                      <StatusPill mb={1} small paymentStatus={rental.paymentStatus} />
                      <StatusPill small orderStatus={rental.orderStatus} />
                    </>
                  ) : null}
                </TD>

                {isSecurityReviewView && (
                  <TD>
                    <Text mb={0} color="pomMute" small>
                      {timeCustomerInReview || ''}
                    </Text>
                  </TD>
                )}

                {isPendingAnyDocs && (
                  <TD>
                    <Text mb={0} color="pomMute" small>
                      {timeWaitingForCustomerPendingDocs || ''}
                    </Text>
                  </TD>
                )}

                {isSecurityCallView && (
                  <TD>
                    <Text
                      mb={0}
                      color={scheduledForHasPassed ? 'marineMute' : 'pomMute'}
                      small
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {scheduledFor}
                    </Text>
                  </TD>
                )}

                {isSecurityCallView && (
                  <TD>
                    <Text
                      mb={0}
                      color={requestedBy === 'USER' ? 'marineMute' : 'copyOne'}
                      small
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {requestedBy}
                    </Text>
                  </TD>
                )}

                <TD minWidth="100px" style={{ whiteSpace: 'nowrap' }}>
                  {formatDateTimeUTC(rental.createdAt, 'd MMM yy, HH:mm')}
                </TD>

                <TD>
                  {currencyMap[rental.currency]}
                  {rental.rentalVolume.toFixed(2)}
                </TD>

                <TD minWidth="200px">
                  {rental.itemNames.length > 1
                    ? `${rental.itemNames.length} items`
                    : rental.itemNames[0]}
                </TD>

                <TD>{rental.borrowerEmail}</TD>
                <TD>
                  <Text>{rental.lenderEmail}</Text>
                  {/* {rental.lenderNotActivated && (
                    <Text small color="pomMute">
                      (Account not activated)
                    </Text>
                  )} */}
                </TD>
              </tr>
            )
          })}
        </tbody>
      </table>
    </ResultsContainer>
  )
}

export default Results
