import { Flex, Modal, H3, Button, Box } from '@fatlama/llama-library'
import { useState } from 'react'

import { formatDateUTC } from '../../utils/dateUtils'
import DateTimeRangePicker from '../Common/DateTimeRangePicker'

const DateFilter = ({ setDateFilter, dateFilter }: any) => {
  const hasDates = dateFilter && dateFilter[0] && dateFilter[1]
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const formattedDates = hasDates
    ? formatDateUTC(dateFilter[0], 'd MMM') + ' - ' + formatDateUTC(dateFilter[1], 'd MMM')
    : ''

  return (
    <>
      <Box width={formattedDates ? '400px' : 'auto'}>
        <Button
          fullWidth
          variant="secondary"
          onClick={() => setModalOpen(true)}
          label={formattedDates || 'Dates'}
        />
      </Box>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <H3 mb={3}>Choose dates</H3>
        <DateTimeRangePicker value={dateFilter} onChange={setDateFilter} dayOnly />
        <Flex justifyContent="flex-end">
          <Button onClick={() => setModalOpen(false)} label="Done" />
        </Flex>
      </Modal>
    </>
  )
}

export default DateFilter
