import { Text } from '@fatlama/llama-library'

import { DocumentStatus } from '../../interfaces'

export const DocStatus = ({ status }: { status?: string }) => {
  switch (status) {
    case DocumentStatus.VALIDATED:
      return (
        <Text mb={0} small color="marineMute">
          Approved
        </Text>
      )
    case DocumentStatus.REJECTED:
      return (
        <Text mb={0} small color="pomMute">
          Rejcted
        </Text>
      )
    case DocumentStatus.PENDING_REVIEW:
      return (
        <Text mb={0} small color="pomMute">
          Needs review
        </Text>
      )
    case DocumentStatus.EXPIRED:
      return (
        <Text mb={0} small color="copyTwp">
          Expired
        </Text>
      )
    default:
      return (
        <Text mb={0} small color="whale">
          Requested
        </Text>
      )
  }
}

export const ReqStatus = ({ status, docStatus }: { status: string; docStatus?: string }) => {
  if (status === 'PENDING') {
    if (docStatus === DocumentStatus.PENDING_REVIEW) {
      return (
        <Text mb={0} small color="pomMute">
          Needs review
        </Text>
      )
    }
    if (docStatus === DocumentStatus.REJECTED) {
      return (
        <Text mb={0} small color="whale">
          Requested (Has rejected doc)
        </Text>
      )
    }
    return (
      <Text mb={0} small color="whale">
        Requested
      </Text>
    )
  }
  return (
    <Text mb={0} small color="marineMute">
      Approved
    </Text>
  )
}
