import { DateTime } from 'luxon'

export const roundToNearest15 = (dateTime: Date): Date => {
  const dt = DateTime.fromJSDate(dateTime)
  const minutes = dt.minute
  const remainder = minutes % 15

  return remainder < 8
    ? dt.minus({ minutes: remainder }).toJSDate() // Round down
    : dt.plus({ minutes: 15 - remainder }).toJSDate() // Round up
}
