import { DateTime } from 'luxon'

/**
 * Format a date in UTC using the specified format
 * Shows local time with timezone abbreviation when showing time
 */
export function formatDateUTC(
  date: string | Date | null | undefined,
  format: string
): string | null {
  if (!date) return null
  const dt = DateTime.fromISO(date.toString())
  if (!dt.isValid) return null
  const localTime = dt.toLocal().toFormat(format)
  // Only show timezone if the format includes time (HH:mm)
  const hasTime = format.includes('HH:mm')
  return hasTime ? `${localTime} (${DateTime.now().toLocal().toFormat('z')})` : localTime
}

/**
 * Format a date and time in UTC using the specified format
 * Shows local time with timezone abbreviation when showing time
 */
export function formatDateTimeUTC(
  date: string | Date | null | undefined,
  format: string
): string | null {
  if (!date) return null
  const dt = DateTime.fromISO(date.toString())
  if (!dt.isValid) return null
  const localTime = dt.toLocal().toFormat(format)
  // Only show timezone if the format includes time (HH:mm)
  const hasTime = format.includes('HH:mm')
  return hasTime ? `${localTime} (${DateTime.now().toLocal().toFormat('z')})` : localTime
}

/**
 * Calculate the number of days between two dates
 */
export function datesToDays(fromDate: string | Date, toDate: string | Date): number {
  return DateTime.fromISO(toDate.toString()).diff(DateTime.fromISO(fromDate.toString()), 'days')
    .days
}

/**
 * Check if date1 is after date2
 */
export function isAfter(date1: string | Date, date2: string | Date): boolean {
  return DateTime.fromISO(date1.toString()) > DateTime.fromISO(date2.toString())
}

/**
 * Check if date1 is before date2
 */
export function isBefore(date1: string | Date, date2: string | Date): boolean {
  return DateTime.fromISO(date1.toString()) < DateTime.fromISO(date2.toString())
}

/**
 * Check if two dates are the same day
 */
export function isSameDay(date1: string | Date, date2: string | Date): boolean {
  return DateTime.fromISO(date1.toString()).hasSame(DateTime.fromISO(date2.toString()), 'day')
}

/**
 * Format a date in a specific timezone
 */
export function formatDateInTimezone(
  date: string | Date,
  timezoneName: string,
  format: string
): string | null {
  if (!date) return null
  const dt = DateTime.fromISO(date.toString())
  return dt.isValid
    ? `${dt.setZone(timezoneName).toFormat(format)} ${dt.setZone(timezoneName).toFormat('z')}`
    : null
}

/**
 * Get time difference between a date and now
 */
export interface TimeDifference {
  minutes: number
  hours: number
  days: number
}

export function getTimeDifference(date: string | Date): TimeDifference {
  const diff = DateTime.now().diff(DateTime.fromISO(date.toString()), ['minutes', 'hours', 'days'])
  return {
    minutes: Math.floor(diff.minutes),
    hours: Math.floor(diff.hours),
    days: Math.floor(diff.days),
  }
}
