export const ALL_REASONS = [
  'Dodgy',
  'Delete- Known Thief',
  'Delete- Identify fraud',
  'Delete- Dodgy behaviour ( fake ID/£1 rentals/ renting of themselves/ odd items/ Rolex) ',
  'Delete - Stripe data ( high risk of fraud) ',
  'Delete - Disciplinary red card',
  'Verification',
  'Name mismatch ( e.g. ID/bank card)',
  'Not financially viable ',
  'Underage',
  'Did not have the required documents ',
  'Unhappy with the verifications process',
  'Post - verification',
  'Open resolutions case - owes fees',
  'Lender unresponsive',
  'Borrower unresponsive',
  'Item not available temporarily ',
  'Item sold',
  'L does not trust B',
  'Unable to organise the handover',
  'B does not need the rental item ',
  'Duplicate account ',
  'Duplicate request',
  'Lender and borrower went off the platform ',
  'Hostile behaviour towards other users or Fat Llama stuff ',
  'Other  - PLEASE ADD NOTES',
]

export const BORROWER_REASONS = [
  'Dodgy',
  'Delete- Known Thief',
  'Delete- Identify fraud',
  'Delete- Dodgy behaviour ( fake ID/£1 rentals/ renting of themselves/ odd items/ Rolex) ',
  'Delete - Stripe data ( high risk of fraud) ',
  'Delete - Disciplinary red card',
  'Verification',
  'Name mismatch ( e.g. ID/bank card)',
  'Not financially viable ',
  'Underage',
  'Did not have the required documents ',
  'Unhappy with the verifications process',
  'Post - verification',
  'Open resolutions case - owes fees',
  'Borrower unresponsive',
  'Unable to organise the handover',
  'B does not need the rental item ',
  'Duplicate account ',
  'Duplicate request',
  'Lender and borrower went off the platform ',
  'Hostile behaviour towards other users or Fat Llama stuff ',
  'Other  - PLEASE ADD NOTES',
]

export const LENDER_REASONS = [
  'Not financially viable ',
  'Did not have the required documents',
  'Open resolutions case - owes fees',
  'Lender unresponsive',
  'Item not available temporarily ',
  'Item sold',
  'L does not trust B',
  'Unable to organise the handover',
  'Lender and borrower went off the platform ',
  'Hostile behaviour towards other users or Fat Llama stuff ',
  'Other  - PLEASE ADD NOTES',
]

export const SOCIAL_LINKS_REJECT_REASONS = {
  NAME_DOES_NOT_MATCH: `Name does not match`,
  NO_CONNECTION_TO_USER: 'No connection to user',
  INSUFFICIENT_NUMBER_OF_CONNECTIONS: 'Insufficient connections',
  PROFILE_IS_PRIVATE: 'Profile is private',
  INVALID_SOCIAL_MEDIA_TYPE: 'Unacceptable social media type',
  NO_ACTIVITY_IN_LAST_6_MONTHS: 'No activity in last 6 months',
  NO_POSTS: 'Profile has no posts on it',
  POSSIBLE_FAKE_PROFILE: 'Suspicion of being a fake profile',
  NOT_VALID_URL: 'Not a valid URL/profile',
}
