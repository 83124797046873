import { Flex, H3, Text, Box, getColor, getSpace, Span } from '@fatlama/llama-library'
import { flags } from '@my/assets/flags'
import styled from 'styled-components'

import CURRENCT_FX_MAP from '../../constants/currencyConversion'
import { getAdminUrl } from '../../helpers/environment-helper'
import formatWithCurrency from '../../helpers/formatWithCurrency'
import { formatDateUTC } from '../../utils/dateUtils'
import ClickToCopy from '../Common/ClickToCopy'
import StatusPill from '../Common/StatusPill'
import { NAV_WIDTH } from '../NavSideBar/Index'
import { getTimeSince } from '../Rentals/utils'

import type { DuplicatedUserType, OrderDataType, ReferralHistory } from '../../interfaces/index'
import type { Country } from '@my/enums'

const FlagIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 16px;
  margin-top: 4px;
  margin-left: 16px;
  object-fit: cover;
  border-radius: 24px;
`

interface Props {
  totalValueOfItemsRequested: number
  idOrOfferUuid: string
  order: OrderDataType
  activeTab: Tab
  selfReferrer?: DuplicatedUserType & {
    referrerUsedCouponIds: number[]
    referrerId: ReferralHistory['giverUserId']
  }
  borrower?: any
  lender?: { country: Country }
  setActiveTab(tab: Tab): void
}

export enum Tab {
  TRANSACTION = 'TRANSACTION',
  COMMS = 'COMMS',
}

const ClickableText = styled(Text)`
  cursor: pointer;
`

const OpenLinkText = styled(ClickableText)`
  color: ${getColor('whale')};
  margin-bottom: 0;
`
const TabContainer = styled(Flex)`
  border-bottom: 1px solid
    ${(props: any) => (props.isActive ? getColor('marineMute')(props) : 'transparent')};
  align-items: center;
  margin-bottom: -1px;
  justify-content: center;

  cursor: pointer;
  margin-right: ${getSpace(3)};
  padding-right: ${getSpace(2)};
  padding-bottom: ${getSpace(1)};
  padding-bottom: ${getSpace(0)};

  p {
    white-space: nowrap;
  }
`

const HeaderContainer = styled(Box).attrs({
  id: 'header',
})`
  &.header-up {
    transform: translate3d(0, -100%, 0);
    transition-duration: 0.35s;
  }
  width: calc(100% - ${NAV_WIDTH}px);

  z-index: 2;
  position: fixed;
  transition: transform 0.5s ease-in-out;
  transform: translateZ(0);
  pointer-events: all;
`

const TabButton = ({
  title,
  onClick,
  isActive,
}: {
  title: string
  isActive: boolean
  onClick(t?: any): any
}) => {
  return (
    <TabContainer onClick={onClick} isActive={isActive}>
      <Text small color={isActive ? 'marineMute' : 'copyOne'} mb={0}>
        {title}
      </Text>
    </TabContainer>
  )
}

const HeaderSection = ({
  order,
  idOrOfferUuid,
  lender,
  activeTab,
  setActiveTab,
  selfReferrer,
  totalValueOfItemsRequested,
}: Props) => {
  const goToUser = (id: number) => {
    // history.push(`/customers/${id}`);
    const adminUrl = getAdminUrl(`/User/${id}`)
    window.open(
      adminUrl?.toString(),
      '_blank' // <- This is what makes it open in a new window.
    )
  }

  const movedToSecurityReviewAt = getTimeSince(
    order.timeCustomerWaitingForReview || order.orderStatusUpdatedAt
  )

  return (
    <HeaderContainer mb={3} pt={2} bg="white">
      {/* <Flex bg="pomMute">
        <H3>PAUSED</H3>
      </Flex> */}
      <Flex py={2} mb={-2} px={3}>
        <Flex width={2 / 7} borderRight="thinLight" pr={4} flexDirection="column">
          <Flex alignItems="center" mb={1}>
            <H3 mb={0} mr={3} textAlign="center">
              {idOrOfferUuid}
              {lender?.country ? <FlagIcon src={flags[lender.country]} /> : null}
            </H3>
            <Box>
              <Flex alignItems="center">
                <StatusPill mb={1} small securityStatus={order.securityStatus} />

                {order.securityStatus === 'IN_MANUAL_REVIEW' && (
                  <Text mb={0} ml={2} small color="pomNeon">
                    (for {movedToSecurityReviewAt})
                  </Text>
                )}
              </Flex>
              <StatusPill small mb={1} paymentStatus={order.paymentStatus} />
            </Box>
          </Flex>
        </Flex>

        <Flex
          width={2 / 7}
          flexDirection="column"
          justifyContnt="center"
          borderRight="thinLight"
          px={4}
        >
          <Box mb={2}>
            <Flex alignitems="center">
              <Text small bold mb={0}>
                Borrower - {order.customerName}
              </Text>
            </Flex>

            <Flex>
              <ClickToCopy text={order.customerId}>
                <Text b={0} small>
                  {order.customerId}{' '}
                </Text>
              </ClickToCopy>

              <ClickToCopy text={order.customerEmail}>
                <Text b={0} small>
                  - {order.customerEmail}{' '}
                </Text>
              </ClickToCopy>

              <OpenLinkText small onClick={() => goToUser(order.customerId)}>
                {' - '}
                open
              </OpenLinkText>
            </Flex>
          </Box>

          <Box>
            <Flex alignitems="center">
              <Text small bold mb={0}>
                Lender - {order.ownerName}
              </Text>
            </Flex>

            <Flex>
              <ClickToCopy text={order.ownerId}>
                <Text mb={0} small>
                  {order.ownerId}{' '}
                </Text>
              </ClickToCopy>

              <ClickToCopy text={order.ownerEmail}>
                <Text mb={0} small>
                  - {order.ownerEmail}{' '}
                </Text>
              </ClickToCopy>

              <OpenLinkText small onClick={() => goToUser(order.ownerId)}>
                {' - '}
                open
              </OpenLinkText>
            </Flex>
            {/* {order.lenderNotActivated ? (
              <Text color="pomMute">(Account not activated)</Text>
            ) : null} */}
          </Box>
        </Flex>

        <Flex width={1.8 / 7} flexDirection="column" pl={4} borderRight="thinLight">
          <Text small>
            Item(s) value: {formatWithCurrency(order.totalEstimatedValue, order.currency)}{' '}
          </Text>

          {order.currency !== 'GBP' ? (
            <Text small mt={-2} color="copyTwo">
              In £:{' '}
              {`(${formatWithCurrency(
                order.totalEstimatedValue / CURRENCT_FX_MAP[order.currency],
                'GBP'
              )})`}{' '}
            </Text>
          ) : null}

          <Text small>
            Total requested value:{' '}
            <Span bold small color="pomMute">
              {formatWithCurrency(totalValueOfItemsRequested, order.currency)}
            </Span>
          </Text>

          <a href={`https://admin.hygglo.se/Order/${idOrOfferUuid}`}>
            <Text link small>
              Open in Hygglo admin
            </Text>
          </a>

          {selfReferrer && (
            <ClickableText small onClick={() => goToUser(selfReferrer.id)}>
              Self referred (email):{' '}
              <Span bold small color="pomMute">
                {selfReferrer.email}
              </Span>
            </ClickableText>
          )}
        </Flex>

        <Flex flexDirection="column" width={1.2 / 7} pl={4} pr={2}>
          <Flex justifyContent="space-between">
            <Text mb={'4px'} small>
              Number of items:{' '}
            </Text>
            <Text mb={'4px'} small>
              {order.items.length}
            </Text>
          </Flex>

          <Flex justifyContent="space-between">
            <Text mb={'4px'} small>
              Highest fraud risk:{' '}
            </Text>
            <Text
              mb={'4px'}
              small
              color={(order.highestFraudRisk || 0) >= 4 ? 'pomMute' : 'copyOne'}
            >
              {order.highestFraudRisk}
            </Text>
          </Flex>

          <Flex justifyContent="space-between">
            <Text mb={'4px'} small>
              {' '}
              Order total:{' '}
            </Text>
            <Text mb={'4px'} small>
              {formatWithCurrency(order.orderFullTotal, order.currency)}
            </Text>
          </Flex>

          <Text small mb={0}>
            {order.fromDate && formatDateUTC(order.fromDate, 'd MMM')} -{' '}
            {order.toDate && formatDateUTC(order.toDate, 'd MMM')}
          </Text>
        </Flex>
      </Flex>

      <Flex borderBottom="thinLight">
        <Flex width="auto" px={3}>
          <TabButton
            isActive={activeTab === Tab.TRANSACTION}
            title="Transaction"
            onClick={() => setActiveTab(Tab.TRANSACTION)}
          />
          <TabButton
            isActive={activeTab === Tab.COMMS}
            title="Communication"
            onClick={() => setActiveTab(Tab.COMMS)}
          />
        </Flex>
      </Flex>
    </HeaderContainer>
  )
}

export default HeaderSection
