import { Text, Box } from '@fatlama/llama-library'
import styled from 'styled-components'

const ClickableBox = styled(Box)`
  cursor: pointer;
`
const Logout = () => {
  const logout = () => {
    localStorage.clear()
    window?.location.reload()
  }
  return (
    <ClickableBox onClick={logout}>
      <Text bold color="white" cursor="pointer">
        Logout
      </Text>
    </ClickableBox>
  )
}

export default Logout
