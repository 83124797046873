import { Flex, Input, Button } from '@fatlama/llama-library'
import { Field, Formik } from 'formik'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Page } from '../../interfaces'
import formikFieldWrapper from '../Common/formikFieldWrapper'
import RentalResults from '../Rentals/Index'

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`

const FormInput = formikFieldWrapper(Input)

const CustomerRentals = () => {
  const { id } = useParams() as any
  const navigate = useNavigate()

  const onSubmit = (values: any) => {
    navigate(`${Page.customerRentals}/${values.userId}`)
  }

  if (!id || isNaN(Number(id))) {
    return (
      <FullScreenCentered>
        <Flex
          alignItems="center"
          maxWidth="350px"
          pr={155}
          flexDirection="column"
          pt="56px"
          pb="150px"
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              username: '',
              password: '',
            }}
            // validate={validate}
            render={(formikBag) => {
              const {
                // setFieldTouched,
                isSubmitting,
                handleSubmit,
                // setFieldValue,
                isValid,
                // values,
              } = formikBag
              return (
                <Flex py={2} flexDirection="column">
                  <Field
                    mb={3}
                    name="userId"
                    type="number"
                    required={true}
                    label="UserId"
                    component={FormInput}
                  />

                  <Button
                    // variant='secondary'
                    onClick={handleSubmit}
                    large
                    fullWidth
                    label="Submit"
                    isLoading={isSubmitting}
                    disabled={!isValid}
                  />
                </Flex>
              )
            }}
          />
        </Flex>
      </FullScreenCentered>
    )
  }
  return <RentalResults customerId={id ? Number(id) : undefined} />
}

export default CustomerRentals
