import { getColor } from '@fatlama/llama-library'
import { ReactSVG } from 'react-svg'
import styled, { css } from 'styled-components'
import { width, height, space, position, top } from 'styled-system'

import type { SpaceProps, PositionProps, HeightProps, WidthProps } from 'styled-system'

// When we have generic there will be another layer ontop to route partner/generic icons. Eg search
// some icons are colored with the path.stroke, and some path.fill...
// this knowledge should stay confined to this file. However any color overrides
// will need to refer to this file
export const SVG_CLASS = 'styled-svg'

const centeredStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const pathColored = css`
  path {
    ${(props: any) => {
      if (props.isStrokeColored) {
        return `stroke: ${getColor(props.color || 'UIDefault')(props)}`
      }
      return `fill: ${getColor(props.color || 'UIDefault')(props)}`
    }}
  }
` as any

const gColored = css`
  path {
    ${(props: any) => {
      return `fill: ${getColor(props.color || 'UIDefault')(props)}`
    }}
  }
` as any

const getColoring = (props: any) => (props.isGColored ? gColored : pathColored)

const SVGStyler = styled.div`
  cursor: ${(props: any) => props.cursor || 'pointer'};
  ${centeredStyle};
  * {
    ${centeredStyle};
  }
  ${width};
  ${height};
  span {
    ${width};
    ${height};
  }
  .${SVG_CLASS} {
    ${space};
    ${width};
    ${height};
    ${position};
    ${top};
    ${getColoring};
  }
` as any

type IconShape = {
  src: string
  isStrokeColored?: boolean
  color?: string
}

type Props = IconShape & SpaceProps & PositionProps & HeightProps & WidthProps & { cursor?: string }

const Icon = ({ src, isStrokeColored, ...rest }: Props) => {
  const stylerProps = {
    height: rest.width ? 'auto' : 18,
    width: rest.height ? 'auto' : 18,
    ...rest,
  }
  return (
    <SVGStyler {...stylerProps} isStrokeColored={isStrokeColored}>
      <ReactSVG
        src={src}
        wrapper="span"
        beforeInjection={(svg) => {
          svg.classList.add(SVG_CLASS)
        }}
      />
    </SVGStyler>
  )
}

export default Icon
