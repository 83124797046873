import { Flex, getColor, Button, H3, Input, Text } from '@fatlama/llama-library'
import { Field, Formik } from 'formik'
import styled from 'styled-components'
import { color, border } from 'styled-system'

import { getBaseurl } from '../../helpers/environment-helper'
import GetApi from '../../utils/api'
import formikFieldWrapper from '../Common/formikFieldWrapper'
// import { useHistory } from "react-router-dom";

const FormInput = formikFieldWrapper(Input)

const validate = (values: any) => {
  const errors = {} as any
  if (!values.username) {
    errors.username = 'Required'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

export const AuthButton = styled.a`
  border: 1px solid ${getColor('gsRat')};
  ${border};
  ${color};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 40px;
  z-index: 5;
  cursor: pointer;
  padding: 0px;
` as any

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
`

const getEnvironment = (baseUrl: string) => {
  if (!baseUrl) {
    return 'No base url'
  }
  if (baseUrl.includes('localhost')) {
    return 'Local'
  }
  if (baseUrl.includes('staging')) {
    return 'Staging'
  }
  return 'Production'
}

const Login = () => {
  const api = GetApi()
  const onSubmit = async (values: any) => {
    try {
      const res = await api.login(values)
      if (res.adminId) {
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const currentBaseUrl = getBaseurl()

  return (
    <FullScreenCentered>
      <Flex alignItems="center" maxWidth="350px" flexDirection="column" pt="56px" pb="150px">
        <Text small>{getEnvironment(currentBaseUrl ?? '')}</Text>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            username: '',
            password: '',
          }}
          validate={validate}
          render={(formikBag) => {
            const { isSubmitting, handleSubmit, isValid } = formikBag
            return (
              <Flex px={3} py={2} flexDirection="column">
                <H3 mb={2}>Login</H3>

                <Field
                  mb={3}
                  name="username"
                  type="text"
                  required={true}
                  label="Username"
                  component={FormInput}
                />

                <Field
                  mb={3}
                  name="password"
                  type="password"
                  required={true}
                  label="Password"
                  component={FormInput}
                />
                <Button
                  onClick={handleSubmit}
                  large
                  fullWidth
                  label="Login"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                />
              </Flex>
            )
          }}
        />
      </Flex>
    </FullScreenCentered>
  )
}

export default Login
