import { Flex, H3 } from '@fatlama/llama-library'
import { useState } from 'react'

import Toggle from '../Common/Toggle'
import Communication from '../CommunicationAndNotes/Communication'
import Notes from '../CommunicationAndNotes/Notes'

import { SectionBlock, TwoGrid } from './Components'

import type { OrderDataType } from '../../interfaces/index'

const CommunicationsAndNotes = ({ order }: { order: OrderDataType }): any => {
  const [sendingRenter, setSendingRenter] = useState(true)

  const userId = sendingRenter ? order.customerId : order.ownerId
  const title = sendingRenter ? 'Borrower' : 'Lender'
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="center" mb={3}>
        <Toggle
          leftText="Borrower"
          rightText="Lender"
          onLeftClick={() => setSendingRenter(true)}
          onRightClick={() => setSendingRenter(false)}
          leftActive={sendingRenter}
        />
      </Flex>
      <TwoGrid>
        <SectionBlock mb={3}>
          <H3>User notes</H3>

          <Notes userId={userId} title={title} noteContextId={`TransId: ${order.orderId}`} />
        </SectionBlock>

        <SectionBlock mb={3}>
          <H3>Send {title} Comms</H3>
          <Communication
            userId={userId}
            userName={sendingRenter ? order.customerFirstName : order.ownerFirstName}
            sendingRenter={sendingRenter}
            order={order}
            noteContextId={`TransId: ${order.orderId}`}
          />
        </SectionBlock>
      </TwoGrid>
    </Flex>
  )
}

export default CommunicationsAndNotes
