import React from 'react'
import styled from 'styled-components'

import { Flex } from '../Layout/Base'
import { Text } from '../Typography/Index'

const MessageBoxType = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'warning',
}

const getBackgroundColor = (type) => {
  switch (type) {
    case MessageBoxType.ERROR:
      return 'error'
    case MessageBoxType.INFO:
      return 'banLight'
    case MessageBoxType.SUCCESS:
      return 'marineLight'
    default:
      return 'banLight'
  }
}

const UnderlinedText = styled(Text)`
  text-decoration-line: underline;
  cursor: pointer;
`
const MessageBox = (props) => {
  const { title, message, type, actionText, onClick } = props
  return (
    <Flex bg={getBackgroundColor(type)} p={3} flexDirection="column" onClick={onClick} mb={5}>
      <Text bold color={type === MessageBoxType.ERROR ? 'white' : 'copyOne'}>
        {title}
      </Text>

      <Text small color={type === MessageBoxType.ERROR ? 'white' : 'copyOne'}>
        {message}
      </Text>

      <UnderlinedText
        mb={0}
        textAlign="right"
        color={type === MessageBoxType.ERROR ? 'white' : 'whale'}
      >
        {actionText}
      </UnderlinedText>
    </Flex>
  )
}

export default MessageBox
