import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import { width, space, maxWidth } from 'styled-system'

import { Box } from '../../index'
import { getColor, getFontSize, getFont, getSpace } from '../../theme/themeHelpers.js'
import { Label, Error, Text } from '../Typography/Index'

import InputContainer from './Container'

const ClearText = styled(Text)`
  font-size: 12px;
  line-height: 20px;
`
const Wrapper = styled.div`
  ${width};
  ${space};
  ${maxWidth};
  display: flex;
  flex-direction: column;
  font-family: ${getFont('body')};
  width: 100%;
  position: relative;
`

const StyledError = styled(Error)`
  margin-bottom: 0;
  ${(props) => {
    return props.inlineError
      ? ``
      : `
    position: absolute;
    bottom: -20px;`
  }}
`

const getBorderColor = ({ inverted, borderless, error }) => {
  if (error) {
    return 'pomNeon'
  }
  if (inverted) {
    return 'gsSlate'
  }
  if (borderless) {
    return 'white'
  }
  return 'gsCloud'
}

const getBackgroundColor = (isActive, borderless) => {
  if (!isActive && !borderless) {
    return 'white'
  }

  return 'whaleLighter'
}

const SVG_CLASS = 'styled-svg'

const focusAndActiveState = css`
  box-shadow: 0px 0px 4px 2px rgba(0, 102, 178, 0.25);
  .hover-responsive-icon {
    .${SVG_CLASS} {
      path {
        stroke: ${getColor('whale')};
      }
    }
  }
`

const StyledInputElement = styled.input`
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  color: ${getColor('copyOne')};
  font-family: ${getFont('body')};
  font-size: ${getFontSize(2)};
  line-height: 24px;
  -webkit-appearance: none;
  &::-webkit-input-placeholder {
    color: ${getColor('copyThree')};
  }
  width: 100%;
  &:focus {
    outline: none;
  }

  -webkit-appearance: none;
  &::-webkit-input-placeholder {
    color: ${getColor('copyThree')};
    font-family: ${getFont('body')};
    font-size: 14px;
    font-weight: 300;
  }
  &::-moz-placeholder {
    color: ${getColor('copyThree')};
    font-family: ${getFont('body')};
    font-size: 14px;
    font-weight: 300;
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  height: 36px;

  resize: none;
  border-radius: ${(props) => (props.isRoundInput ? '26px' : '8px')};
  margin: 0;
  background-color: ${(props) => {
    return getColor(getBackgroundColor(props.isActive, props.borderless))
  }};
  .hover-responsive-icon {
    .${SVG_CLASS} {
      path {
        transition: 0.4s;
        stroke: ${getColor('gsRat')};
      }
    }
  }

  border: 1.2px solid ${(props) => getColor(getBorderColor(props))};
  &:focus {
    ${(props) => (!props.borderless ? focusAndActiveState : '')};
  }
  &:focus-within {
    ${(props) => (!props.borderless ? focusAndActiveState : '')};
  }
  padding-left: ${getSpace(2)};
  padding-right: ${(props) => props.rightElementPadding || getSpace(2)};

  color: ${(props) =>
    props.disabled && !props.borderless ? getColor('copyTwo') : getColor('copyOne')};

  &:not(:placeholder-shown) {
    background-color: ${(props) => (props.error ? 'white' : getColor('whaleLighter'))};
  }
`

/**
 * Generic CoreInput component
 */
class Input extends React.PureComponent {
  handleKeyPress = (event) => {
    if (this.props.onEnterKeyPress && event.key === 'Enter') {
      this.props.onEnterKeyPress()
    }
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event)
    }
  }

  render() {
    const {
      id,
      value,
      autoComplete,
      autoFocus,
      type,
      name,
      placeholder,
      inputRef,
      maxLength,
      disabled,
      containerClassName,
      onBlur,
      onFocus,
      error,
      inlineError,
      label,
      onChange,
      isActive,
      onClear,
      clearable,
      ...rest
    } = this.props

    return (
      <Wrapper width={width} className={containerClassName} {...rest}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <Box position="relative">
          <StyledInputElement
            variant="input"
            id={id}
            name={name}
            ref={inputRef}
            value={value}
            error={error}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder || ' '}
            type={type || 'text'}
            onChange={(e) => {
              onChange && onChange(e)
            }}
            {...rest}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            maxLength={maxLength}
            disabled={disabled}
            rightElementPadding={onClear ? '42px' : 0}
            hasLeftIcon={Boolean(this.props.LeftIcon)}
            onKeyPress={this.handleKeyPress}
          />

          {clearable && (
            <Box
              p={1}
              position="absolute"
              width="auto"
              right={1}
              top={0}
              onClick={() => {
                onChange && onChange({ target: { value: '' } })
                onClear && onClear()
              }}
            >
              <ClearText mb={0} bold>
                Clear
              </ClearText>
            </Box>
          )}
        </Box>

        {error && <StyledError inlineError={inlineError}>{error}</StyledError>}
      </Wrapper>
    )
  }
}

Input.propTypes = {
  ...InputContainer.propTypes,
  /** id for the input element. */
  id: PropTypes.string,
  /** current name of input */
  name: PropTypes.string,
  /** current value of input */
  value: PropTypes.string,
  /** e.g. 'd-o-b-day' or 'full-name'. */
  autoComplete: PropTypes.string,
  /** type of input e.g. password, text etc */
  type: PropTypes.string,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** classname to apply to container element */
  containerClassName: PropTypes.string,
  /** function to run on blur */
  onBlur: PropTypes.func,
  /** function to run on focus */
  onFocus: PropTypes.func,
  /** function to run on change */
  onChange: PropTypes.func,
  /** function to run when enter key is pressed */
  onEnterKeyPress: PropTypes.func,
}

export default Input
