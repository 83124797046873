import { Box, Error, Flex, Label, Text } from '@fatlama/llama-library'

import { TripleToggle } from '../../Common/Toggle'

import { ScheduleCallTimeDate } from './ScheduleCallTimeDate'

export const sharedValidate = (showRequestedByUser: boolean) => (values: any) => () => {
  const errors: any = {}
  if (values.visibleToUser === '') {
    errors.visibleToUser = 'Required'
  }
  if (showRequestedByUser && values.requestedByUser === '') {
    errors.requestedByUser = 'Required'
  }
  return errors
}

export const SharedRequestSecurityCallForm = ({
  values,
  errors,
  setFieldValue,
  showRequestedByUser,
}: {
  values: any
  errors: any
  setFieldValue: (field: string, value: any) => void
  showRequestedByUser: boolean
}) => {
  return (
    <Box>
      <Text bold>Request security call</Text>
      <Flex>
        <Box minWidth="220px" maxWidth="220px" mr={7}>
          {showRequestedByUser ? (
            <Box maxWidth="220px" mb={4}>
              <Label>User decided schedule?</Label>
              <TripleToggle
                leftText={'YES'}
                centerText={''}
                rightText={'NO'}
                onLeftClick={() => {
                  setFieldValue('requestedByUser', 'YES')
                }}
                onCenterClick={() => {
                  setFieldValue('requestedByUser', '')
                }}
                onRightClick={() => {
                  setFieldValue('requestedByUser', 'NO')
                }}
                leftActive={values.requestedByUser === 'YES'}
                centerActive={values.requestedByUser === ''}
                rightActive={values.requestedByUser === 'NO'}
              />
            </Box>
          ) : null}
          <>
            {!showRequestedByUser || values.requestedByUser === 'NO' ? (
              <Box maxWidth="220px" mb={4}>
                <Label>Borrower can view and change time?</Label>
                <TripleToggle
                  leftText={'YES'}
                  centerText={''}
                  rightText={'NO'}
                  onLeftClick={() => {
                    setFieldValue('visibleToUser', 'YES')
                  }}
                  onCenterClick={() => {
                    setFieldValue('visibleToUser', '')
                  }}
                  onRightClick={() => {
                    setFieldValue('visibleToUser', 'NO')
                  }}
                  leftActive={values.visibleToUser === 'YES'}
                  centerActive={values.visibleToUser === ''}
                  rightActive={values.visibleToUser === 'NO'}
                />
                {errors.visibleToUser ? <Error>{errors.visibleToUser}</Error> : null}
              </Box>
            ) : null}
          </>
        </Box>

        <ScheduleCallTimeDate
          setCallDate={(date) => setFieldValue('callDate', date)}
          callDate={values.callDate}
        />
      </Flex>
    </Box>
  )
}
