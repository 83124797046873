import { Box, Flex, getSpace, Text } from '@fatlama/llama-library'
import styled from 'styled-components'

import { DocumentDisplayOnly } from './DocumentDisplay'

import type { DuplicatedUserType, Duplicates } from '../../interfaces/index'

const DocContainer = styled(Box)`
  display: grid;
  grid-column-gap: ${getSpace(3)};
  grid-row-gap: ${getSpace(4)};
  grid-template-columns: 1fr 1fr;
`

const DuplicatesDocuments = ({ duplicates }: { duplicates?: Duplicates }) => {
  if (!duplicates || !duplicates.accounts) {
    return null
  }
  return (
    <Flex flexDirection="column">
      <Box mb={4}>
        {Object.values(duplicates?.accounts)?.map((dup: DuplicatedUserType) => {
          if (!dup || !dup.documents || dup.documents.length === 0) {
            return null
          }

          return (
            <Box key={dup.id}>
              <Box>
                <Text small>Email: {dup.email}</Text>
                <Text small>ID: {dup.id}</Text>
              </Box>
              <DocContainer mb={3}>
                {dup.documents.map((doc) => (
                  <DocumentDisplayOnly key={doc.id} document={doc} />
                ))}
              </DocContainer>
            </Box>
          )
        })}
      </Box>
    </Flex>
  )
}

export default DuplicatesDocuments
