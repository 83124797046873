import { Flex, Box, Button, Text, Modal, H3, SpinnerIcon, Error } from '@fatlama/llama-library'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { DocumentType } from '../../interfaces'
import GetApi from '../../utils/api'
import { formatDateTimeUTC } from '../../utils/dateUtils'
import Dropdown from '../Common/Dropdown'

import { DocStatus } from './Components'
import { ImageDisplay } from './ImageOrVideo'
import OnlinePresenceLink from './OnlinePresenceLink'
import { getReasonsByDocumentType } from './rejectionReasons'

import type { Document, DuplicatedUserType } from '../../interfaces'

export const DocumentDisplayOnly = ({
  document,
  noTitle,
  duplicateUser,
}: {
  document: Document
  noTitle?: boolean
  duplicateUser?: DuplicatedUserType
}) => {
  const [isLoadingImg, setIsLoadingImg] = useState(false)

  const type = document.confirmedType || document.suspectedType
  const {
    status,
    validatedBy,
    validatedAt,
    rejectedBy,
    rejectedAt,
    rejectedReason,
    createdAt,
    personaVerificationId,
  } = document
  const api = GetApi()
  const [signedUrl, setSignedUrl] = useState()
  useEffect(() => {
    const url = document?.url
    const getSignedImgUrls = async () => {
      setIsLoadingImg(true)
      if (!url) {
        return
      }
      const response = await api.getSignedUrls({ urls: [url] })
      if (response.success) {
        const signed = response.payload[0]
        setSignedUrl(signed)
      }

      setIsLoadingImg(false)
    }
    if (url) {
      getSignedImgUrls()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.url])

  if (type === DocumentType.ONLINE_PRESENCE) {
    return (
      <>
        {' '}
        <OnlinePresenceLink
          {...document}
          withoutAction
          noTitle={noTitle}
          duplicateUser={duplicateUser}
        />
      </>
    )
  }
  return (
    <Flex flexDirection="column">
      <Box border="thinLight" borderRadius={8} p={2} mb={2}>
        {noTitle ? null : (
          <Text bold small capitalize mb={0}>
            {type}
          </Text>
        )}

        {isLoadingImg ? (
          <SpinnerIcon />
        ) : (
          <ImageDisplay
            src={signedUrl}
            defaultAsPdf={
              type === DocumentType.PROOF_OF_ADDRESS || type === DocumentType.PROOF_OF_EMPLOYMENT
            }
          />
        )}
        <DocStatus status={status} />

        {validatedBy && status === 'VALIDATED' && (
          <Text small mb={1}>
            Validated by: {validatedBy} at {formatDateTimeUTC(validatedAt, 'd MMM yy HH:mm')}
          </Text>
        )}
        {rejectedBy && status === 'REJECTED' && (
          <Text small mb={1}>
            Rejected by: {rejectedBy} at {formatDateTimeUTC(rejectedAt, 'd MMM yy HH:mm')}. Reason:
            {rejectedReason ? rejectedReason : 'Not set'}
          </Text>
        )}
        <Text small>Added: {formatDateTimeUTC(createdAt, 'd MMM yy HH:mm')}</Text>

        {duplicateUser ? (
          <>
            <Text small>Email: {duplicateUser.email}</Text>
            <Text small>ID: {duplicateUser.id}</Text>
          </>
        ) : null}
        {personaVerificationId ? (
          <Text
            small
            link
            mt={1}
            onClick={() =>
              window.open(
                `https://app.withpersona.com/dashboard/verifications/${personaVerificationId}`,
                '_blank' // <- This is what makes it open in a new window.
              )
            }
          >
            Persona verificationId: {personaVerificationId}
          </Text>
        ) : null}
      </Box>
    </Flex>
  )
}

const DocumentWithAction = (props: { document?: Document }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [rejectionReason, setRejectionReason] = useState<string>('')

  const [isLoadingReject, setIsLoadingReject] = useState(false)
  const [isLoadingApprove, setIsLoadingApprove] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [approveError, setApproveError] = useState<string>()
  const [rejectError, setRejectError] = useState<string>()
  const api = GetApi()

  if (!props.document) {
    return null
  }
  const {
    status,
    // validatedBy,
    // rejectedBy,
    // personaVerificationId,
    confirmedType,
    suspectedType,
    id,
  } = props.document

  const approveDocument = async () => {
    try {
      setIsLoadingApprove(true)
      setApproveError(undefined)
      const res = await api.validateDocument({
        id,
      })
      if (res.success) {
        navigate(`${pathname}?refresh=true`)
      } else {
        setApproveError('Unable to approve document')
      }
    } catch {
      setApproveError('Unable to approve document')
    } finally {
      setIsLoadingApprove(false)
    }
  }

  const rejectDocument = async () => {
    if (!rejectionReason) {
      return
    }

    try {
      setIsLoadingReject(true)
      setRejectError(undefined)

      const payload = {
        id,
        reason: rejectionReason,
      } as any

      const res = await api.rejectDocument(payload)

      if (res.success) {
        navigate(`${pathname}?refresh=true`)
        setShowRejectModal(false)
      } else {
        setRejectError('Unable to reject document')
      }
    } catch {
      setRejectError('Unable to reject document')
    } finally {
      setIsLoadingReject(false)
    }
  }
  const type = confirmedType || suspectedType

  if (type === DocumentType.ONLINE_PRESENCE) {
    return <OnlinePresenceLink {...props.document} />
  }
  return (
    <Flex flexDirection="column" mb={3}>
      <DocumentDisplayOnly document={props.document} />
      <Flex justifyContent="flex-end">
        {confirmedType === DocumentType.VIDEO_SELFIE ||
        suspectedType === DocumentType.VIDEO_SELFIE ? null : (
          <Box width="auto" mr={2}>
            <Button
              mr={2}
              alert
              disabled={status === 'REJECTED'}
              variant="secondary"
              onClick={() => setShowRejectModal(true)}
              label="Reject"
            />
          </Box>
        )}
        <Button
          label="Approve"
          disabled={status === 'VALIDATED'}
          isLoading={isLoadingApprove}
          onClick={approveDocument}
        />
      </Flex>

      {approveError && <Error>{approveError}</Error>}
      <Modal isOpen={showRejectModal} onClose={() => setShowRejectModal(false)}>
        <Flex px={3} py={2} flexDirection="column">
          <H3 mb={2}>Reject document </H3>
          <Box mb={2}>
            <Dropdown
              options={getReasonsByDocumentType(suspectedType)}
              // value={this.getReason()}

              handleChange={(event: any) => {
                setRejectionReason(event.target.value)
              }}
              value={rejectionReason}
            />
            {rejectError && <Error>{rejectError}</Error>}
          </Box>
          <Button
            alert
            variant="primary"
            isLoading={isLoadingReject}
            disabled={!rejectionReason}
            onClick={rejectDocument}
            label="Reject"
          />
        </Flex>
      </Modal>
    </Flex>
  )
}

export default DocumentWithAction
