import { Box, Flex, Label, Text } from '@fatlama/llama-library'
import { DateTime } from 'luxon'
import DateTimePicker from 'react-datetime-picker'

import { roundToNearest15 } from '../../../helpers/roundToNearest15'
import { DateTimePickerCSS } from '../../Common/DateTimePickerCSS'

export const ScheduleCallTimeDate = ({
  setCallDate,
  callDate,
}: {
  setCallDate: (date: Date | null) => void
  callDate: Date | null
}) => {
  // Convert UTC date to local time for display
  const localCallDate = callDate ? DateTime.fromJSDate(callDate).toLocal().toJSDate() : null

  // Convert local date back to UTC when setting
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const localDate = DateTime.fromJSDate(date)
      const utcDate = localDate.toUTC().toJSDate()
      setCallDate(utcDate)
    } else {
      setCallDate(null)
    }
  }

  return (
    <Box mb={3}>
      <Label>Scheduled call time? ({DateTime.now().zoneName})</Label>
      <Flex flexDirection="row">
        <Box>
          <DateTimePickerCSS>
            <DateTimePicker
              onChange={handleDateChange}
              value={localCallDate}
              format="dd MMM yyyy HH:mm"
            />
          </DateTimePickerCSS>
        </Box>

        <Text mt={2} small textAlign="center" px={5}>
          or
        </Text>
        <Flex alignItems="center">
          {[0, 1, 2, 3, 4, 5].map((i) => {
            return (
              <Text
                key={i}
                link
                px={1}
                onClick={() =>
                  setCallDate(
                    roundToNearest15(DateTime.now().plus({ hours: i }).toUTC().toJSDate())
                  )
                }
              >
                {i === 0 ? 'Now' : i}
              </Text>
            )
          })}

          <Text small>hours from now</Text>
        </Flex>
      </Flex>
    </Box>
  )
}
