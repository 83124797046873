import {
  Flex,
  Box,
  Text,
  getSpace,
  Button,
  getBorder,
  SpinnerIcon,
  Error,
  Label,
} from '@fatlama/llama-library'
import { Field, Formik } from 'formik'
import { useState, useEffect } from 'react'
import styled from 'styled-components'

import GetApi from '../../utils/api'
import { formatDateTimeUTC } from '../../utils/dateUtils'
import formikFieldWrapper from '../Common/formikFieldWrapper'
import TextArea from '../Common/TextArea'

const FormInput = formikFieldWrapper(TextArea)

const NotesContainer = styled(Flex)`
  height: 270px;
  overflow-y: scroll;
  white-space: pre-wrap;
  flex-direction: column;
  border: ${getBorder('thinLight')};
  border-radius: 16px;
  background-color: white;
  font-size: 12px;
  padding-left: ${getSpace(3)};
  padding-right: ${getSpace(3)};
  padding-bottom: ${getSpace(2)};
  padding-top: ${getSpace(2)};
  p {
    font-size: 12px;
    line-height: 20px;
  }
`

const CommunicationsAndNotes = ({
  userId,
  title,
}: {
  userId: number
  title?: string
  noteContextId: string
}): any => {
  const [notes, setUserNotes] = useState([])
  // const user = useSelector(getUser);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const api = GetApi()

  const getUserNotes = async () => {
    const res = await getNotes(userId)
    setUserNotes(res)
  }

  const getNotes = async (userId: number) => {
    try {
      setError(undefined)
      setIsLoading(true)

      const res = await api.getUserNotes(userId)

      if (!res.success) {
        setError('Cannot get notes')
        return
      }
      const data = res.payload
      return data
    } catch {
      setError('Cannot get notes')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUserNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const updateNotes = async (note: string) => {
    const payload = {
      userId,
      text: note,
    }

    await api.addUserNote(payload)

    getUserNotes()
  }

  const onSubmit = async (values: any, formik: any) => {
    await updateNotes(values.note)
    formik.setFieldValue('note', '')

    formik.setSubmitting(false)
  }

  if (error) {
    return <Error>{error}</Error>
  }

  if (isLoading && !notes) {
    return (
      <Flex height="400px" alignItems="center" justifyContent="center">
        <SpinnerIcon />
      </Flex>
    )
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        note: '',
      }}
      validate={(values) => (!values.note ? { note: 'Required' } : {})}
      render={(formikBag) => {
        const { setFieldTouched, isSubmitting, handleSubmit, setFieldValue, isValid } = formikBag
        return (
          <Flex flexDirection="column">
            <NotesContainer mb={3}>
              {notes.map((note: any) => {
                return (
                  <Box mb={2} key={note.text}>
                    <Text bold mb={0}>
                      Admin: {note.adminName} {formatDateTimeUTC(note.createdAt, 'd MMM yy, HH:mm')}
                    </Text>

                    <Text>{note.text}</Text>
                  </Box>
                )
              })}
            </NotesContainer>
            <Box>
              <Label>Add note to {title || 'user'} </Label>

              <Field
                mb={3}
                name="note"
                type="text"
                style={{ height: '40px' }}
                maxRows={5}
                minRows={3}
                required={true}
                onChange={(e: any) => {
                  setFieldTouched('note', true, false)
                  setFieldValue('note', e.target.value)
                }}
                label={`Add note for ${title || 'user'}`}
                component={FormInput}
              />
            </Box>

            <Button
              // variant='secondary'
              onClick={handleSubmit}
              large
              fullWidth
              label={`Add ${title || 'user'} note`}
              isLoading={isSubmitting}
              disabled={!isValid}
            />
          </Flex>
        )
      }}
    />
  )
}

export default CommunicationsAndNotes
