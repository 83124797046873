import { createContext, useReducer } from 'react'

import reducer from './reducer'

import type { TAction } from './actions'
import type { SelectorFn } from './selectors'
import type { Dispatch, FC, ReactNode } from 'react'

interface State {
  hasLoggedOut: boolean
  error: string
  loading: boolean
  user?: any
}

const initialState: State = {
  hasLoggedOut: false,
  error: '',
  loading: false,
}

interface IContextProps<T> {
  dispatch: Dispatch<TAction>
  getValue(selector: SelectorFn<T>): T
}

export const AppContext = createContext<IContextProps<any>>({
  dispatch: () => {
    // Dispatch initial value
  },
  getValue: () => initialState,
})

const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const getValue = (selector: any) => {
    return selector(state)
  }

  return <AppContext.Provider value={{ getValue, dispatch }}>{children}</AppContext.Provider>
}

export default AppProvider
