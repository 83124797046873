import React from 'react'
import styled from 'styled-components'

import { getColor } from '../theme/themeHelpers'

import { Flex } from './Layout/Base'
import { Text } from './Typography/Index'

const ToggleContainer = styled(Flex)`
  border-radius: 40px;
  height: 56px;
  border: 1px solid ${getColor('gsSlate')};
  padding: 4px;
`

const ToggleButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  transition: background-color 300ms;
  cursor: pointer;
`

const Toggle = ({ leftText, rightText, onLeftClick, onRightClick, leftActive }) => {
  return (
    <ToggleContainer>
      <ToggleButton bg={leftActive ? 'marineMuteDark' : 'transparent'} onClick={onLeftClick}>
        <Text bold mb={0} color={leftActive ? 'white' : 'copyTwo'}>
          {leftText}
        </Text>
      </ToggleButton>
      <ToggleButton bg={!leftActive ? 'marineMuteDark' : 'transparent'} onClick={onRightClick}>
        <Text bold mb={0} color={!leftActive ? 'white' : 'copyTwo'}>
          {rightText}
        </Text>
      </ToggleButton>
    </ToggleContainer>
  )
}

export default Toggle
