import { Box, H3, Text, Modal } from '@fatlama/llama-library'
import { useState } from 'react'
import styled from 'styled-components'

import { formatDateTimeUTC } from '../../../utils/dateUtils'

import type { SecurityCall } from '../../../interfaces/index'

const TH = styled.th`
  font-size: 14px;
  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

const TD = styled.td`
  font-size: 14px;

  width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  max-width: ${(props: { width?: string; minWidth?: string }) => props.width || 'auto'};
  min-width: ${(props: { width?: string; minWidth?: string }) => props.minWidth || 'auto'};
` as any

export const SecurityCallTable = ({
  securityCalls,
  isCompletedCalls,
}: {
  securityCalls: SecurityCall[] // Assuming SecurityCall is an array type
  isCompletedCalls?: boolean
}) => {
  const [showAllNotes, setShowAllNotes] = useState(false)
  return (
    <Box>
      <table>
        <tbody>
          <tr>
            <TH>Requested by</TH>

            {isCompletedCalls ? (
              <>
                <TH>answered</TH>
                <TH>Called by</TH>
                <TH>Called at</TH>
                <TH>Notes</TH>
              </>
            ) : (
              <>
                <TH>Scheduled for</TH>
                <TH>Visible to user</TH>
                <TH>User notes</TH>
              </>
            )}
          </tr>

          {securityCalls.map((call) => {
            return (
              <SecurityCallRow
                key={call.id}
                call={call}
                isCompletedCall={isCompletedCalls}
                showAllNotes={showAllNotes}
              />
            )
          })}
          {isCompletedCalls ? (
            <tr>
              <TD></TD>
              <TD></TD>
              <TD></TD>
              <TD></TD>
              <TD>
                <Text link onClick={() => setShowAllNotes(!showAllNotes)}>
                  {!showAllNotes ? 'View all' : 'Hide all'}
                </Text>
              </TD>
            </tr>
          ) : null}
        </tbody>
      </table>
    </Box>
  )
}

const SecurityCallRow = ({
  call,
  isCompletedCall,
  showAllNotes,
}: {
  call: SecurityCall
  isCompletedCall?: boolean
  showAllNotes?: boolean
}) => {
  const [notesOpen, setNotesOpen] = useState(false)
  const openNotesClick = () => {
    setNotesOpen(true)
  }

  return (
    <>
      <Modal isOpen={notesOpen} onClose={() => setNotesOpen(false)}>
        <H3>Notes from call</H3>
        <Text>{call.notes}</Text>
      </Modal>

      <tr>
        <TD>
          <Box key={call.id} mb={3}>
            <Text>{call.requestedBy}</Text>
          </Box>
        </TD>

        {isCompletedCall ? (
          <>
            <TD>
              <Box key={call.id} mb={3}>
                <Text bold color={call.answered ? 'marineMute' : 'pomMute'}>
                  {call.answered?.toString()}
                </Text>
              </Box>
            </TD>
            <TD>
              <Box key={call.id} mb={3}>
                <Text>{call.calledBy}</Text>
              </Box>
            </TD>
            <TD>
              <Box key={call.id} mb={3}>
                <Text>
                  {call.calledAt ? formatDateTimeUTC(call.calledAt, 'd MMM yyyy, HH:mm') : null}
                </Text>
              </Box>
            </TD>

            <TD>
              <Box onClick={openNotesClick} key={call.id} mb={3}>
                <Text link>View</Text>
              </Box>
            </TD>
          </>
        ) : (
          <>
            <TD>
              <Box mb={3}>
                <Text>
                  {call.scheduledFor
                    ? formatDateTimeUTC(call.scheduledFor, 'd MMM yyyy, HH:mm')
                    : 'N/A'}
                </Text>
              </Box>
            </TD>
            <TD>
              <Box mb={3}>
                <Text>{call.visibleToUser ? 'YES' : 'NO'}</Text>
              </Box>
            </TD>
            <TD>
              <Box mb={3}>
                <Text>{call.userNotes}</Text>
              </Box>
            </TD>
          </>
        )}
      </tr>
      {showAllNotes && isCompletedCall ? (
        <tr>
          <TD>
            <Box borderRadius={8}>
              <Text small>&quot;{call.notes}&quot;</Text>
            </Box>
          </TD>
        </tr>
      ) : null}
    </>
  )
}
