import {
  Box,
  Button,
  Flex,
  getBreakpoint,
  getColor,
  getSpace,
  H3,
  Span,
  SpinnerIcon,
  Text,
} from '@fatlama/llama-library'
import { indexBy, isEmpty, path, prop } from 'ramda'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import formatWithCurrency from '../../helpers/formatWithCurrency'
import { AdminEventType, Page } from '../../interfaces'
import GetApi from '../../utils/api'
import { formatDateTimeUTC, formatDateUTC } from '../../utils/dateUtils'
import UserFlaggedBanner from '../Common/UserFlaggedBanner'
import { AllUserConfirmationDocuments, ConfirmationDocuments } from '../ConfirmationDocuments'
import DuplicatesDocuments from '../Documents/DuplicateDocuments'
import Documents from '../Documents/Index'
import { NAV_WIDTH } from '../NavSideBar/Index'
import { getTimeSince } from '../Rentals/utils'

import AdminEvents from './AdminEvents'
import ApprovalSection from './ApprovalSection'
import AutoApproveDisabledSection from './AutoApproveDisabledSection'
import CommunicationsAndNotes from './CommunicationsAndNotes'
import { DataRow, DoubleDataRow, SectionBlock, SectionCollapsableBlock } from './Components'
import CurrentRentalItems from './CurrentRentalItems'
import CustomerCreditScore from './CustomerCreditScore'
import DuplicatesList from './Duplicates'
import HeaderSection, { Tab } from './HeaderSection'
import useDuplicates from './hooks/useDuplicates'
import useOtherRentals from './hooks/useOtherRentals'
import useUsers from './hooks/useUsers'
import useUserSearches from './hooks/useUserSearches'
import useUserVerifications from './hooks/useUserVerifications'
import OrderVerificationComponent from './OrderVerification'
import OtherRentals from './OtherRentals'
import SecurityCalls from './SecurityCall/Index'
import StripeData from './StripeData'
import UserDetails from './UserDetails'

import type { DuplicatedUserType, GetCMSRentalPageResponse, OrderDataType } from '../../interfaces'

const MapPin = styled.div`
  z-index: 1;
  width: 40px;
  height: 40px;
  @media (min-width: ${getBreakpoint(3)}) {
    width: 70px;
    height: 70px;
  }
  background-color: ${getColor('whale')}33; // 0.2 opacity
  border: 1px solid ${getColor('whale')};
  border-radius: 100px;
`

const MapImage = styled.img`
  width: 100%;
  border-radius: 16px;
  object-fit: contain;
`

const StripeLogo = styled.img`
  width: 65px;
`

const PageContainer = styled(Box)`
  transition: padding 0.7s ease;
`

const FullScreenCentered = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`

const TwoGrid = styled(Box)`
  display: grid;
  grid-column-gap: ${getSpace(3)};
  grid-template-columns: 1fr 1fr;
`
const SelectedRental = () => {
  // ScrollHideHeader("pageContainer");

  const [currentOrder, setCurrentOrder] = useState<GetCMSRentalPageResponse>()

  const borrowerId = path(['order', 'customerId'], currentOrder)
  const query = new URLSearchParams(useLocation().search)

  const navigate = useNavigate()

  const refresh = Boolean(query.get('refresh'))
  const { userVerifications, userDocuments, loadingUserVerifications } = useUserVerifications(
    borrowerId,
    currentOrder?.order.orderId,
    refresh
  )

  const { otherRentals } = useOtherRentals(borrowerId)

  const { userSearches, loadingUserSearches, showMore, canShowMore } = useUserSearches(borrowerId)
  const duplicates = useDuplicates(borrowerId)
  const userDetails = useUsers({
    lenderId: currentOrder && currentOrder.order.ownerId,
    borrowerId: currentOrder && currentOrder.order.customerId,
  })

  const [activeTab, setActiveTab] = useState(Tab.TRANSACTION)
  const [showUpcomingTx, setShowUpcomingTx] = useState(false)
  const api = GetApi()
  const { idOrOfferUuid } = useParams() as any
  const { accounts, selfReferrer } = duplicates

  const hasDuplicateFlaggedDetails =
    accounts &&
    Boolean(
      Object.values(accounts).find(
        (account: DuplicatedUserType) => account.tags && account.tags.hasFlaggedDetails
      )
    )

  const hasDuplicateAutoApproveBlocked =
    accounts &&
    Boolean(
      Object.values(accounts).find(
        (account: DuplicatedUserType) => account.tags && account.tags.isFlaggedDodgy
      )
    )

  const [headerHeight, setheaderHeight] = useState(90)

  const getAndSetHeight = () => {
    const header = document.getElementById('header')
    if (header) {
      setheaderHeight(header.offsetHeight)
    }
  }

  useEffect(() => {
    getAndSetHeight()
    window.addEventListener('resize', getAndSetHeight)
    return () => {
      window.removeEventListener('resize', getAndSetHeight)
    }
  }, [])

  useEffect(() => {
    getAndSetHeight()
  }, [currentOrder, hasDuplicateFlaggedDetails, hasDuplicateAutoApproveBlocked])

  const getRental = async () => {
    // const getRental = async () => {
    const response = await api.getRental({ idOrOfferUuid })

    const formatted = {
      ...response.payload,
      overlappingOrders: indexBy(prop('orderId'), response.payload.overlappingOrders),
      upcomingOrders: indexBy(prop('orderId'), response.payload.upcomingOrders),
    }
    setCurrentOrder(formatted)
    // }
  }
  useEffect(() => {
    if (refresh) {
      getRental()
      navigate(`${Page.rentals}/${idOrOfferUuid}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (!currentOrder || Number(idOrOfferUuid) !== currentOrder.order.orderId) {
      getRental()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, idOrOfferUuid])

  if (!currentOrder) {
    return (
      <FullScreenCentered>
        <SpinnerIcon />
      </FullScreenCentered>
    )
  }

  const { order, overlappingOrders, upcomingOrders } = currentOrder

  const totalValueOfOverlapping = overlappingOrders
    ? Object.keys(overlappingOrders).reduce((acc: number, idOrOfferUuid: any) => {
        const overlappingOrder = overlappingOrders[idOrOfferUuid]
        return acc + overlappingOrder.totalEstimatedValue
      }, 0)
    : 0

  const totalValueOfItemsRequested = totalValueOfOverlapping + order.totalEstimatedValue

  const approved = (currentOrder.order.adminEvents || []).find(
    (event) => event.type === AdminEventType.ORDER_APPROVED
  )
  const rejected = (currentOrder.order.adminEvents || []).find(
    (event) => event.type === AdminEventType.ORDER_REJECTED
  )

  const highestValueCOMPLETEDRental = (otherRentals || []).reduce(
    (acc: number, rental: OrderDataType) => {
      return rental.orderStatus === 'COMPLETED' && rental.totalEstimatedValue > acc
        ? rental.totalEstimatedValue
        : acc
    },
    0
  )

  let detailsRowCount = 1

  return (
    <Box bg="rgba(70, 41, 211, 0.05)">
      <Box position="fixed" zIndex={10} width={`calc(100% - ${NAV_WIDTH}px)`}>
        <UserFlaggedBanner
          autoApproveDisabled={order.autoApproveDisabled}
          duplicateAccounts={duplicates.accounts}
        />
      </Box>

      <PageContainer bg="#ggg" position="relative" pt={order.autoApproveDisabled ? '40px' : 0}>
        <HeaderSection
          activeTab={activeTab}
          idOrOfferUuid={idOrOfferUuid}
          setActiveTab={setActiveTab}
          order={order}
          borrower={userDetails && userDetails.borrower}
          lender={userDetails && userDetails.lender}
          totalValueOfItemsRequested={totalValueOfItemsRequested}
          selfReferrer={selfReferrer}
        />

        <Box pt={headerHeight + 24} px={3} minHeight="100vh">
          {activeTab === Tab.COMMS ? (
            <CommunicationsAndNotes order={order} />
          ) : (
            <>
              <TwoGrid>
                <Box>
                  <SectionBlock>
                    <H3>Rental Details</H3>

                    <DoubleDataRow
                      rowCount={detailsRowCount++}
                      title="Requested at"
                      value={formatDateTimeUTC(order.createdAt, 'd MMM yy HH:mm')}
                      value2={getTimeSince(order.createdAt)}
                    />

                    <DoubleDataRow
                      rowCount={detailsRowCount++}
                      title="Paid at"
                      value={
                        order.paymentStatusUpdatedAt &&
                        (order.paymentStatus === 'FUNDS_RESERVED' ||
                          order.paymentStatus === 'FULLY_PAID')
                          ? formatDateTimeUTC(order.paymentStatusUpdatedAt, 'd MMM yy HH:mm')
                          : 'N/A'
                      }
                      value2={
                        order.paymentStatusUpdatedAt &&
                        (order.paymentStatus === 'FUNDS_RESERVED' ||
                          order.paymentStatus === 'FULLY_PAID')
                          ? getTimeSince(order.paymentStatusUpdatedAt)
                          : 'N/A'
                      }
                    />

                    <DoubleDataRow
                      rowCount={detailsRowCount++}
                      title="Time customer waiting for review"
                      value={
                        order.securityStatus === 'IN_MANUAL_REVIEW' &&
                        order.timeCustomerWaitingForReview
                          ? formatDateTimeUTC(order.timeCustomerWaitingForReview, 'd MMM yy HH:mm')
                          : 'N/A'
                      }
                      value2={
                        order.timeCustomerWaitingForReview
                          ? getTimeSince(order.timeCustomerWaitingForReview)
                          : 'N/A'
                      }
                    />

                    <DataRow
                      rowCount={detailsRowCount++}
                      title="From Date"
                      value={order.fromDate && formatDateUTC(order.fromDate, 'd MMM yy')}
                    />

                    <DataRow
                      rowCount={detailsRowCount++}
                      title="To Date"
                      value={order.toDate && formatDateUTC(order.toDate, 'd MMM yy')}
                    />

                    {rejected && (
                      <DataRow
                        rowCount={detailsRowCount++}
                        title="Rejected by"
                        value={rejected.adminName}
                      />
                    )}

                    {approved && (
                      <DataRow
                        rowCount={detailsRowCount++}
                        title="Approved by"
                        value={approved.adminName}
                      />
                    )}

                    <DataRow
                      rowCount={detailsRowCount++}
                      title={order.items.length > 1 ? 'Item IDs' : 'Item ID'}
                      value={order.items.map((item: any) => item.itemId).join(', ')}
                    />

                    <DataRow
                      rowCount={detailsRowCount++}
                      title="Discount"
                      value={
                        order.totalDiscount > 0
                          ? formatWithCurrency(order.totalDiscount, order.currency)
                          : '-'
                      }
                    />

                    {/* <DataRow
                      rowCount={detailsRowCount++}
                      title="What renter sees"
                      value={
                        <>
                          <Text bold small>
                            {order.statusLabelForCustomer.header}
                          </Text>
                          <Text small>{order.statusLabelForCustomer.subHeader}</Text>
                        </>
                      }
                    /> */}

                    <a href={`https://admin.hygglo.se/Order/${idOrOfferUuid}`}>
                      <DataRow
                        rowCount={detailsRowCount++}
                        title="Change dates"
                        value={
                          <Span link color="whale" small>
                            Done in admin - click here
                          </Span>
                        }
                      />
                    </a>
                  </SectionBlock>
                </Box>
                <Box>
                  <SectionBlock>
                    <H3>Customer Details</H3>

                    <UserDetails
                      {...userDetails}
                      highestValueCOMPLETEDRental={highestValueCOMPLETEDRental}
                      stats={currentOrder.order.stats}
                    />
                  </SectionBlock>
                </Box>
              </TwoGrid>

              <OrderVerificationComponent orderVerification={order.orderVerification} />

              <CustomerCreditScore customerCreditScore={currentOrder.order.customerCreditScore} />

              <SectionCollapsableBlock
                Title={
                  <H3>Duplicate accounts ({Object.keys(duplicates.accounts || {}).length})</H3>
                }
              >
                <DuplicatesList
                  autoApproveDisabled={order.autoApproveDisabled}
                  duplicates={{ accounts, selfReferrer }}
                  isLoading={false}
                />
              </SectionCollapsableBlock>

              <SectionCollapsableBlock Title={<StripeLogo src="/images/stripe.png" />}>
                <StripeData transactionId={order.orderId} />
              </SectionCollapsableBlock>

              <SectionBlock>
                <Box mb={3} id="otherRentals">
                  <Flex>
                    <H3>Item{order.items.length > 1 ? 's' : ''} in this rental</H3>
                  </Flex>

                  <CurrentRentalItems order={order} />
                </Box>

                {!isEmpty(overlappingOrders) && (
                  <Box mb={3}>
                    <H3>Overlapping rentals</H3>
                    <OtherRentals orders={overlappingOrders} />
                  </Box>
                )}

                {!isEmpty(overlappingOrders) && (
                  <Flex justifyContent="flex-end">
                    <Flex width="auto">
                      <Text bold>Total items value: </Text>

                      <Text width="90px" px={2} bold color="pomNeon">
                        {formatWithCurrency(totalValueOfItemsRequested, order.currency)}
                      </Text>
                    </Flex>
                  </Flex>
                )}

                {!isEmpty(upcomingOrders) ? (
                  showUpcomingTx ? (
                    <Box>
                      <H3>Other Upcoming rentals</H3>
                      <OtherRentals orders={indexBy(prop('orderId'), upcomingOrders)} />
                    </Box>
                  ) : (
                    <Text mt={3} textAlign="right" link onClick={() => setShowUpcomingTx(true)}>
                      Show other upcoming txs
                    </Text>
                  )
                ) : null}
              </SectionBlock>

              {!isEmpty(otherRentals) ? (
                <SectionCollapsableBlock
                  Title={
                    <H3
                      onClick={() => {
                        navigate(`/customer-rentals/${borrowerId}`)
                      }}
                    >
                      All other orders as Customer (click me)
                    </H3>
                  }
                >
                  <Box>
                    <OtherRentals
                      isCustomerOtherRentals
                      orders={indexBy(prop('orderId'), otherRentals)}
                    />
                  </Box>
                </SectionCollapsableBlock>
              ) : null}

              <Box id="secCalls">
                <SecurityCalls userId={order.customerId} />
              </Box>

              <Box id="confDocs">
                <ConfirmationDocuments orderId={order.orderId} userId={order.customerId} />
              </Box>
              <AllUserConfirmationDocuments userId={order.customerId} />

              <CommunicationsAndNotes order={order} />

              <AdminEvents events={order.adminEvents} />

              <Box id="requirements">
                <SectionCollapsableBlock Title={<H3>Borrower Requirements</H3>}>
                  {loadingUserVerifications && !userVerifications ? (
                    <SpinnerIcon />
                  ) : (
                    <Documents
                      orderId={order.orderId}
                      userId={order.customerId}
                      userVerifications={userVerifications}
                      userDocuments={userDocuments}
                    />
                  )}
                </SectionCollapsableBlock>
              </Box>

              <SectionCollapsableBlock initiallyClosed Title={<H3>Duplicate documents</H3>}>
                <DuplicatesDocuments duplicates={{ accounts, selfReferrer }} />
              </SectionCollapsableBlock>

              <TwoGrid>
                <Box>
                  <SectionCollapsableBlock Title={<H3>Item location</H3>}>
                    <Flex position="relative">
                      <Flex
                        height="100%"
                        position="absolute"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <MapPin />
                      </Flex>

                      <MapImage
                        src={`https://maps.googleapis.com/maps/api/staticmap?center=${order.items[0].lat},${order.items[0].lng}&zoom=11&size=800x600
          &key=AIzaSyANWfzTlV-cyaFb2hIZru5Yw6jpcFASOwo`}
                      />
                    </Flex>
                  </SectionCollapsableBlock>
                </Box>

                <Box>
                  <SectionCollapsableBlock Title={<H3>Searches</H3>}>
                    {loadingUserSearches && !userSearches.length ? (
                      <SpinnerIcon />
                    ) : (
                      <>
                        <Flex mb={2}>
                          <Text small mb={0} width={1 / 3}></Text>
                          <Text small color="copyTwo" bold mb={0} width={2 / 3}>
                            Search Date
                          </Text>
                        </Flex>
                        {userSearches.map((search, i) => (
                          <DataRow
                            key={search.searchTerm}
                            rowCount={i + 1}
                            title={search.searchTerm}
                            value={formatDateTimeUTC(search.createdAt, 'd MMM yy, HH:mm')}
                          />
                        ))}

                        {canShowMore && (
                          <Button
                            mr={3}
                            mt={2}
                            variant="secondary"
                            large
                            label="See more"
                            onClick={() => showMore()}
                          />
                        )}
                      </>
                    )}
                  </SectionCollapsableBlock>
                </Box>
              </TwoGrid>

              <TwoGrid>
                <Box id="approveSection">
                  <SectionBlock>
                    <ApprovalSection order={order} />
                  </SectionBlock>
                </Box>

                <Box>
                  <SectionBlock>
                    <AutoApproveDisabledSection order={order} />
                  </SectionBlock>
                </Box>
              </TwoGrid>
            </>
          )}
        </Box>
      </PageContainer>
    </Box>
  )
}

export default SelectedRental
