import { DocumentType } from '../../interfaces'

export const socialLinksRejectedReasons = {
  NAME_DOES_NOT_MATCH: `NAME_DOES_NOT_MATCH`,
  NO_CONNECTION_TO_USER: 'NO_CONNECTION_TO_USER',
  INSUFFICIENT_NUMBER_OF_CONNECTIONS: 'INSUFFICIENT_NUMBER_OF_CONNECTIONS',
  PROFILE_IS_PRIVATE: 'PROFILE_IS_PRIVATE',
  INVALID_SOCIAL_MEDIA_TYPE: 'INVALID_SOCIAL_MEDIA_TYPE',
  NO_ACTIVITY_IN_LAST_6_MONTHS: 'NO_ACTIVITY_IN_LAST_6_MONTHS',
  NO_POSTS: 'NO_POSTS',
  POSSIBLE_FAKE_PROFILE: 'POSSIBLE_FAKE_PROFILE',
  NOT_VALID_URL: 'NOT_VALID_URL',
}

export const socialLinksRejectedReasonsTExt = {
  NAME_DOES_NOT_MATCH: `Name does not match`,
  NO_CONNECTION_TO_USER: 'No connection to user',
  INSUFFICIENT_NUMBER_OF_CONNECTIONS: 'Insufficient connections',
  PROFILE_IS_PRIVATE: 'Profile is private',
  INVALID_SOCIAL_MEDIA_TYPE: 'Unacceptable social media type',
  NO_ACTIVITY_IN_LAST_6_MONTHS: 'No activity in last 6 months',
  NO_POSTS: 'Profile has no posts on it',
  POSSIBLE_FAKE_PROFILE: 'Suspicion of being a fake profile',
  NOT_VALID_URL: 'Not a valid URL/profile',
}

export const rejectionReasons = {
  DETAILS_NOT_VISIBLE: 'DETAILS_NOT_VISIBLE',
  FACE_NOT_VISIBLE: 'FACE_NOT_VISIBLE',
  ID_EXPIRED: 'ID_EXPIRED',
  ILLEGIBLE_ID: 'ILLEGIBLE_ID',
  INCORRECT_PHRASE: 'INCORRECT_PHRASE',
  NAME_MISMATCH: 'NAME_MISMATCH',
  PROOF_NOT_RECENT: 'PROOF_NOT_RECENT',
  SELFIE_NOT_UPSIDE_DOWN: 'SELFIE_NOT_UPSIDE_DOWN',
  SILENT_VIDEO: 'SILENT_VIDEO',
  TYPE_NOT_ACCEPTED: 'TYPE_NOT_ACCEPTED',
  UNDERAGE: 'UNDERAGE',
  OTHER: 'OTHER',
  // New types added by https://fatllama.atlassian.net/browse/PT-252
  NOT_VALID_PROOF_OF_IDENTITY: 'NOT_VALID_PROOF_OF_IDENTITY',
  NOT_VALID_PROOF_OF_ADDRESS: 'NOT_VALID_PROOF_OF_ADDRESS',
  NOT_VALID_PROOF_OF_INCOME: 'NOT_VALID_PROOF_OF_INCOME',
  COULD_NOT_HEAR_4_DIGIT_CODE: 'COULD_NOT_HEAR_4_DIGIT_CODE',
  COULD_NOT_VERIFY_VIDEO: 'COULD_NOT_VERIFY_VIDEO',
  DOES_NOT_SHOW_INCOME: 'DOES_NOT_SHOW_INCOME',
}

const reasons = {
  [rejectionReasons.DETAILS_NOT_VISIBLE]: 'Not all details visible',
  [rejectionReasons.FACE_NOT_VISIBLE]: 'Face not visible',
  [rejectionReasons.ID_EXPIRED]: 'ID Expired',
  [rejectionReasons.ILLEGIBLE_ID]: 'Illegible ID',
  [rejectionReasons.INCORRECT_PHRASE]: 'Incorrect phrase',
  [rejectionReasons.NAME_MISMATCH]: 'Name Mismatch',
  [rejectionReasons.PROOF_NOT_RECENT]: 'POA is not recent enough',
  [rejectionReasons.SELFIE_NOT_UPSIDE_DOWN]: 'Selfie not upside down',
  [rejectionReasons.SILENT_VIDEO]: 'Silent video',
  [rejectionReasons.TYPE_NOT_ACCEPTED]: 'Type not accepted',
  [rejectionReasons.UNDERAGE]: 'Underage',
  [rejectionReasons.NOT_VALID_PROOF_OF_IDENTITY]: 'Not valid proof of identity',
  [rejectionReasons.NOT_VALID_PROOF_OF_ADDRESS]: 'Not valid proof of address',
  [rejectionReasons.NOT_VALID_PROOF_OF_INCOME]: 'Not valid proof of income',
  [rejectionReasons.COULD_NOT_HEAR_4_DIGIT_CODE]: 'Could not hear 4-digit code',
  [rejectionReasons.COULD_NOT_VERIFY_VIDEO]: 'Could not verify video',
  [rejectionReasons.DOES_NOT_SHOW_INCOME]: 'Does not show income',
  [rejectionReasons.OTHER]: 'Other',
}

export const getReasonsByDocumentType = (documentType: DocumentType) => {
  switch (documentType) {
    case DocumentType.PASSPORT:
    case DocumentType.DRIVING_LICENCE:
    case DocumentType.ID_CARD:
      return {
        [rejectionReasons.ID_EXPIRED]: 'ID Expired',
        [rejectionReasons.NOT_VALID_PROOF_OF_IDENTITY]: 'Not valid proof of identity',
        [rejectionReasons.NAME_MISMATCH]: 'Name Mismatch',
        [rejectionReasons.ILLEGIBLE_ID]: 'Illegible or blurry',
        [rejectionReasons.DETAILS_NOT_VISIBLE]: 'Not all details visible',
        [rejectionReasons.OTHER]: 'Other',
      }

    case DocumentType.PROOF_OF_ADDRESS:
      return {
        [rejectionReasons.NOT_VALID_PROOF_OF_ADDRESS]: 'Not valid proof of address',
        [rejectionReasons.PROOF_NOT_RECENT]: 'More than 3 months old',
        [rejectionReasons.ILLEGIBLE_ID]: 'Illegible or blurry',
        [rejectionReasons.NAME_MISMATCH]: 'Name Mismatch',
        [rejectionReasons.DETAILS_NOT_VISIBLE]: 'Not all details visible',
        [rejectionReasons.OTHER]: 'Other',
      }
    case DocumentType.PROOF_OF_EMPLOYMENT:
      return {
        [rejectionReasons.NOT_VALID_PROOF_OF_INCOME]: 'Not valid proof of income',
        [rejectionReasons.PROOF_NOT_RECENT]: 'More than 3 months old',
        [rejectionReasons.DOES_NOT_SHOW_INCOME]: 'Does not show income',
        [rejectionReasons.ILLEGIBLE_ID]: 'Illegible or blurry',
        [rejectionReasons.DETAILS_NOT_VISIBLE]: 'Not all details visible',
        [rejectionReasons.OTHER]: 'Other',
      }
    case DocumentType.VIDEO_SELFIE:
      return {
        [rejectionReasons.SILENT_VIDEO]: 'Silent video',
        [rejectionReasons.COULD_NOT_HEAR_4_DIGIT_CODE]: 'Could not hear 4-digit code',
        [rejectionReasons.FACE_NOT_VISIBLE]: 'Could not see face in video',
        [rejectionReasons.COULD_NOT_VERIFY_VIDEO]: 'Could not verify video',
      }
    case DocumentType.PHOTO_SELFIE:
    case DocumentType.UNKNOWN:
    default:
      return reasons
  }
}
