import { useEffect, useState } from 'react'

import GetApi from '../../../utils/api'

// import { Duplicates } from "../../../interfaces/index";

const useOtherRentals = (userId?: number) => {
  const api = GetApi()
  const [otherRentals, setOtherRentals] = useState<any>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const getOtherRentals = async () => {
    if (!userId) {
      return
    }
    try {
      setError(undefined)
      setIsLoading(true)

      const res = await api.getUserOtherRentals({ userId })

      if (!res.success) {
        setError('Cannot get other rentals')
        return
      }
      const data = res.payload

      const otherNotChatting = data.txs.filter((tx: any) => tx.status !== 'CHATTING')
      setOtherRentals(otherNotChatting)
    } catch {
      setError('Cannot get duplicates')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (userId) {
      getOtherRentals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  return {
    otherRentals,
    isLoading,
    error,
  }
}

export default useOtherRentals
